import React, { useEffect, useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import {
  Campaign,
  CampaignType,
  InviteListCampaign,
} from "../../../models/campaign";
import InfiniteScroll from "react-infinite-scroll-component";
import { getInvites, getInviteStatus } from "../../../api/invite";
import { InviteListCampaignTile } from "../../../components/CampaignTile";
import { useAuthToken } from "../../../hooks/useAuthToken";
import Progress from "../../../components/Feedback/Progress";

interface PaginatedListProps extends BoxProps {
  paginatedContainerId?: string;
  children: React.ReactNode;
  records: Campaign[];
  onNextPage: () => void;
  hasMoreRecords: boolean;
}

const PaginatedList = ({
  records,
  onNextPage,
  hasMoreRecords,
  paginatedContainerId = "scrollableDiv",
  ...props
}: PaginatedListProps) => {
  return (
    <Box
      id={paginatedContainerId}
      w="full"
      overflow="auto"
      h="calc(100vh - 10rem)"
    >
      <InfiniteScroll
        dataLength={records.length}
        next={() => onNextPage()}
        hasMore={hasMoreRecords}
        loader={<Progress size="sm" bg="transparent" />}
        scrollableTarget={paginatedContainerId}
      >
        {props.children}
      </InfiniteScroll>
    </Box>
  );
};

interface InviteListProps {
  flowId: string;
  type: CampaignType;
}

const PAGE_SIZE: number = 10;

export const InviteList = ({ flowId, type }: InviteListProps) => {
  const { getAuthToken } = useAuthToken();

  const [skip, setSkip] = useState(0);
  const [invitations, setInvitations] = useState<InviteListCampaign[]>([]);
  const [hasMoreInvitations, setHasMoreInvitations] = useState<boolean>(true);
  const [lastInvitationIndex, setLastInvitationIndex] = useState(0);

  const loadMoreInvitations = () => setSkip(lastInvitationIndex);

  useEffect(() => {
    const fetchInvitations = async () => {
      const token = localStorage.getItem('token')

      const invitationCampaigns = await getInvites({
        flowId,
        type,
        token,
        pagination: {
          skip,
          size: PAGE_SIZE,
        },
      });

      if (invitationCampaigns) {
        setHasMoreInvitations(PAGE_SIZE - invitationCampaigns.length === 0);

        let inviteListCampaigns: InviteListCampaign[] = [];
        inviteListCampaigns = invitationCampaigns.map(
          (invite): InviteListCampaign => ({
            ...invite,
            summaryStatus: "Loading",
          })
        );

        if (skip === 0) {
          setInvitations(inviteListCampaigns);
        } else setInvitations((state) => [...state, ...inviteListCampaigns]);

        setLastInvitationIndex(skip + PAGE_SIZE);
      }

      return invitationCampaigns;
    };

    fetchInvitations();
  }, [skip]);

  useEffect(() => {
    // fetches the invite status for given campaign list and updates the summary
    const updateCampaignSummary = async (campaignList: string[]) => {
      campaignList.map(async (campaignId) => {
        if (!campaignId) return;

        const updateSummary = async (campaignId: string) => {
          try {
            const result = await getInviteStatus({
              flowId: campaignId,
              token: await getAuthToken(),
            });
            if (result) {
              setInvitations((invitations) => {
                return invitations.map((invite) => {
                  if (invite.id === campaignId) {
                    return {
                      ...invite,
                      summary: result,
                      summaryStatus: "Fetched",
                    };
                  }
                  return invite;
                });
              });
            }
          } catch (e) {}
        };

        await updateSummary(campaignId);
      });
    };

    updateCampaignSummary(
      invitations.map((invite) =>
        invite.summaryStatus === "Loading" ? invite.id : ""
      )
    );
  }, [invitations.length]);

  return (
    <PaginatedList
      records={invitations}
      onNextPage={() => loadMoreInvitations()}
      hasMoreRecords={hasMoreInvitations}
      paginatedContainerId={`${type}_scroll`}
    >
      {invitations.map((invite, index) => (
        <InviteListCampaignTile key={invite.id} campaign={invite} mb={4} />
      ))}
    </PaginatedList>
  );
};
