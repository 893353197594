import React, { Fragment, useState, useEffect } from "react";
import {
  Stack,
  HStack,
  Box,
  Heading,
  Text,
  Divider,
  useDisclosure,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  CircularProgress,
} from "@chakra-ui/react";
import { useParams } from "react-router";
import { useInView } from "react-intersection-observer";

import { AddIcon, CloseIcon, SearchIcon } from "../../../../../constants/icons";
import emptyImageSrc from "../../../../../img/empty.svg";
import {
  QuickReply,
  useQuickReplies,
} from "../../../../../data/support/useQuickReplies";
import { Button } from "../../../../../components/Button";
import { QuickReplyTile } from "./QuickReplyTile";
import { AddNewQuickReply } from "./NewQuickReply";
import useDebounce from "../../../../../hooks/useDebounce";

export const QuickReplies = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentEditing, setCurrentEditing] = useState<QuickReply | null>(null);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { data, hasNextPage, fetchNextPage, status, refetch } = useQuickReplies(
    flowId,
    debouncedSearchQuery
  );
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { ref: listRef, inView: isInView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (isInView) {
      fetchNextPage();
    }
  }, [isInView]);

  useEffect(() => {
    if (status !== "loading") {
      refetch();
    }
  }, [debouncedSearchQuery]);

  return (
    <Stack className="px-8 py-4 m-0 h-screen flex-1">
      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="md">
            Quick Replies
          </Heading>
          <Text>Setup quick responses</Text>
        </Box>
        <Button leftIcon={<AddIcon />} onClick={() => onOpen()}>
          New
        </Button>
      </HStack>
      <Divider py={2} />
      <HStack pt={4} justify="space-between">
        <InputGroup size="sm" maxW="sm">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            placeholder="Search"
          />
          <InputRightElement>
            {searchQuery.length !== 0 && (
              <CloseIcon onClick={() => setSearchQuery("")} />
            )}
          </InputRightElement>
        </InputGroup>
      </HStack>
      {data && data.pages && data.pages.length === 0 && (
        <Stack
          py={8}
          spacing={0}
          boxShadow="sm"
          borderWidth="thin"
          borderRadius="md"
          maxH="xl"
          justify="center"
          align="center"
        >
          <Image src={emptyImageSrc} />
          <Text className="text-base text-[#1D2939]">No quick replies</Text>
        </Stack>
      )}
      {data && data.pages.length !== 0 && (
        <Stack
          boxShadow="sm"
          borderWidth="thin"
          borderRadius="md"
          maxH="xl"
          overflowY="scroll"
        >
          {data.pages.map((page, idx) => (
            <Fragment key={idx}>
              {page.quick_replies.map((reply, idx) => (
                <QuickReplyTile
                  flex={1}
                  reply={reply}
                  key={`${reply.trigger_text}_${idx}`}
                  onEdit={() => {
                    setCurrentEditing(reply);
                    onOpen();
                  }}
                />
              ))}
            </Fragment>
          ))}
          {hasNextPage && (
            <Stack py={2} w="full" align="center" ref={listRef}>
              <CircularProgress color="pink.400" isIndeterminate />
            </Stack>
          )}
        </Stack>
      )}
      <AddNewQuickReply
        quickReply={currentEditing}
        flowId={flowId}
        isOpen={isOpen}
        onClose={() => {
          setCurrentEditing(null);
          onClose();
        }}
      />
    </Stack>
  );
};
