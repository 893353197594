export const AGENT_INTERVENTION = "agent_complete_intervention";
export const ORDER_CREATED_ACTION = "mark_as_order_created_support_action";
export const DISABLE_RESOLVE_ACTION = "disable_resolve_support_action";
export const DISABLE_INTERNATIONAL_NO_IMPORT = "disable_international_no_import";
export const QUESTION_ANALYTICS = "question_analytics";
export const DISABLE_LIVE_USERS = "disable_live_users";
export const DISABLE_IMP_SIDE_MENU = "disable_imp_side_menu";
export const DISABLE_INVITE_TAB = "disable_invite_tab";
export const DISABLE_EXPORT_INVITATIONS = "disable_export_invitations";
export const IS_WORKFLOW = "is_workflow";
export const INTERVIEWER_RESTRICTED = "interviewer_restricted"







