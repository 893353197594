import React, { useState, forwardRef } from "react";
import {
  HStack,
  IconButton,
  Input,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { colorSystem } from "../../theme";
import { CheckIcon, EditIcon } from "../../constants/icons";
import { useUpdateUserAttributes } from "../../mutations/users/useUpdateUserAttributes";
import { useUpdateUserSummary } from "../../mutations/users/useUpdateUserSummary";

interface AttributeProps extends StackProps {
  type?: "summary" | "metadata";
  flowId: string;
  conversationId: string;
  sessionId: string;
  attributeKey: string;
  attributeValue: any;
  editable?: boolean;
}

const { textSubdue } = colorSystem;

export const Attribute = forwardRef<HTMLDivElement, AttributeProps>(
  (
    {
      type = "metadata",
      editable = true,
      flowId,
      conversationId,
      sessionId,
      attributeKey,
      attributeValue,
      ...props
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [stateAttributeValue, setStateAttributeValue] =
      useState(attributeValue);

    const updateUserDetails = useUpdateUserAttributes({
      data: {
        [attributeKey]: stateAttributeValue,
      },
      flowId,
      conversationId,
      refetchKey: ["user_attributes", flowId, conversationId],
    });

    const updateUserSummary = useUpdateUserSummary({
      flowId,
      refetchKey: ["users", flowId, conversationId],
    });

    const saveAttribute = () => {
      if (type === "summary")
        updateUserSummary.mutate({
          data: {
            [attributeKey]: stateAttributeValue,
          },
          sessionId,
        });
      else updateUserDetails.mutate();
      return setIsEditing(false);
    };

    const handleEdit = () => {
      if (isEditing) return saveAttribute();
      //
      setStateAttributeValue(attributeValue);
      setIsEditing(!isEditing);
    };

    return (
      <Stack role="group" ref={ref} {...props}>
        <HStack className="justify-between" onClick={() => handleEdit()}>
          <Text fontSize="sm" color={textSubdue} _groupHover={{ cursor: "pointer" }}>
            {attributeKey}
          </Text>
          {editable && (
            <Tooltip label={isEditing ? "Save Changes" : "Edit"}>
              <IconButton
                size="xs"
                aria-label="remove"
                visibility={isEditing ? "visible" : "hidden"}
                icon={isEditing ? <CheckIcon /> : <EditIcon />}
                _groupHover={{ visibility: "visible" }}
                onClick={() => handleEdit()}
              />
            </Tooltip>
          )}
        </HStack>
        {isEditing ? (
          <Input
            value={stateAttributeValue}
            onChange={(event) =>
              setStateAttributeValue(event.currentTarget.value)
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") saveAttribute();
            }}
          />
        ) : (
          <Text>{attributeValue}</Text>
        )}
      </Stack>
    );
  }
);
