import React, { forwardRef, useState, ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  Text,
  BoxProps,
  StackProps,
} from "@chakra-ui/react";
import { useParams, Link} from "react-router-dom";
import { useUserSummary } from "../data/user/useUserData";

import { Transcript } from "../pages/Flow/Support/Transcript";
import { AvatarTile } from "../components/AvatarTile";
import ForeverProgress from "../components/Feedback/Progress";
import CandidateSummaryText from "../components/CandidateSummaryText";

import { productBackgroundNeutral } from "../theme";
import { isUrl } from "../utils/helpers";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { IS_WORKFLOW } from "../constants/feature_flags";

interface UserProfileProps extends StackProps {
  flowId?: string;
  sessionId?: string;
  userId?: string;
  onClose?: () => void;
}

interface UserSummaryTabProps extends BoxProps {
  selected?: boolean;
  tabName: string;
}

const defaultTabs = ["Basic details", "Documents", "Transcript"];

const UserSummaryTab = forwardRef<HTMLDivElement, UserSummaryTabProps>(
  ({ selected = false, tabName, ...props }, ref) => (
    <Box
      ref={ref}
      px={2}
      py={1}
      bg={selected && "gray.100"}
      borderRadius="md"
      _hover={{
        cursor: "pointer",
        bg: "gray.100",
      }}
      {...props}
    >
      <Text fontSize="sm" fontWeight="medium">
        {tabName}
      </Text>
    </Box>
  )
);

export const UserSummary = (props: UserProfileProps) => {
  const {
    id: locationFlowId,
    sessionId: locationSessionId,
    userId: locationUserId,
  } = useParams();

  const flowId = locationFlowId ?? props.flowId;
  const userId = props.userId ?? locationUserId;
  const sessionId = props.sessionId ?? locationSessionId;

  const [currentTab, setCurrentTab] = useState(0);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const { isLoading, data: userProfile } = useUserSummary({
    flowId,
    sessionId,
    userId,
  });

  const { data: featureFlags } = useFeatureFlags(flowId);

  const isFeatureEnabled = (feature: string) => {
    return (featureFlags?.flags ?? []).includes(feature);
  };

  if (isLoading) {
    return (
      <Container maxW="container.xl">
        <ForeverProgress />
      </Container>
    );
  }
  const getOverallScoreCategory = (score) => {
    if (score >= 0 && score <= 25) return "A1 (Beginner)";
    if (score > 25 && score <= 50) return "A2 (Elementary)";
    if (score > 50 && score <= 75) return "B1 (Intermediate)";
    if (score > 75 && score <= 85) return "B2 (Upper-Intermediate)";
    if (score > 85 && score <= 95) return "C1 (Advanced)";
    if (score > 95 && score <= 100) return "C2 (Proficient)";
    return "Unknown"; // In case the score is outside the 0-100 range
  };

  const renderAttributes = (
    attributes: { [key: string]: string },
    showDocuments: boolean = false
  ) => {
    return (
      <Stack className="px-8 py-6 w-full rounded-lg overflow-y-scroll">

{(flowId == 'westside-hiring' || flowId == 'zudio-hiring') && attributes["Verdict"] && attributes["Overall Score"] && (
  <Stack
    px={4}
    py={2}
    mt={4} // optional, for some spacing
    justify="space-between"
    borderRadius="md"
    borderWidth="medium"
  >
    <Text fontSize="lg" fontWeight="medium">
      Overall score(all tests)
    </Text>
    <Stack direction="row" align="baseline">
      <Text fontSize="sm" fontWeight="medium" color="gray.600">
        Recommendation:
      </Text>
      <CandidateSummaryText
        fontSize="sm"
        fontWeight="medium"
        color={
          attributes["Verdict"] === "Recommended"
            ? "green"
            : attributes["Verdict"] === "Not Recommended"
            ? "red"
            : "black"
        }
        text={attributes["Verdict"]}
        report={false}
      />
    </Stack>
    <Stack direction="row" align="baseline">
      <Text fontSize="sm" fontWeight="medium" color="gray.600">
        Overall Score:
      </Text>
      <Text
        fontSize="sm"
        fontWeight="medium"
      >
        {attributes["Overall Score"]}
      </Text>
    </Stack>
  </Stack>
)}

{(flowId == 'indigo-aocs' || flowId == 'indigo-ifs') && attributes["Overall Score"] && (
  <Stack
    px={4}
    py={2}
    mt={4} // optional, for some spacing
    justify="space-between"
    borderRadius="md"
    borderWidth="medium"
  >
    <Text fontSize="lg" fontWeight="medium">
    CEFR Level
    </Text>
    <Stack direction="row" align="baseline">
      <Text fontSize="sm" fontWeight="medium" color="gray.600">
      {getOverallScoreCategory(parseInt(attributes["Overall Score"]))}
      </Text>
    </Stack>

  </Stack>
)}
        {Object.keys(attributes).map((key, index) => {
  if (isUrl(attributes[key]) === showDocuments) {
    if(key != "Overall Score" && key != "Verdict"){
    return (
      <Stack
        px={4}
        py={2}
        key={`${index}_summary_${key}`}
        justify="space-between"
        borderRadius="md"
        borderWidth="thin"
      >
        <Text fontSize="sm" fontWeight="medium" color="gray.600">
          {key}
        </Text>
        <CandidateSummaryText
          fontSize="lg"
          fontWeight="medium"
          text={attributes[key]}
          report={false}
        />
      </Stack>
    );
  }
  }})}
  {Object.keys(attributes).map((key, index) => {
  if (isUrl(attributes[key]) === true) {
    if(key == "Report URL"){
    return (
      <Stack
        px={4}
        py={2}
        key={`${index}_summary_${key}`}
        justify="space-between"
        borderRadius="md"
        borderWidth="thin"
      >
        <Text fontSize="sm" fontWeight="medium" color="gray.600">
          {key == "Report URL" ? "Report" : key}
        </Text>
        <CandidateSummaryText
          fontSize="lg"
          fontWeight="medium"
          text={attributes[key]}
          report={true}
        />
      </Stack>
    );
  }
  }})}

      </Stack>
    );
  };
  const rendervideoAttributes = (
    attributes: string | string[],
    verdict: string,
    showDocuments: boolean = false
  ): ReactNode => {
    // Parse the first element which contains the video scores
    const jsonObj = JSON.parse(attributes[0]);
    // Get the report URL from the second element
    const reportUrl = attributes[1];
    return (
      <Stack className="px-8 py-6 w-full rounded-lg overflow-y-scroll">
        {isFeatureEnabled(IS_WORKFLOW) && verdict && (
          <Stack
            px={4}
            py={2}
            mt={4}
            justify="space-between"
            borderRadius="md"
            borderWidth="medium"
          >
            <Stack direction="row" align="baseline">
              <Text fontSize="sm" fontWeight="medium" color="gray.600">
                Recommendation:
              </Text>
              <CandidateSummaryText
                fontSize="sm"
                fontWeight="medium"
                color={
                  verdict === "Recommended"
                    ? "green"
                    : verdict === "Not Recommended"
                    ? "red"
                    : "black"
                }
                text={verdict}
                report={false}
              />
            </Stack>
          </Stack>
        )}
        {/* Render test name and score */}
        <Stack
          px={4}
          py={2}
          key={`test_summary`}
          justify="space-between"
          borderRadius="md"
          borderWidth="thin"
        >
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            {Object.keys(jsonObj)[0]}
          </Text>
          <Text fontSize="lg" fontWeight="low">
            {Object.values(jsonObj)[0] as string}
          </Text>
        </Stack>

        {jsonObj.Videoscore.map((item: any, index: number) => (
          <Stack
            key={`video_score_${index}`}
            onClick={() => {
              if (expandedItem === `${Object.keys(item)[0]}`) {
                setExpandedItem(null);
              } else {
                setExpandedItem(`${Object.keys(item)[0]}`);
              }
            }}
            cursor="pointer"
            _hover={{ bg: "gray.100" }}
            p={4}
            borderWidth={1}
            borderRadius="md"
            borderColor={expandedItem === `${Object.keys(item)[0]}` ? "gray.400" : "gray.200"}
          >
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              {item[`${Object.keys(item)[0]}`]?.question}
            </Text>
            {expandedItem === `${Object.keys(item)[0]}` && (
              <Stack mt={2} pl={4} spacing={1}>
                {Object.entries(item[`${Object.keys(item)[0]}`]).map(
                  ([key, value]: [string, any]) =>
                    key !== "question" && (
                      <Stack
                        px={2}
                        py={1}
                        key={key}
                        justify="space-between"
                        borderRadius="md"
                        borderWidth="thin"
                      >
                        <Text fontSize="xs" fontWeight="medium" color="gray.600">
                          {key}
                        </Text>
                        {key === 'video' ? (
                          <Link to={value} target="_blank" rel="noopener noreferrer">
                            <Text fontSize="sm" fontWeight="medium" color="blue.500" textDecoration="underline">
                              {value}
                            </Text>
                          </Link>
                        ) : (
                          <Text fontSize="sm" fontWeight="medium">
                            {value}
                          </Text>
                        )}
                      </Stack>
                    )
                )}
              </Stack>
            )}
          </Stack>
        ))}
                {/* Add Report Section */}
                {reportUrl && (
          <Stack
            px={4}
            py={2}
            justify="space-between"
            borderRadius="md"
            borderWidth="thin"
          >
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              Report
            </Text>
            <CandidateSummaryText
              fontSize="lg"
              fontWeight="medium"
              text={reportUrl}
              report={true}
            />
          </Stack>
        )}
      </Stack>
    );
  };
const renderresumeAttributes = (attributes: Record<string, any>) => {

  const excludedFields = [
    'doc_extension',
    'doc_id',
    'extraction_mode',
    'status',
    'updated_at',
    'urls',
    'version',
  ];

  const renderValue = (value: any, key: string) => {
    if (value === null || value === undefined || value === "") {
      return null;
    }

    if (Array.isArray(value)) {
      return (
        <Stack spacing={2}>
          {value.map((item, index) => (
            <Stack
              key={`array_item_${index}`}
              borderWidth="thin"
              borderRadius="md"
              p={2}
              bg="gray.50"
              boxShadow="sm"
            >
              {typeof item === "object" && item !== null ? (
                renderValue(item, key)
              ) : (
                <Text fontSize="sm">{JSON.stringify(item, null, 2)}</Text>
              )}
            </Stack>
          ))}
        </Stack>
      );
    }

    if (typeof value === "object" && value !== null) {
      return (
        <Stack spacing={2}>
          {Object.entries(value).map(([nestedKey, nestedValue]) => {
            if (nestedValue === null || nestedValue === undefined || nestedValue === "") {
              return null;
            }

            return (
              <Stack key={nestedKey} spacing={1} pl={4}>
                <Text fontSize="xs" fontWeight="medium" color="gray.600">
                  <strong>{nestedKey}:</strong>
                </Text>
                {renderValue(nestedValue, nestedKey)}
              </Stack>
            );
          })}
        </Stack>
      );
    }

    return <Text fontSize="sm" fontWeight="medium">{value}</Text>;
  };

  return (
    <Stack spacing={2}>
      {attributes["shortlist_system_recommendation"] && attributes["score"] && attributes["status"] === "analyzed" &&
      <Stack
        px={4}
        py={2}
        mt={4} // optional, for some spacing
        justify="space-between"
        borderRadius="md"
        borderWidth="medium"
      >
        <Text fontSize="lg" fontWeight="medium">
          Overall Recommendation
        </Text>
        <Stack direction="row" align="baseline">
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            Recommendation:
          </Text>
          <CandidateSummaryText
            fontSize="sm"
            fontWeight="medium"
            color={
              attributes["shortlist_system_recommendation"] === "SELECT"
                ? "green"
                : attributes["shortlist_system_recommendation"] === "REJECT"
                ? "red"
                : "black"
            }
            text={attributes["shortlist_system_recommendation"]}
            report={false}
          />
        </Stack>
        <Stack direction="row" align="baseline">
          <Text fontSize="sm" fontWeight="medium" color="gray.600">
            Score:
          </Text>
          <Text
            fontSize="sm"
            fontWeight="medium"
          >
            {attributes["score"]}
          </Text>
        </Stack>
      </Stack>}
      {Object.entries(attributes).map(([key, value]) => {
        if (excludedFields.includes(key)) {
          return null;
        }

        if (value === null || value === undefined || value === "") {
          return null;
        }

        return (
          <Stack
            key={key}
            cursor="default"
            p={4}
            borderWidth={1}
            borderRadius="md"
            borderColor="gray.200"
            boxShadow="xs"
          >
            <Text fontSize="sm" fontWeight="medium" color="gray.600">
              <strong>{key}:</strong>
            </Text>
            <Stack mt={2} pl={4} spacing={1}>
              {key === "video" ? (
                <Link to={value} target="_blank" rel="noopener noreferrer">
                  <Text fontSize="sm" fontWeight="medium" color="blue.500" textDecoration="underline">
                    {value}
                  </Text>
                </Link>
              ) : (
                renderValue(value, key)
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};


  if (userProfile) {
    return (
      <Box
        className="w-full h-full flex overflow-hidden"
        bg={productBackgroundNeutral}
        {...props}
      >
        <Box className="flex-shrink-0 overflow-x-hidden w-64">
          <Stack
            className="min-h-0 dark h-full"
            spacing={0}
            bg={productBackgroundNeutral}
            borderRightWidth="thin"
          >
            <AvatarTile
              p={4}
              size={30}
              fontWeight="medium"
              fontSize="md"
              name={userProfile.table?.Name ?? ""}
              subtitle={userProfile.table?.["Phone Number"] ?? ""}
            />
            <Stack spacing={0} p={4}>
              {defaultTabs.map((tabName, index) => (
                <UserSummaryTab
                  key={`${tabName}_${index}_tab`}
                  tabName={tabName}
                  selected={currentTab === index}
                  onClick={() => setCurrentTab(index)}
                />
              ))}
              {userProfile.test_results &&
                Object.keys(userProfile.test_results).length !== 0 && (
                  <UserSummaryTab
                    tabName={isFeatureEnabled(IS_WORKFLOW) ? "L1 Recommendation" : "Test Results"}
                    selected={currentTab === 3}
                    onClick={() => setCurrentTab(3)}
                  />
                )}
              {userProfile.video_test_results &&
                Object.keys(userProfile.video_test_results).length !== 0 && (
                  <UserSummaryTab
                    tabName={isFeatureEnabled(IS_WORKFLOW) ? "Final Recommendation" : "Video Test Results"}
                    selected={currentTab === 4}
                    onClick={() => setCurrentTab(4)}
                  />
                )}
              {userProfile.resume &&
                Object.keys(userProfile.resume).length !== 0 && (
                  <UserSummaryTab
                    tabName="Resume"
                    selected={currentTab === 5}
                    onClick={() => setCurrentTab(5)}
                  />
                )}
            </Stack>
          </Stack>
        </Box>

        <Box className="relative flex-col flex flex-1 h-full overflow-y-auto">
          {currentTab === 0 && renderAttributes(userProfile.summary)}
          {currentTab === 1 && renderAttributes(userProfile.summary, true)}
          {currentTab === 2 && (
            <Transcript
              key={`transcript-${sessionId}`}
              flex={1}
              direction="column-reverse"
              className="p-4 w-full rounded-lg overflow-y-scroll"
              flowId={flowId}
              userId={userId}
              sessionId={sessionId}
            />
          )}
          {currentTab === 3 &&
            userProfile.test_results &&
            renderAttributes(userProfile.test_results)}
          {currentTab === 4 &&
          userProfile.video_test_results &&
          rendervideoAttributes(userProfile.video_test_results, userProfile.test_results["Verdict"])}
          {currentTab === 5 &&
          userProfile.resume &&
          renderresumeAttributes(userProfile.resume)}
        </Box>
      </Box>
    );
  }
};
