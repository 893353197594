import React, { forwardRef, Fragment, useEffect, useState } from "react";
import { Box, Divider, Stack, StackProps } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useInView } from "react-intersection-observer";

import { SupportTile } from "../../../components/SupportTile";
import { ChatListFilters } from "./ChatListFilter";
import { FilterQuery } from "../../../components/Filter/models";
import NotFound from "../../../pages/NotFound";
import { useChats } from "../../../data/support/useChats";
import { colorSystem } from "../../../theme";
import { SortGroup } from "../../../api/users";
import Progress from "../../../components/Feedback/Progress";

interface ChatListProps extends StackProps {
  flowId: string;
}

const { textPurple } = colorSystem;

export const ChatList = forwardRef<HTMLDivElement, ChatListProps>(
  ({ flowId, ...props }, ref) => {
    const [filters, setFilters] = useState<FilterQuery[]>([]);
    const [sortGroup, setSortGroup] = useState<SortGroup[]>([]);
    const { ref: listRef, inView: isInView } = useInView({
      threshold: 0,
    });

    useEffect(() => {
      if (isInView) {
        fetchNextPage();
      }
    }, [isInView]);

    const { data, isLoading, error, fetchNextPage } = useChats({
      flowId,
      filters: { filters, operation: "or" },
      sort: sortGroup,
    });

    if (isLoading) return <Progress size="sm" />;

    // if (error) return <div>{JSON.stringify(error)}</div>;

    if (!data) return <div>No data</div>;

    return (
      <Stack
        ref={ref}
        spacing={0}
        flex={1}
        className="max-h-full overflow-y-scroll"
        {...props}
      >
        <ChatListFilters onChange={setFilters} onSortChange={setSortGroup} />
        {data && data.pages && data.pages.length === 0 && (
          <NotFound title="No Users" subtitle="" />
        )}
        {data &&
          data.pages &&
          data.pages.map((currentChatList, idx) => (
            <Fragment key={`${currentChatList.last_document_index}_${idx}`}>
              {currentChatList.users.map((chat) => (
                <Box
                  key={chat.conversation_oid}
                  pos="relative"
                  as={NavLink}
                  replace
                  to={`/dashboard/${flowId}/support/${chat.conversation_oid}/${chat.conversation_id}/`}
                  _hover={{
                    bg: "gray.100",
                    cursor: "pointer",
                  }}
                  _activeLink={{
                    bg: "gray.100",
                    _before: {
                      content: `""`,
                      left: 0,
                      top: 0,
                      bottom: 0,
                      width: 1,
                      position: "absolute",
                      backgroundColor: textPurple,
                    },
                  }}
                >
                  <SupportTile px={4} py={3} user={chat} />
                </Box>
              ))}
            </Fragment>
          ))}
        <Divider ref={listRef} />
      </Stack>
    );
  }
);
