import { createStore, action, persist } from "easy-peasy";
import { defaultWhatsappTemplate } from "../constants";
import { CacheStore } from "../models";

export const store = createStore<CacheStore>({
  currentPlayingMedia: "",
  updateCurrentPlayingMedia: action((state, payload) => {
    // console.log(`[STORE] updating current playing media to ${payload}`);
    state.currentPlayingMedia = payload;
  }),

  // All the bots under the given org
  flows: persist({}, { storage: "localStorage" }),
  cacheFlows: action((state, payload) => {
    if (!payload || payload.length === 0) {
      state.flows = {};
      return;
    }

    //console.log(`[STORE] caching ${payload.length} flows to app store`);
    state.flows = {};
    payload.map((flow) => (state.flows[flow.bot_id] = flow));
  }),
  clearFlows: action((state) => {
    //console.log(`[STORE] cleared flows`);
    state.flows = {};
  }),

  // DashboardTemplate used to send invite
  template: defaultWhatsappTemplate,
  cacheTemplate: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.name} template to app store`);
    state.template = payload;
  }),
  clearTemplate: action((state) => {
    //console.log(`[STORE] cleared template to app store`);
    state.template = defaultWhatsappTemplate;
  }),

  // New Users who are going to receive an invite
  newUsers: [],
  cacheNewUsers: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.length} users to app store`);
    state.newUsers = payload;
  }),
  clearNewUsers: action((state) => {
    //console.log(`[STORE] cleared new users from app store`);
    state.newUsers = [];
  }),

  // Metadata of campaign
  campaignData: {},
  cacheCampaignData: action((state, payload) => {
    // //console.log(
    //   `[STORE] caching campaign.${payload.key}: ${payload.value} to app store`
    // );
    state.campaignData[payload.key] = payload.value;
  }),
  clearCampaignData: action((state) => {
    ////console.log(`[STORE] cleared campaign data`);
    state.campaignData = {};
  }),

  // Flow config
  flowConfig: persist({}, { storage: "localStorage" }),
  cacheFlowConfig: action((state, payload) => {
    //console.log(`[STORE] caching flow.${payload.id} config to app store`);
    state.flowConfig[payload.id] = payload;
  }),

  flowFilters: persist({}, { storage: "localStorage" }),
  cacheFlowFilters: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.flowId} attributes to app store`);
    state.flowFilters[payload.flowId] = {
      filters: payload.filters,
      last_fetched: Date.now() / 1000,
    };
  }),

  // Notes
  notes: {},
  cacheNotes: action((state, payload) => {
    //console.log(`[STORE] caching flow.${payload.id} config to app store`);
    state.notes[payload.id] = {};
    state.notes[payload.id][payload.phone] = payload.note;
  }),

  // Flow status breakdown
  analytics: {},
  cacheAnalytics: action((state, payload) => {
    //console.log(
    //   `[STORE] caching flow.${payload.id} status breakdown to app store`
    // );
    state.flowConfig[payload.id] = payload;
  }),

  playground: persist({}, { storage: "localStorage" }),
  cachePlayground: action((state, payload) => {
    //console.log(
    //   `[STORE] caching flow.${payload.flowId} playground to app store`
    // );
    state.playground[payload.flowId] = payload;
  }),

  flowTemplates: persist({}),
  cacheFlowTemplates: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.sourceId} templates to app store`);

    if (state.flowTemplates[payload.sourceId]) {
      state.flowTemplates[payload.sourceId] = {
        ...state.flowTemplates[payload.sourceId],
        ...payload.templates,
      };
    } else state.flowTemplates[payload.sourceId] = payload.templates;
  }),

  choices: [],
  cacheChoices: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.length} choices to app store`);
    state.choices = payload;
  }),

  inputs: {},
  cacheInputs: action((state, payload) => {
    //console.log(`[STORE] caching ${payload.key} input to app store`);
    state.inputs[payload.key] = payload.input;
  }),
});
