import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";

interface RemoveQuickReply {
  flowId: string;

  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useRemoveQuickReply = ({
  flowId,
  refetchKey,
}: RemoveQuickReply) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["delete", "quick_reply"],
    mutationFn: async (quickReplyTrigger: string) => {
      const token = localStorage.getItem('token')

      const response = await axios.delete<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/quickreplies`,
        {
          params: {
            trigger_text: quickReplyTrigger,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({ title: "Quick reply deleted", status: "success" });
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to delete new quick reply",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
