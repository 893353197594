import React from "react";
import { HStack, Stack } from "@chakra-ui/react";
import { Outlet, useParams } from "react-router-dom";
import { Appbar } from "../../../components/Appbar";
import { Button } from "../../../components/Button";
import { productBackgroundNeutral } from "../../../theme";
import { usePing } from "../../../data/support/usePing";
import { ChatList } from "./ChatList";

export const Support = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;
  const { data, isLoading, error } = usePing(flowId);


  return (
    <HStack
      h="100vh"
      spacing={0}
      bg={productBackgroundNeutral}
      // templateColumns="18rem auto"
    >
      <Stack
        className="max-h-full overflow-y-scroll"
        spacing={0}
        h="100%"
        bg={productBackgroundNeutral}
        borderRightWidth="thin"
        minW="18rem"
      >
        <Appbar
          className="overflow-y-scroll static w-full"
          boxShadow="sm"
          borderBottomWidth="thin"
          variant="sticky"
          title="Inbox"
          trailing={
            <Button visibility="hidden" size="sm">
              Assign
            </Button>
          }
        />
        <ChatList flowId={flowId} />
      </Stack>
      <Outlet />
    </HStack>
  );
};
