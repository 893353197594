import axios from "axios";
import config from "../config";

const apiRoot = "v2/bot_analytics";

export const getBotAnalyticsData = async () => {
  const response = await axios.get(`${config.url}/${apiRoot}/active_users`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true
  });
  return response.data;
};

export const getFlowUsers = async (flowId) => {
  const response = await axios.get(`${config.url}/${apiRoot}/${flowId}/users`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true
  });
  return response.data;
};
