import React, { useEffect, useRef, useState } from "react";
import {
  Button, Box, VStack, Image, Flex, FormControl, FormErrorMessage, FormLabel,
  Stack, Input, InputGroup, InputRightElement, Text
} from "@chakra-ui/react";
import AssesshubLogo from '../img/assesshub_logo.png';
import LoginImage from '../img/login_background.jpg'
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import * as Yup from "yup";
import { loginUser, adminloginUser } from "../api/auth";
import { AuthContext } from "../store/authContext";
import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";

const LoginInfoSchema = Yup.object().shape({
  email: Yup.string().max(100, "Too long!").email("Not a valid email!").required('Email is required!'),
  redirect: Yup.boolean()
})


export const Login = () => {
  const { user, login, isAuthenticated } = React.useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ email: "" });
  const [disablePage, setDisablePage] = useState(false);
  const passwordRef = useRef<HTMLInputElement>(null); // Use ref for password
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  const handleShowClick = () => setShow(!show);

  const handleTokenLogin = (token: string, id: string, email: string, username: string, role: string) => {
    localStorage.setItem('token', JSON.stringify(token));
    localStorage.setItem('user', JSON.stringify({ id, email, username, role, redirect: true }));
    navigate('/');
  };

  const handleSignIn = async (email: string, password: string, redirect: boolean) => {
    setDisablePage(true);
    try {
      const data = await loginUser({ email, password, redirect });
      let admin_data = await adminloginUser({ email, password, redirect })
      if (data?.success) {
        localStorage.setItem("token", JSON.stringify(data.token));
        localStorage.setItem("user", JSON.stringify(data.user));
        login(data.user);
        navigate('/');
      } else {
        setErrorMessage(data?.error || "Login failed");
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("An error occurred during login.");
    } finally {
      setDisablePage(false);
      if (passwordRef.current) passwordRef.current.value = ""; // Clear password
    }
  };

  const validateForm = async () => {
    try {
      // Validate email using Yup schema
      await LoginInfoSchema.validate({ email }, { abortEarly: false });
      setErrors({ email: "" });
  
      // Custom validation for password
      const password = passwordRef.current?.value;
      if (!password) {
        setPasswordError("Password is required!");
        return false;
      }
      setPasswordError(""); // Clear password error if valid
      return true;
    } catch (validationErrors) {
      // Handle Yup validation errors for email
      const newErrors = { email: "" };
      validationErrors.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      const password = passwordRef.current?.value;
      await handleSignIn(email, password, false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminLoginToken = params.get('token');
    const id = params.get('id');
    const email = params.get('email');
    const username = params.get('username');
    const role = params.get('role');

    if (adminLoginToken) {
      handleTokenLogin(adminLoginToken, id!, email!, username!, role!);
    }

    const token = localStorage.getItem('token');
    const userObject = localStorage.getItem("user");
    if (userObject) {
      const userData = JSON.parse(userObject);
      if (userData?.email) login(userData);
    }
  }, [isAuthenticated, login]);

  return (
    <Flex height="100vh" align="center" justify="center">
      <Box display={{ base: 'none', lg: 'block' }} width="75%" height="100vh">
        <Image src={LoginImage} alt="Background Image" objectFit="cover" height="100%" width="100%" />
      </Box>
      <VStack spacing={8} width={{ base: '100%', md: '50%' }} p={8}>
        <Box mb={8}>
          <Image src={AssesshubLogo} alt="Assesshub Logo" />
          <form onSubmit={handleSubmit}>
            <Stack w="full" spacing={3}>
              <FormControl isInvalid={errors.email !== ""}>
                <FormLabel>Email Address</FormLabel>
                <Input
                  type="email"
                  placeholder="Email address"
                  borderColor="pink.500"
                  maxLength={100}
                  minLength={5}
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={passwordError !== ""}>
                <FormLabel>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    ref={passwordRef}
                    borderColor="pink.500"
                    pr="4.5rem"
                    type={show ? 'text' : 'password'}
                    placeholder="Password"
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {show ? <IoMdEyeOff size="80%" /> : <IoMdEye size="80%" />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{passwordError}</FormErrorMessage>
              </FormControl>


              <Button type="submit" colorScheme="pink" variant="solid" width="full" mt={2} isDisabled={disablePage}>
                Login
              </Button>

              {errorMessage && (
                <Box bg="white" border="1px solid tomato" borderRadius="md">
                  <Flex align="center">
                    <Box px={2}>
                      <MdErrorOutline color="red" size={22} />
                    </Box>
                    <Text fontSize="sm" color="red" m={2}>{errorMessage}</Text>
                  </Flex>
                </Box>
              )}
            </Stack>
          </form>
        </Box>
      </VStack>
    </Flex>
  );
};
