import React, { useState} from "react";
import InviteMembers from "../components/SchedulerInterview/InviteMembers"
import ScheduleInterviews from "../components/SchedulerInterview/ScheduleInterviews"
import ScheduledInterviews from "../components/SchedulerInterview/ScheduledInterviews"
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { INTERVIEWER_RESTRICTED } from "../constants/feature_flags";
import { AuthContext } from "../store/authContext";
import {
    Box,
    Text,
    Divider,
    VStack
} from "@chakra-ui/react";



const Sidebar = ({ selectedPage, setSelectedPage, isAdmin }) => {

    const menuItems = !isAdmin
        ? ['Invite Members', 'Schedule Interviews', 'Scheduled Interviews']
        : ['Schedule Interviews', 'Scheduled Interviews'];

    return (
        <Box className="sticky top-0 h-screen w-60 bg-white shadow-md p-2">           
            <Box className="h-12 flex items-center justify-left px-2">
                <Text className="font-bold text-md text-[#34456a] text-center">
                    Interview Scheduler
                </Text>
            </Box>

            <Divider borderColor="gray.300" borderWidth="1px" my={2} />

            <VStack spacing={1} align="stretch" mt={4} px={2}>
                {menuItems.map((text) => (
                    <Box
                        key={text}
                        onClick={() => setSelectedPage(text)} // Set the selected page on click
                        className={`px-2 py-2 rounded-md hover:bg-[#d0d5dd] hover:cursor-pointer transition duration-200
                            ${selectedPage === text ? 'bg-[#d0d5dd]' : 'bg-white'}` // Change background if selected
                        }
                    >
                        <Text className="text-sm font-semibold">{text}</Text>
                    </Box>
                ))}
            </VStack>
        </Box>
    );
};



const MainWindow = ({ selectedPage, isAdmin }) => {
    if (selectedPage === 'Invite Members' && !isAdmin) {
        return <InviteMembers />;
    }

    switch (selectedPage) {
        case 'Schedule Interviews':
            return <ScheduleInterviews />;
        case 'Scheduled Interviews':
            return <ScheduledInterviews />;
        default:
            return null;
    }
};



const Scheduler = () => {

    const isFeatureEnabled = (feature: string) => {
        return (featureFlags?.flags ?? []).includes(feature);
    };
    const { user, isAuthenticated } = React.useContext(AuthContext);
    const { data: featureFlags } = useFeatureFlags("", user.role);

    const isAdmin = isFeatureEnabled(INTERVIEWER_RESTRICTED)
    // console.log('IsAdmin',isAdmin);
    
    const [selectedPage, setSelectedPage] =  useState(isAdmin ? 'Schedule Interviews' : 'Invite Members');

    return (
        <div className="flex">

            <Sidebar setSelectedPage={setSelectedPage} selectedPage={selectedPage} isAdmin={isAdmin} />

            <div className="flex-grow p-6">
                <MainWindow selectedPage={selectedPage} isAdmin={isAdmin} />
            </div>
        </div>
    );
}

export default Scheduler;