import React, { forwardRef, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Box, BoxProps, Checkbox, Stack, Text, Wrap } from "@chakra-ui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { SortUpDown } from "../components/SortUpDown";
import { selectionInvite, UserRecord } from "../api/users";
import TimeAgo from "javascript-time-ago";
import StatusTag from "./Status";
import { AvatarTile } from "./AvatarTile";
import { BsArrowUpRight } from "react-icons/bs";
import { DatePicker } from "./Input";
import { FilterQuery } from "./Filter/models";
import Progress from "./Feedback/Progress";
import { DashboardNote } from "./dashboardnote";
import StatusDropdown from "./StatusDropdown";

interface InfiniteTableProps extends BoxProps {
  flowId: string;
  banner?: React.ReactNode;
  actions?: React.ReactNode;
  records: UserRecord[]; // data to render
  hasMore: boolean;
  sort?: string[]; // table keys to enable sorting
  loading?: boolean;
  loadMoreRecords?: () => void;
  onSort?: (columnKey: string, order: "asc" | "desc") => void;
  onFilter?: (filters: FilterQuery[]) => void;
  onRowsSelected?: (selectedRows: string[]) => void;
  onSelectAll?: (check: boolean) => void;
  onCellClick?: (record: UserRecord, columnKey: string, data?: any) => void;
  onEditNote?: () => void;
  editNote?: boolean;
  setrowIndex?: React.Dispatch<React.SetStateAction<number>>;
  onNoteClose?: () => void;
  onStatusClose?: () => void;
  editStatus?: boolean;
  handleupdateUserDetails?: (action: string, data?: any) => void;
  fetchUser?: (_skip:number) => void;
  editableCell?: {row: number ; col: number}
}

interface TableColumn {
  title: string;
  key: string;
  canSort: boolean;
  filters: "date" | "none";
}

const getPropertyType = (text: string) => {
  if (text === "Last Interaction") return "time";
  if (text === "Invited") return "date";
  if (text === "Status") return "status";
  if (["Milestones", "Attributes", "Tags"].includes(text)) return "tags";
  if (text === "Name") return "avatar";
  if (text === "Comment") return "note";
  if (text === "Selection Status") return "selectionStatus"
  return "text";
};

export const InfiniteTable = forwardRef<HTMLDivElement, InfiniteTableProps>(
  (
    {
      flowId,
      records,
      banner,
      actions,
      hasMore = true,
      sort = ["Invited", "Last Interaction"],
      loading = false,
      loadMoreRecords,
      onSort,
      onFilter,
      onRowsSelected,
      onSelectAll,
      onCellClick,
      onEditNote,
      editNote,
      onNoteClose,
      onStatusClose,
      editStatus,
      setrowIndex,
      handleupdateUserDetails,
      fetchUser,
      editableCell,
      ...props
    },
    ref
  ) => {
    const [tableColumns, setTableColumns] = useState<TableColumn[]>([]);
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const [tableFilters, setTableFilters] = useState<FilterQuery[]>([]);
    const [selectedIndex, setselectedIndex] = useState<number>(-1);


    useEffect(() => {
      if (records.length !== 0) {
        setTableColumns(
          Object.keys(records[0].data).map((tableColumnKey) => ({
            key: tableColumnKey,
            title: tableColumnKey,
            canSort: sort.includes(tableColumnKey),
            filters: "none",
            // filters: sort.includes(tableColumnKey) ? "date" : "none",
          }))
        );
      }
    }, [records]);

    useEffect(() => {
      onFilter?.(tableFilters);
    }, [tableFilters]);

    const allChecked = checkedRows.length === records.length;
    const isIndeterminate =
      checkedRows.length === 0 ? false : checkedRows.length !== records.length;

    useEffect(() => onRowsSelected?.(checkedRows), [checkedRows]);

    const checkAll = () => {
      setCheckedRows(records.map((record) => record.id));
      onSelectAll?.(true);
      return checkedRows;
    };

    const unCheckAll = () => {
      onSelectAll?.(false);
      setCheckedRows([]);
    };

    return (
      <Stack
        pos="relative"
        flex="0 1 auto"
        borderWidth="thin"
        spacing={0}
        overflowX="scroll"
        ref={ref}
        {...props}
      >
        {banner && banner}
        {/* Header */}
        <Stack
          minW="full"
          w="fit-content"
          direction="row"
          flex="0 0 2rem"
          height="2rem"
          borderBottomWidth="thin"
          boxShadow="sm"
          bg="gray.100"
        >
          <Box
            hidden={records.length === 0}
            py={2}
            pl={2}
            w="60px"
            wordBreak="break-word"
          >
            <Checkbox
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              px={4}
              onChange={(e) => (e.target.checked ? checkAll() : unCheckAll())}
              borderColor="gray.400"
            />
          </Box>

          {tableColumns.map((column, index) =>
            // TABLE CELL
            column.filters === "date" ? (
              <Stack
                py={2}
                px={2}
                w="150px"
                wordBreak="break-word"
                flex="unset !important"
                flexGrow={0}
                key={index}
                direction="row"
                align="center"
                _hover={{ bg: "#f3e5f4", cursor: "pointer" }}
              >
                <DatePicker
                  title={column.title}
                  date={{
                    from: undefined,
                    to: undefined,
                  }}
                  mode={"range"}
                  onDateSelected={(range) => {
                    let dateFilters = [range.from.getTime() / 1000];
                    if (range.to) dateFilters.push(range.to.getTime() / 1000);
                    setTableFilters((prevState) => {
                      const filterKey =
                        column.title === "Invited"
                          ? "timestamp"
                          : "last_user_message";
                      const removedFilters = prevState.filter(
                        (filter) => filter.key !== filterKey
                      );
                      return [
                        ...removedFilters,
                        { key: filterKey, operation: "in", value: dateFilters },
                      ];
                    });
                  }}
                  onDateClear={() => {
                    setTableFilters((prevState) => {
                      const filterKey =
                        column.title === "Invited"
                          ? "timestamp"
                          : "last_user_message";
                      const removedFilters = prevState.filter(
                        (filter) => filter.key !== filterKey
                      );
                      return [
                        ...removedFilters,
                        { key: filterKey, operation: "in", value: null },
                      ];
                    });
                  }}
                  py={1}
                >
                  <Text fontWeight="bold">{column.title}</Text>
                </DatePicker>
                {column.canSort && (
                  <SortUpDown onSort={(order) => onSort?.(column.key, order)} />
                )}
              </Stack>
            ) : (
              <Stack
                py={2}
                w="150px"
                wordBreak="break-word"
                flex="unset !important"
                flexGrow={0}
                key={index}
                direction="row"
                align="center"
              >
                <Text fontWeight="bold">{column.title}</Text>
                {column.canSort && (
                  <SortUpDown onSort={(order) => onSort?.(column.key, order)} />
                )}
              </Stack>
            )
          )}
        </Stack>

        <Progress
          visibility={loading ? "visible" : "hidden"}
          size="sm"
          isIndeterminate
          colorScheme="pink"
          bg="transparent"
        />

        <Box
          id="scrollableDiv"
          style={{
            minHeight: "0",
            flex: "1 1 auto",
            height: "calc(100vh - 12rem)",
            overflow: "auto",
            width: "fit-content",
            borderBottomWidth: "thin",
          }}
        >
          <InfiniteScroll
            dataLength={records.length} //This is important field to render the next data
            next={() => loadMoreRecords?.()}
            hasMore={hasMore}
            loader={<Progress size="sm" bg="transparent" />}
            scrollableTarget="scrollableDiv"
          >
            {records.map((user, rowIndex) => (
              <Stack
                key={user.id}
                minW="full"
                w="fit-content"
                direction="row"
                minH="2rem"
                borderBottomWidth="thin"
                align="center"
                role="group"
                _hover={{ bg: "pink.50" }}
                transition="background 100ms ease-out 0s"
              >
                <Stack py={2} w="60px" wordBreak="break-word">
                  <Checkbox
                    alignSelf="center"
                    isChecked={checkedRows.includes(user.id)}
                    borderColor="gray.400"
                    onChange={(event) => {
                      const { checked } = event.target;
                      if (checked) {
                        setCheckedRows([...checkedRows, user.id]);
                      } else {
                        setCheckedRows(
                          checkedRows.filter((id) => id !== user.id)
                        );
                      }
                      onSelectAll?.(false);
                    }}
                  />
                </Stack>
                {Object.keys(user.data).map((columnKey, index) => (
                  <TableCell
                  flowId={flowId}
                    key={`${user.id}_${index}`}
                    editNote={editNote}
                    editStatus={editStatus}
                    setrowIndex={setrowIndex}
                    onNoteClose={() => onNoteClose?.()}
                    onStatusClose={() => onStatusClose?.()}
                    fetchUser={(_skip:number) => {
                      fetchUser(_skip)
                    }}
                    handleupdateUserDetails={(action: "hide" | "update", data?: any) => {
                      handleupdateUserDetails(action, data)
                    }}
                    type={getPropertyType(columnKey)}
                    data={user.data[columnKey]}
                    onClick={() => {
                      onCellClick?.(user, columnKey, index);
                      setselectedIndex(rowIndex);
                    }}
                    editable={rowIndex ===selectedIndex  && editableCell.col === index}
                    userData={user}
                  />
                ))}
              </Stack>
            ))}
          </InfiniteScroll>
        </Box>
        {actions && (
          <Box
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            background="rgba(255,255,255,.5)"
            backdropFilter="blur(32px)"
            borderTopWidth="thin"
            boxShadow="md"
            zIndex={200}
          >
            {actions}
          </Box>
        )}
      </Stack>
    );
  }
);
interface TableCellProps extends BoxProps {
  type: "text" | "date" | "time" | "status" | "avatar" | "tags" | "note" | "selectionStatus";
  data: any;
  editNote?: boolean;
  handleupdateUserDetails?: (action: string, data?: any) => void;
  fetchUser?: (_skip:number) => void;
  onNoteClose?: () => void;
  onStatusClose?: () => void;
  editStatus?: boolean;
  editable?: boolean;
  rowIndex?: number, // Add a rowIndex prop
  columnIndex?:number,
  setrowIndex?: React.Dispatch<React.SetStateAction<number>>;
  userData: UserRecord
  flowId: string

}

const TableCell = ({ type, data, editNote, onNoteClose, onStatusClose, editStatus,setrowIndex, handleupdateUserDetails, fetchUser, editable, userData, rowIndex, flowId, columnIndex, ...props }: TableCellProps) => {
  const baseOptions = [
    { label: 'Selected', value: 'Selected' },
    { label: 'Rejected', value: 'Rejected' },
    { label: 'On hold', value: 'On hold' },
  ];

  const getJioHireOptions = (currentStatus: string) => {
    switch (currentStatus) {
      case "Pre-offer Completed":
        return [
          { label: 'Initiate Onboarding', value: 'Initiate Onboarding'},
          { label: 'Rejected', value: 'Rejected' }
        ];
      case "Test Completed":
        return [
          { label: 'Initiate Pre-offer', value: 'Initiate Pre-offer'},
          { label: 'Rejected', value: 'Rejected' }
        ];
      case "Onboarding Completed":
        return [
          { label: 'Rejected', value: 'Rejected' }
        ]; // Return empty array for Onboarding Completed status
      default:
        return [
          { label: 'Rejected', value: 'Rejected' }
        ];
    }
  };

  const options = flowId === 'jio-hire'
  ? getJioHireOptions(userData.data["Status"])
  : baseOptions;

  const handleSpecialSelection = async (selection: string) => {
    if (flowId === 'jio-hire' && (selection === 'Initiate Pre-offer' || selection === 'Initiate Onboarding')) {
      const token = localStorage.getItem('token');

      const inviteData = {
        users: {
          operation: "and",
          filters: [
              {
                  key: "conversation_id",
                  operation: "in",
                  value: [
                    userData.data["Phone Number"]
                  ]
              }
          ]
        },
        templateId: selection === 'Initiate Pre-offer' ? 'notification_7200924' : 'notification_7200938',
        token: token,
        flowId: "jio-hire"
      };

      try {
        const response = await selectionInvite(inviteData);
      } catch (error) {
        console.error('Error sending invite:', error);
      }
    }
  };

  return (
    <Stack
      py="3"
      w="150px"
      wordBreak="break-word"
      flex="unset !important"
      flexGrow={0}
      {...props}
    >
      {type === "text" && <Text>{`${data}`}</Text>}
      {type === "date" && <Text>{parseDate(data)}</Text>}
      {type === "time" && <Text>{getTimeAgo(data)}</Text>}
      {type === "tags" && (
        <Wrap>
          {Object.keys(data).map((key, idx) => (
            <StatusTag
              key={`${key}_${idx}`}
              variant="tag"
              showTooltip
              tag={data[key]}
            />
          ))}
        </Wrap>
      )}
      {type === "avatar" && (
        <Stack justify="space-between" align="center" direction="row">
          <AvatarTile name={data} cursor="pointer" />
          <Box visibility="hidden" _groupHover={{ visibility: "visible" }}>
            <BsArrowUpRight />
          </Box>
        </Stack>
      )}
      {type === "status" && (
        <Wrap>
          <StatusTag showTooltip showDot tag={data} />
        </Wrap>
      )}
      {type === "note" && (editable && editNote ?
       <DashboardNote
          note={data}
          allowEditing
          onChange={(dashboard_note) => {
            handleupdateUserDetails("update", { dashboard_note });

          }}
          onClose={() => onNoteClose?.()}
        >
      </DashboardNote> : <Text>{`${data}`}</Text>

      )}
      {type === "selectionStatus" && (editable && editStatus?
      <StatusDropdown
        options={options}
        initial={data}
        onSelect= {(selection_status) => {
          handleupdateUserDetails("update", { selection_status });
          onStatusClose?.()
          handleSpecialSelection(selection_status);
        }}
      />
        :  <Wrap>
        <StatusTag showTooltip showDot tag={data} />
      </Wrap>
      )}
    </Stack>
  );
};

export const parseDate = (
  text: string | number = "",
  format: string = "mmm dd hh:MM tt"
) => {
  if (typeof text === "number") {
    try {
      let now = new Date(text * 1000);
      return dateFormat(now, format);
    } catch (error) {
      console.log(error);
      //
      let now = new Date(0);
      return dateFormat(now, format);
    }
  }

  try {
    let now: Date;
    if (!text || text === "") now = new Date();
    else now = new Date(text.replace(/-/g, "/"));
    return dateFormat(now, format);
  } catch (error) {
    let a = text.split(/[^0-9]/).map((number) => parseInt(number));
    let now = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    // const formattedTime = now.toLocaleTimeString("en-US", { hour12: true });
    return dateFormat(now, format);
  }
};

export const getTimeAgo = (text: number | string, mini = false) => {
  if (!text) return "";

  const timeAgo = new TimeAgo("en-US");

  if (typeof text === "string") {
    return mini
      ? timeAgo.format(new Date(text), "mini")
      : timeAgo.format(new Date(text));
  }

  return mini
    ? timeAgo.format(new Date(text * 1000), "mini")
    : timeAgo.format(new Date(text * 1000));
};
