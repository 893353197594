import axios from "axios";
import config from "../config";
import {
  IFetchBotsRequest,
  IFetchBotsOrgRequest,
  IFlowRequest,
  IAnalyticsRequest,
  OrgFlow,
  IFetchBotsOfUserRequest,
} from "../models";
import { getThisMonth, getThisWeek } from "../utils/helpers";

interface IAuthUser {
  id: string;
  createdTime: string;
  fields: {
    name?: string;
    company_id: number;
    company?: string;
    email: string;
  };
}
/**
 * Get the bots present in the company
 * @param name, email, token
 * @returns List of bots assigned to the user
 */
export const getBotsAssigned = async ({
  name,
  email,
  token,
}: IFetchBotsRequest) => {
  try {
    let response = await axios.get<IAuthUser>(
      `${config.url}/recruiter/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    let user = response.data;

    if (!user) return Promise.reject(`failed to fetch the user: ${user.id}`);

    localStorage.setItem("recruiter", JSON.stringify(user));
    localStorage.setItem("recruiterId", user.id);

    if (user.fields.company)
      return await getBotsByOrg({ company_id: user.fields.company_id, token });

    return Promise.reject(`no bots found for the user: ${user.id}`);
  } catch (error) {
    // if (axios.isAxiosError(error)) {
    // if (error.response.status === 404)
    // return await createNewUser({ name, email, token });
    // }
    console.log('getBotsAssigned error:', error)
  }
};


/**
 * Get the bots assigned to the user
 * @param name, email, token
 * @returns List of bots assigned to the user
 */
export const getBotsAssignedToUser = async ({
  id,
  token,
}: IFetchBotsOfUserRequest) => {
  try {
    let response = await axios.get(
      `${config.url}/bots/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
    );

    return response.data
  } catch (error) {
    console.log('getBotsAssignedToUser error:', error)
  }
};


/**
 * Creates a new user
 * @param name, email, token
 * @returns List of bots assigned to the user
 */
export const createNewUser = async ({
  name,
  email,
  token,
}: IFetchBotsRequest) => {
  let response = await axios.post<IAuthUser>(
    `${config.url}/recruiter/new`,
    {
      email: email,
      name: name,
    },
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  if (response.status !== 200)
    return Promise.reject(`failed to create new user, ${response.statusText}`);

  let user = response.data;


  localStorage.setItem("recruiter", JSON.stringify(user));
  localStorage.setItem("recruiterId", user.id);

  if (user.fields.company)
    return await getBotsByOrg({ company_id: user.fields.company_id, token });

  return Promise.reject(`no bots found for the user: ${user.id}`);
};

/**
 * Get the bots assigned to the org
 * @param company (aka. org)
 * @returns the list of bots assigned to the org
 * @throws error if the org is not found
 */
const getBotsByOrg = async ({ company_id, token }: IFetchBotsOrgRequest) => {
  console.log(`fetching bots for org: ${company_id}`);

  let response = await axios.get<OrgFlow[]>(
    `${config.url}/all_companies/${company_id}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  if (response.status != 200)
    return Promise.reject(
      `failed to fetch bots for ${company_id}, ${response.statusText}`
    );

  return response.data;
};

/**
 *
 * @param flowId
 * @param delta - number of days or object with from and to dates
 * @returns the analytics data for the flow
 */
export const fetchDhwaniAnalytics = async ({
  flowId,
  delta,
  token,
}: IAnalyticsRequest) => {
  console.log(`fetching dhwani analytics for ${flowId}`);

  let params: { from_time: number; to_time: number };

  switch (delta) {
    case 7:
      const { start: weekStart, end: weekEnd } = getThisWeek();
      params = {
        from_time: weekStart.getTime() / 1000,
        to_time: weekEnd.getTime() / 1000,
      };
      break;

    case 30:
      const { start: monthStart, end: monthEnd } = getThisMonth();
      params = {
        from_time: monthStart.getTime() / 1000,
        to_time: monthEnd.getTime() / 1000,
      };
      break;

    default:
      if (typeof delta !== "object") break;

      if (Object.keys(delta).length !== 0) {
        const from = delta.from.getTime() / 1000;
        const to = delta.to.getTime() / 1000;

        if (from === to)
          params = {
            from_time: from,
            to_time: from + 86399,
          };
        else
          params = {
            from_time: from,
            to_time: to,
          };

        break;
      }
  }

  let response = await axios.get<{
    From: string;
    To: string;
    "Top Queries": { [query: string]: number };
    "Query stats": { [query: string]: number };
    "List of unanswered questions": string[];
    "Campaign Type Analytics": any[];
    "Notification Analytics": any[];
  }>(`${config.url}/faq_analytics/${flowId}`, {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  });

  if (response.status != 200)
    return Promise.reject(`manager assignment failed`);

  return response.data;
};

/**
 *
 * @param flowId
 * @returns the campaign object with {campaignName: campaignId} for the flow
 */
export const getCampaignInfo = async ({ flowId, token }: IFlowRequest) => {
  console.log(`fetching campaigns for ${flowId}`);

  let response = await axios.get<{ [name: string]: string }>(
    `${config.url}/campaign_info/${flowId}`,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  if (response.status != 200)
    return Promise.reject(`fetching campaign names failed`);

  return response.data;
};
