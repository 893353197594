import React, { forwardRef, useState } from "react";
import {
  Stack,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Portal,
  useToast,
} from "@chakra-ui/react";
import { CSVInput } from "../../components/Input/Csv";
import { ExportIcon } from "../../constants/icons";
import { InterviewerSchedulerSupportUser } from "../../models/support";
import { generateCSVMapping } from "../../utils";
import { useNewInterviewerSupportUsers } from "../../mutations/support/useNewInterviewerSupportUsers";
import { Button } from "../../components/Button";

import { v4 as uuidv4 } from 'uuid';

/**
 * helper function to get the support users from the csv
 * @param csvData
 * @returns array of support users
 */

const user = JSON.parse(localStorage.getItem('user'));

const getSupportUsersFromCSV = (
  csvData: {
    [key: string]: any;
  }[]
): InterviewerSchedulerSupportUser[] => {
  let supportUsers: InterviewerSchedulerSupportUser[] = [];
  
  let headerMappingKeys = {
    Name: `(${["name", "full_name", "employee_name"].join("|")})`,
    Phone: `(${["phone", "mobile", "contact_number", "telephone"].join("|")})`,
    Email: `(${["email", "mail", "email_address"].join("|")})`,
    bot_flow_id: `(${["bot_flow_id", "flow_id", "bot_id", "bot_flow"].join("|")})`
  };
  


  let headers = generateCSVMapping(csvData, headerMappingKeys);


  

  csvData.map((eachRow) => {
    const userEmail = eachRow?.[headers["Email"] ?? ""] ?? "";
    const flowGroup = eachRow?.[headers["bot_flow_id"] ?? ""] ?? "";

    if (userEmail)
      supportUsers.push({        
        hm_name: eachRow?.[headers["Name"] ?? ""] ?? "",
        hm_number : eachRow?.[headers['Phone']?? ""] ?? "",
        admin: false,
        hm_email: eachRow?.[headers["Email"] ?? ""] ?? "",
        hm_code : uuidv4(),
        hm_org_ids : user.company_id,
        hm_flow_ids:  
        flowGroup === ""
            ? []
            : typeof flowGroup === "string"
            ? flowGroup.split(",")
            : flowGroup,
      });
  });

  
  return supportUsers;
};

interface NewSupportUserProps {
  flowId: string;
  isOpen: boolean;
  onClose?: () => void;
}


export const NewSupportUser = forwardRef<HTMLDivElement, NewSupportUserProps>(
  ({ flowId, isOpen, onClose }, _) => {
    const toast = useToast();
    const [csvData, setCsvData] = useState<InterviewerSchedulerSupportUser[]>([]);

    // console.log('CSV DATA', csvData);
    

    const addNewSupportUsers = useNewInterviewerSupportUsers({
      flowId,
      refetchKey: ["support", "users", flowId],
    });

    // console.log('addNewSupportUsers', addNewSupportUsers);
    

    return (
      <Portal>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader borderBottomWidth="thin" boxShadow="sm">
              <Heading size="sm">Import Users</Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody as={Stack} py={4}>
              <Button
                onClick={() => {
                  window.open("/assets/sample_is.csv");
                }}
                leftIcon={<ExportIcon />}
                variant="tertiary"
              >
                Download Sample CSV
              </Button>
              <CSVInput
                onParse={(csvRecords) => {
                  if (csvRecords.length === 0) return setCsvData([]);
                  const parsedRecords = getSupportUsersFromCSV(csvRecords);
                  if (csvRecords.length !== 0 && parsedRecords.length === 0)
                    return toast({
                      title: "No Users Detected",
                      description: "Please check the CSV file and try again",
                      status: "warning",
                    });

                  setCsvData(parsedRecords);
                }}
              />
            </ModalBody>
            
            <ModalFooter hidden={csvData.length === 0} pt={0} pb={4}>
              <Button
                isLoading={addNewSupportUsers.isLoading}
                isDisabled={csvData.length === 0}
                onClick={() => {
                  addNewSupportUsers.mutate(csvData);
                  onClose?.();
                }}
              >
                {csvData.length === 0
                  ? "Import users"
                  : `Import ${csvData.length} users`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Portal>
    );
  }
);
