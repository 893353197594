import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";
import { MutationInput } from "../../models";

export const useNewNote = ({ flowId, refetchKey = [] }: MutationInput) => {
  const queryClient = useQueryClient();
  const { user, getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["new", "user_note"],
    mutationFn: async ({
      text,
      userId,
      sessionId,
    }: {
      text: string;
      userId: string;
      sessionId?: string;
    }) => {
      const token = localStorage.getItem('token')

      const response = await axios.post<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/user_notes/new`,
        {
          text,
          user_id: userId,
          session_id: sessionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Email": user.email,
            "dashboard-user-id": user.email,
            "user-id": user.email,
          },
          withCredentials: true,
        }
      );

      return response.data;
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
