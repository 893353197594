import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Stack,
  Input,
  IconButton,
  ButtonGroup,
  Text,
  Drawer,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useToast,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Switch,
  Kbd,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ReactDragListView from "react-drag-listview";

import { Appbar } from "../components/Appbar";
import {
  say,
  ask_and_acknowledge,
  branch,
  Branch,
  IntentUnion,
  MicroFlow,
  MediaInput,
  time_delay,
  AITurn,
} from "../models/intent";
import { Button } from "../components/Button";
import { BackIcon, CloseIcon, SendIcon } from "../constants/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IntentView } from "../components/IntentView";
import { saveEvent } from "../api/events";
import { TextField } from "../components/TextField";
import { Toolbar, AddChoices } from "../components/Toolbar";
import { ToolbarComponent } from "../components/Toolbar/ToolbarComponent";
import {
  defaultMedia,
  suggestedDatapoints,
  suggestedFollowups,
  toolbarActions,
} from "../constants";
import backIcon from "../img/back_curve.svg";
import { AvatarTile } from "../components/AvatarTile";
import { createAIComponent as generateAIIntent } from "../api/flow";
import { SelectableImage } from "../components/SelectableImage";
import { useStoreActions } from "../hooks/store";
import { ChatBubble } from "../components/ChatBubble";
import { useAuthToken } from "../hooks/useAuthToken";
import {
  updateBranchInPath,
  getIntentsByPath,
  updateIntentsByPath,
} from "../utils";
import { useUploadFile } from "../mutations/root/useUploadFile";
import { SelectAttributes } from "../components/Input";

type FlowBuilderProps = {
  flow: MicroFlow;
  persona: string;
  tone: string;
  onSave?: (flow: MicroFlow, publish: boolean) => void;
};

export const FlowBuilder = ({ flow, ...rest }: FlowBuilderProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { botId, id } = useParams();
  const flowId = botId ?? id;

  const routeNavigator = useNavigate();
  const toolbarActionRef = useRef();
  const inputRef = useRef();
  const { 
    user: { email: userMail, username: userName },
    getAuthToken } = useAuthToken();

  const message = useToast();
  const uploadFileMutation = useUploadFile();
  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );
  const cacheBranchOptions = useStoreActions((actions) => actions.cacheChoices);

  const [loading, setLoading] = useState(false);
  const [microFlowName, setMicroFlowName] = useState<string>(flow.name);
  const [intentBody, setIntentBody] = useState<string>("");
  const [timeUnit, setTimeUnit] = useState<"mins" | "seconds" | "hrs" | string>(
    "seconds"
  );
  const [isDefault, setIsDefault] = useState<boolean>(false);
  const [continueFlow, setContinueFlow] = useState<boolean>(true);
  const [saveFlag, setSaveFlag] = useState(false);

  const [toolbarAction, setToolbarAction] = useState<
    "statement" | "question" | "probe" | string
  >("");
  const [media, setMedia] = useState<MediaInput>(defaultMedia);
  const [currentlyEditing, setCurrentlyEditing] = useState(-1);

  // working canvas
  const [intents, setIntents] = useState<IntentUnion[]>(flow.intents);

  /**
   * keeps track of the current position in the flow
   * this is used to add intents at the right position
   *
   * Eg. if the user is adding an intent under a branch - "yes",
   *     then the intent should be added under that branch
   *     currentPosition = 2.yes
   */
  const [currentPosition, setCurrentPosition] = useState<string>("");

  /**
   * Branching
   */
  const [branches, setBranches] = useState<string[]>([]);
  const [currentBranch, setCurrentBranch] = useState<Branch>(null);
  const [currentSelectedBranch, setCurrentSelectedBranch] =
    useState<string>("");

  const _handleKeyDown = (key: string) => {
    if (key === "Enter") {
      const disabled =
        toolbarAction === "branch"
          ? intentBody.length === 0 || branches.length === 0
          : intentBody.length === 0;

      if (disabled) return;

      addIntent();
    }
  };

  const handleToolbarChange = (action: string, disabled: boolean) => {
    if (disabled) return {};

    if ([toolbarAction].includes(action)) return closeToolbar();

    setToolbarAction(action);
  };

  const _handleDisableToolbarActions = (key: string) => {
    if (["question", "statement"].includes(key)) return false;

    // handle `probe`
    if (key === "probe") {
      if (intents.length === 0) return true;

      const lastIntent = intents[intents.length - 1];
      // can’t use probe without any question/probe before that
      // we’ll allow only two followups
      if (lastIntent.type === "ai_ask") {
        // now there are no followups
        if (lastIntent.as_ === "ask") return false;

        if (lastIntent.as_ === "probe")
          if (lastIntent.turns.length < 3) return false;

        return true;
      }

      return true;
    }

    return false;
  };

  const handleIntentEdit = (index: number, updatedIntent: IntentUnion) => {
    let currentBranchIntents: IntentUnion[] = getIntentsByPath(
      intents,
      currentPosition
    );

    currentBranchIntents = currentBranchIntents.map((intent, intentIdx) =>
      index === intentIdx ? updatedIntent : intent
    );

    setIntents(
      updateIntentsByPath(intents, currentPosition, currentBranchIntents)
    );
  };

  const handleIntentRemove = (indexToRemove: number) => {
    let currentBranchIntents: IntentUnion[] = getIntentsByPath(
      intents,
      currentPosition
    );

    currentBranchIntents = currentBranchIntents.filter((intent, intentIdx) => {
      return indexToRemove !== intentIdx;
    });

    setIntents(
      updateIntentsByPath(intents, currentPosition, currentBranchIntents)
    );

    trackMicroFlowEvent(flowId, "micro_flow_removed_intent", userMail);
  };

  const handleIntentOrderChange = (fromIndex: number, toIndex: number) => {
    let currentBranchIntents: IntentUnion[] = [
      ...getIntentsByPath(intents, currentPosition),
    ];

    const item = currentBranchIntents.splice(fromIndex, 1)[0];
    currentBranchIntents.splice(toIndex, 0, item);

    setIntents(
      updateIntentsByPath(intents, currentPosition, currentBranchIntents)
    );
  };

  const trackMicroFlowEvent = async (
    flowId: string,
    eventName: string,
    uid: string,
    data: {} = {}
  ) => {
    const token = localStorage.getItem('token')
    saveEvent({
      flowId,
      token,
      event: {
        data: {
          event_type: "micro_flow",
          ...data,
        },
        event: eventName,
        uid: uid,
      },
    });
  };

  const resetBranchOptions = () => cacheBranchOptions([]);

  const createAIComponent = async (
    intent: IntentUnion,
    userMail: string
  ): Promise<AITurn[]> => {
    const token = localStorage.getItem('token')

    try {
      const result = await generateAIIntent({
        flowId,
        intent,
        token,
        userMail,
      });

      return result;
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const addIntent = async () => {
    let intentToAdd: IntentUnion;
    let currentBranchPosition: string = currentPosition;
    let currentBranchIntents: IntentUnion[] = getIntentsByPath(
      intents,
      currentPosition
    );

    // replace attributes in the intent body
    let formattedIntentBody: string = intentBody;

    if (toolbarAction === "ask") {
      intentToAdd = ask_and_acknowledge(
        formattedIntentBody,
        rest.persona,
        rest.tone
      );

      setLoading(true);
      await createAIComponent(intentToAdd, userMail);
      setLoading(false);
    }

    // ask and acknowledgment
    else if (toolbarAction === "question") {
      intentToAdd = ask_and_acknowledge(
        formattedIntentBody,
        rest.persona,
        rest.tone
      );

      setLoading(true);
      const turns = await createAIComponent(intentToAdd, userMail);
      intentToAdd.turns = [turns.pop()];
      setLoading(false);
    }

    // time delay
    else if (toolbarAction === "delay") {
      let delay = parseInt(intentBody);
      intentToAdd = time_delay(
        `sum([time(), \`${delay}\`])`,
        `${delay} ${timeUnit}`
      );
    }

    // statement
    else if (toolbarAction === "statement") {
      intentToAdd = say(formattedIntentBody);
    }

    // media
    else if (toolbarAction === "media") {

      if (media.url === defaultMedia.url) {
        intentToAdd = {
          type: "say",
          body: formattedIntentBody,
        };
      } else {
        intentToAdd = {
          type: "media",
          body: formattedIntentBody,
          media: media.url,
          path: `${media.bucket}|${media.key}`,
          media_type: media.type,
        };
      }
      setMedia(defaultMedia);
    }

    // probe - applies only to the last intent
    else if (toolbarAction === "probe") {
      const lastIntent = currentBranchIntents[currentBranchIntents.length - 1];

      if (lastIntent.type === "ai_ask") {
        setLoading(true);
        lastIntent.as_ = "probe";
        lastIntent.context = formattedIntentBody;
        const turns = await createAIComponent(lastIntent, userMail);
        lastIntent.turns = turns;
        setLoading(false);
      }
    }

    // branch
    else {
      intentToAdd = branch(formattedIntentBody, branches);
      // TODO: update current position to the new branch
      currentBranchPosition = `${currentBranchIntents.length}.${branches[0]}`;
      setCurrentBranch(intentToAdd);
      setCurrentSelectedBranch(branches[0]);
    }

    if (intentToAdd) {
      const updatedIntents = updateIntentsByPath(intents, currentPosition, [
        ...currentBranchIntents,
        intentToAdd,
      ]);
      setIntents(updatedIntents);
    }

    // update current position
    if (currentBranchPosition !== currentPosition) {

      setCurrentPosition((currentPosition) =>
        currentPosition
          ? `${currentPosition}.${currentBranchPosition}`
          : currentBranchPosition
      );
    }

    setIntentBody("");
    setToolbarAction("");
    setBranches([]);
    resetBranchOptions();
  };

  const renderIntents = () => {
    let currentBranchIntents = getIntentsByPath(intents, currentPosition);

    return (
      <ReactDragListView
        lineClassName="dnd-line"
        nodeSelector="section"
        onDragEnd={handleIntentOrderChange}
      >
        {currentBranchIntents.map((intent, idx) => (
          <Stack
            as="section"
            role="group"
            _hover={{ visibility: "visibile" }}
            direction="row"
            my={2}
          >
            <Box w="full" key={idx} mx={3} _before={{}}>
              <IntentView
                intent={intent}
                onChange={(updatedIntent) =>
                  handleIntentEdit(idx, updatedIntent)
                }
                onRemove={(intent) => handleIntentRemove(idx)}
                onDownVote={(intent) =>
                  trackMicroFlowEvent(
                    flowId,
                    "micro_flow_downvote_generation",
                    userMail,
                    {
                      ...intent,
                    }
                  )
                }
                onUpvote={(intent) =>
                  trackMicroFlowEvent(
                    flowId,
                    "micro_flow_upvote_generation",
                    userMail,
                    {
                      ...intent,
                    }
                  )
                }
                persona={rest.persona}
                tone={rest.tone}
                intentEditing={currentlyEditing === idx}
                setIntentEditing={() => setCurrentlyEditing(idx)}
                onButtonTap={(button) => {
                  setCurrentSelectedBranch(button);
                  setCurrentPosition((currentPosition) =>
                    currentPosition
                      ? `${currentPosition}.${idx}.${button}`
                      : `${idx}.${button}`
                  );
                  if (intent.type === "branch") setCurrentBranch(intent);
                }}
              />
            </Box>
          </Stack>
        ))}
      </ReactDragListView>
    );
  };

  const saveMicroFlow = (publish: boolean = false) => {
    setSaveFlag(true);
    // TODO: handle save
    // const intents: IntentUnion[] = getAllIntents();
    rest.onSave?.(
      {
        intents,
        name: microFlowName,
        data: {
          resume_flow: continueFlow,
        },
        default: isDefault,
      },
      publish
    );

    if (publish)
      trackMicroFlowEvent(flowId, "micro_flow_publish", userMail, {
        name: microFlowName,
      });

    setIntents(intents);
  };

  // TODO: on going back, prompt to save pending changes
  // usePrompt(
  //   "Leave without saving?",
  //   saveFlag ? false : intents.length !== 0,
  //   () => {
  //     trackMicroFlowEvent(flowId, "micro_flow_generate_ack", userMail, {
  //       message: "closed without saving",
  //     });
  //   }
  // );
  const closeToolbar = () => setToolbarAction("");

  useEffect(() => {
    setIntents(flow.intents);
    setMicroFlowName(flow.name);
  }, [flow]);

  useEffect(() => {
    const _handleSwitchAction = () => {
      if (intents.length === 0) return;
      if (toolbarAction === "probe") {
        const lastIntent = intents[intents.length - 1];

        if (lastIntent.type === "ai_ask" && lastIntent.as_ === "probe") {
          setIntentBody(lastIntent.context);
        }
      }
    };

    const _focusInput = () => {
      const node = inputRef.current as HTMLDivElement;
      node?.focus();
    };

    _focusInput();
    _handleSwitchAction();
  }, [toolbarAction]);

  return (
    <Box borderRightWidth="thin">
      <SimpleGrid minH="100vh" templateRows="auto 1fr auto">
        {/* Main App Bar */}

        <Appbar
          showHeader={false}
          variant="sticky"
          boxShadow="sm"
          borderBottomWidth="thin"
        >
          <Stack py={4} direction="row" justify="space-between" align="center">
            <ButtonGroup alignItems="center">
              <BackIcon onClick={() => routeNavigator(-1)} size={18} />
              <Input
                value={microFlowName}
                variant="unstyled"
                bg="transparent !important"
                borderRadius="none"
                alignSelf="center"
                w="fit-content"
                onChange={(event) =>
                  setMicroFlowName(event.currentTarget.value)
                }
                _hover={{ borderWidth: "thin" }}
                fontWeight="medium"
              />
            </ButtonGroup>
            <ButtonGroup>
              <FormControl
                display="flex"
                borderWidth="thin"
                borderRadius="md"
                alignItems="center"
                px={3}
              >
                <FormLabel htmlFor="default-flow" mb="0">
                  Set as default
                </FormLabel>
                <Switch
                  size="sm"
                  id="default-flow"
                  defaultChecked={flow.default ?? false}
                  onChange={(event) =>
                    setIsDefault(event.currentTarget.checked)
                  }
                />
              </FormControl>
              <Button
                tooltip="save your flow"
                disabled={intents.length === 0}
                onClick={() => {
                  storeCampaignData({
                    key: "micro_flow",
                    value: microFlowName,
                  });
                  saveMicroFlow(true);
                }}
              >
                Publish
              </Button>
              {/* <Button
                tooltip="go to review page"
                disabled={intents.length === 0}
                onClick={() => {
                  onOpen();
                  storeCampaignData({
                    key: "micro_flow",
                    value: microFlowName,
                  });
                }}
              >
                Review
              </Button> */}
            </ButtonGroup>
          </Stack>
        </Appbar>

        <Stack
          as="main"
          style={{
            backgroundColor: "white",
            backgroundImage: "radial-gradient(#BFBFBF 1px, transparent 0)",
            backgroundSize: "30px 30px",
          }}
        >
          {/* <Stack position="sticky" overflow="auto" pr={8} bg="gray.50"> */}
          <Box>
            {currentBranch && (
              <Stack p={2} borderBottomWidth="thin" bg="white">
                <Button
                  variant="tertiary"
                  alignSelf="center"
                  leftIcon={<img src={backIcon} />}
                  onClick={() => {
                    setCurrentPosition("");
                    setCurrentBranch(null);
                  }}
                >
                  Back to main content
                </Button>
              </Stack>
            )}
          </Box>

          <Stack w="full">
            {currentBranch && (
              <Stack p={4}>
                <ChatBubble sender="bot" shape="bubble" borderTopWidth="thin">
                  <Text fontSize="lg">{currentBranch?.body ?? ""}</Text>
                </ChatBubble>
                <Tabs
                  defaultIndex={
                    currentBranch &&
                    currentBranch.branches.indexOf(currentSelectedBranch)
                  }
                  colorScheme="pink"
                  variant="solid-rounded"
                  onChange={(index) => {
                    if (currentBranch) {
                      const selectedBranch = currentBranch.branches[index];
                      setCurrentPosition(
                        updateBranchInPath(currentPosition, selectedBranch)
                      );
                      setCurrentSelectedBranch(selectedBranch);
                    }
                  }}
                >
                  <Stack w="full">
                    <TabList alignSelf="center">
                      {currentBranch &&
                        currentBranch.branches.map((branch, idx) => (
                          <Tab
                            bg="white"
                            mx={4}
                            borderWidth="thin"
                            borderRadius="md"
                          >
                            {branch}
                          </Tab>
                        ))}
                    </TabList>
                  </Stack>

                  <TabPanels>
                    {currentBranch &&
                      currentBranch.branches.map((_) => (
                        <TabPanel key={_}></TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              </Stack>
            )}
          </Stack>
          {renderIntents()}
        </Stack>

        <Box>
          {toolbarAction !== "" && (
            <Box
              pos="fixed"
              left={0}
              right={0}
              top={0}
              bottom={0}
              bg="blackAlpha.600"
              backdropFilter="auto"
              backdropBlur="6px"
              onClick={() => closeToolbar()}
            />
          )}
        </Box>

        {/* Bottom Bar */}
        <Appbar
          py={0}
          placement="bottom"
          variant="sticky"
          background="rgba(250,251,252,.8)"
          backdropFilter="blur(24px)"
          showHeader={false}
          borderTopWidth="thin"
        >
          {/* Conversation Components */}
          <Toolbar
            toolbarActionRef={toolbarActionRef}
            size={toolbarAction === "media" ? "sm" : "lg"}
          >
            <Stack height="full" direction="row">
              {toolbarActions.map((actionItem) => {
                const disabled = _handleDisableToolbarActions(actionItem.key);
                return (
                  <ToolbarComponent
                    title={actionItem.title}
                    isActive={toolbarAction === actionItem.key}
                    toolbarRef={toolbarActionRef}
                    disabled={disabled}
                    onClick={() =>
                      handleToolbarChange(actionItem.key, disabled)
                    }
                    icon={actionItem.icon}
                  >
                    <Stack
                      hidden={toolbarAction !== actionItem.key}
                      direction="column"
                      spacing={0}
                    >
                      <Stack direction="row-reverse">
                        <IconButton
                          variant="ghost"
                          icon={<CloseIcon />}
                          aria-label={""}
                          onClick={closeToolbar}
                        />
                      </Stack>
                      <SelectableImage
                        buttonProps={{ hidden: true }}
                        hidden={toolbarAction !== "media"}
                        src={media.url}
                        type={media.type}
                        borderRadius="2xl"
                        overflow="hidden"
                        onMediaUpload={async (mediaType, mediaBlob) => {
                          setLoading(true);
                          let response = await uploadFileMutation
                            .mutateAsync(mediaBlob)
                            .catch((err) => {
                              console.log(err);
                              message({
                                title: "failed to upload media",
                                status: "error",
                              });
                              return setLoading(false);
                            });

                          if (response)
                            setMedia({
                              type: mediaType,
                              ...response,
                            });

                          setLoading(false);
                        }}
                        ratio={1125 / 600}
                      />
                      <Stack>
                        <Stack pt={2} direction="row" align="start" w="full">
                          <AvatarTile
                            showTooltip={false}
                            name="magic ai"
                            showText={false}
                          />
                          <TextField
                            flex={1}
                            value={intentBody}
                            placeholder={actionItem.placeholder}
                            onChange={(event) =>
                              setIntentBody(event.currentTarget.value)
                            }
                            isDisabled={loading}
                            pt={0}
                            bg="transparent !important"
                            border="none"
                            onKeyDown={(event) => _handleKeyDown(event.key)}
                            minH={12}
                            trailing={
                              <IconButton
                                hidden={["probe", "branch"].includes(
                                  toolbarAction
                                )}
                                variant="ghost"
                                size="sm"
                                isDisabled={
                                  toolbarAction === "media"
                                    ? media.url === defaultMedia.url &&
                                      intentBody.length === 0
                                    : intentBody.length === 0
                                }
                                icon={<SendIcon size={22} />}
                                aria-label="send icon"
                                isLoading={loading}
                                onClick={() => addIntent()}
                              />
                            }
                          />
                          {intentBody.includes("@") &&
                            !intentBody.includes("@ ") && (
                              <SelectAttributes
                                className={`h-[30vh] z-10 absolute rounded-md p-2 bg-white shadow-lg w-72 overflow-y-scroll ${
                                  toolbarAction === "branch"
                                    ? "bottom-64"
                                    : "bottom-24"
                                }`}
                                flowId={flowId}
                                query={intentBody.slice(
                                  intentBody.indexOf("@") + 1
                                )}
                                onAttributeSelect={(attribute, isExternal) =>
                                  setIntentBody(
                                    (intentBody) =>
                                      intentBody.substring(
                                        0,
                                        intentBody.indexOf("@")
                                      ) +
                                      `{${
                                        isExternal
                                          ? `attributes.${attribute}`
                                          : attribute
                                      }}`
                                  )
                                }
                                title="Choose attribute"
                              />
                            )}
                        </Stack>
                        {toolbarAction === "delay" && (
                          <RadioGroup
                            onChange={(value) => setTimeUnit(value)}
                            value={timeUnit}
                          >
                            <Stack direction="row">
                              <Radio value="mins">Minutes</Radio>
                              <Radio value="seconds">Seconds</Radio>
                              <Radio value="hrs">Hours</Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                        <Text borderTopWidth="thin" pt={1} fontSize="xs">
                          <Kbd>@</Kbd> for attributes
                        </Text>
                      </Stack>
                      <Stack
                        pb={2}
                        hidden={toolbarAction !== "collect"}
                        direction="row"
                      >
                        {suggestedDatapoints.map((datapoint) => (
                          <Button
                            variant="secondary"
                            borderRadius="full"
                            onClick={() => setIntentBody(datapoint)}
                          >
                            {datapoint}
                          </Button>
                        ))}
                      </Stack>
                      <Stack
                        hidden={toolbarAction !== "branch"}
                        spacing={4}
                        direction="row"
                        py={4}
                      >
                        <AddChoices
                          onChange={(choices) => setBranches(choices)}
                        />
                      </Stack>
                      <Stack
                        align="center"
                        justify="space-between"
                        direction="row-reverse"
                        py={2}
                        hidden={!["probe", "branch"].includes(toolbarAction)}
                      >
                        <IconButton
                          variant="ghost"
                          size="sm"
                          disabled={
                            toolbarAction === "branch"
                              ? intentBody.length === 0 || branches.length === 0
                              : intentBody.length === 0
                          }
                          icon={<SendIcon size={22} />}
                          aria-label="send icon"
                          isLoading={loading}
                          onClick={() => addIntent()}
                        />
                        <Stack
                          hidden={toolbarAction !== "probe"}
                          direction="row"
                        >
                          {suggestedFollowups.map((followup) => (
                            <Button
                              variant="secondary"
                              borderRadius="full"
                              onClick={() => setIntentBody(followup)}
                            >
                              {followup}
                            </Button>
                          ))}
                        </Stack>
                      </Stack>
                    </Stack>
                  </ToolbarComponent>
                );
              })}
            </Stack>
          </Toolbar>
        </Appbar>
      </SimpleGrid>
      <Drawer size="xs" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Publish</DrawerHeader>
          <DrawerBody bg="gray.50" py={8} as={Stack} spacing={8}>
            <Stack>
              <Text>Name</Text>
              <Input value={microFlowName} />
            </Stack>
            <Stack>
              <Text>When this conversation ends</Text>
              <RadioGroup
                onChange={(value) =>
                  setContinueFlow(value === "true" ? true : false)
                }
                value={continueFlow ? "true" : "false"}
              >
                <Stack direction="column">
                  <Radio value="true">Return to main conversation</Radio>
                  <Radio value="false">End conversation</Radio>
                </Stack>
              </RadioGroup>
            </Stack>
            <Button onClick={() => saveMicroFlow(true)}>Continue</Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};
