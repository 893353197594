import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  VStack,
  HStack,
  useToast,
  InputGroup,
  Grid,
  GridItem,
  Checkbox,
  InputLeftElement,
  Tooltip,
  Heading,
} from "@chakra-ui/react";
import { BotRecords, ExportStep, getExportValues } from "../../api/go_live"; // Adjust the import paths as needed
import { SearchIcon } from "../../constants/icons";

interface DashboardConfig {
  botName: string;
  botType: string;
  company: string;
  role: string;
  info: string;
  isVideoInterviewEnabled: boolean;
  isAssesshubTestEnabled: boolean;
  exportFields: ExportStep[];
  summaryFields: ExportStep[];
}

interface SetDashboardDataFormProps {
  record: BotRecords;
  dashboardConfig: DashboardConfig;
  setDashboardConfig: React.Dispatch<React.SetStateAction<DashboardConfig>>;
}

const SetDashboardData: React.FC<SetDashboardDataFormProps> = ({ record, dashboardConfig, setDashboardConfig }) => {
  const toast = useToast();
  const [isEditingSummary, setIsEditingSummary] = useState(true);
  const [isEditingExport, setIsEditingExport] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSameAsSummary, setIsSameAsSummary] = useState(false);

  const filterValidSteps = (steps: ExportStep[]): ExportStep[] => {
    // Filter out expression steps and http steps with no response values
    return steps.filter((step) => {
      if (step.intent_type === "expression") {
        return false;
      }
      if (step.intent_type === "http") {
        return step.response_values && Object.keys(step.response_values).length > 0;
      }
      return true;
    });
  };

  const processHttpResponseValues = (steps: ExportStep[]): ExportStep[] => {
    const processedSteps: ExportStep[] = [];

    steps.forEach((step) => {
      if (step.intent_type === "http" && step.response_values) {
        // Only create the derived fields, skip the original HTTP field
        Object.entries(step.response_values).forEach(([key, value]) => {
          processedSteps.push({
            step_name: `${step.step_name}_${key}`,
            answer_field: value,
            intent_type: "field",
            label: key,
            isSelected: true,
            response_values: {},
          });
        });
      } else if (step.intent_type !== "expression") {
        // Add non-HTTP fields as they are
        processedSteps.push(step);
      }
    });

    return processedSteps;
  };

  const fetchExportValues = async (section: "export" | "summary") => {
    try {
      const flowId = record.data["Flow Id"];
      const response = await getExportValues(flowId);

      // Filter and process the steps
      const filteredSteps = filterValidSteps(response.steps);
      const processedSteps = processHttpResponseValues(filteredSteps);
      const updatedFields = processedSteps.map((step) => ({
        ...step,
        isSelected: true,
        label: step.label || step.answer_field,
      }));

      if (section === "export") {
        setDashboardConfig((prev) => ({ ...prev, exportFields: updatedFields }));
      } else {
        setDashboardConfig((prev) => ({ ...prev, summaryFields: updatedFields }));
      }

      toast({
        title: "Export values fetched",
        description: `Successfully retrieved ${response.steps.length} steps.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error fetching export values:", error);
      toast({
        title: "Error",
        description: "Failed to fetch export values. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const updateResponseValue = (fieldIndex: number, key: string, value: string, section: "export" | "summary") => {
    setDashboardConfig((prev) => {
      const updatedFields = [...prev[`${section}Fields`]];
      const updatedField = { ...updatedFields[fieldIndex] };
      updatedField.response_values = { ...updatedField.response_values, [key]: value };
      updatedFields[fieldIndex] = updatedField;
      return { ...prev, [`${section}Fields`]: updatedFields };
    });
  };

  const updateField = (index: number, updatedField: ExportStep, section: "export" | "summary") => {
    setDashboardConfig((prev) => {
      const updatedFields = [...prev[`${section}Fields`]];
      updatedFields[index] = updatedField;
      return { ...prev, [`${section}Fields`]: updatedFields };
    });
  };

  const handleSameAsSummaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsSameAsSummary(isChecked);

    if (isChecked) {
      setDashboardConfig((prev) => ({
        ...prev,
        exportFields: [...prev.summaryFields], // Create a copy for independent editing
      }));
    } else {
      setDashboardConfig((prev) => ({
        ...prev,
        exportFields: [], // Reset or maintain as per your logic
      }));
    }
  };

  const renderFieldCard = (field: ExportStep, index: number, section: "export" | "summary") => (
    <Card key={index} mb={4}>
      <CardBody>
        <VStack align="stretch" spacing={3}>
          <Flex justify="space-between" align="center">
            <Tooltip label="Select this field for inclusion">
              <Switch isChecked={field.isSelected} onChange={(e) => updateField(index, { ...field, isSelected: e.target.checked }, section)} />
            </Tooltip>
            <Text fontWeight="bold">{field.step_name}</Text>
          </Flex>
          <FormControl>
            <FormLabel>Answer Field</FormLabel>
            <Input value={field.answer_field} isReadOnly placeholder="Answer Field" />
          </FormControl>
          <FormControl>
            <FormLabel>Intent Type</FormLabel>
            <Input value={field.intent_type} isReadOnly placeholder="Intent Type" />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Label</FormLabel>
            <Input
              value={field.label || ""}
              onChange={(e) => updateField(index, { ...field, label: e.target.value }, section)}
              placeholder="Label (required)"
            />
          </FormControl>

          {field.intent_type === "http" && field.response_values && (
            <FormControl>
              <FormLabel>Response Values</FormLabel>
              {Object.entries(field.response_values).map(([key, value], idx) => (
                <Box key={idx} pl={4} mb={2}>
                  <Text fontWeight="bold">{key}:</Text>
                  <Input value={value} onChange={(e) => updateResponseValue(index, key, e.target.value, section)} placeholder={`Value for ${key}`} />
                </Box>
              ))}
            </FormControl>
          )}
        </VStack>
      </CardBody>
    </Card>
  );

  const filteredFields = (fields: ExportStep[]) => fields.filter((field) => field.step_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const renderFieldsSection = (
    title: string,
    fields: ExportStep[],
    section: "export" | "summary",
    isEditing: boolean,
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  ) => (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="xl" fontWeight="bold">
          {title}
        </Text>
        <HStack>
          <Button onClick={() => fetchExportValues(section)} colorScheme="blue" size="sm">
            Get {title}
          </Button>
          <Button onClick={() => setIsEditing(!isEditing)} colorScheme="teal" size="sm">
            {isEditing ? "View Mode" : "Edit Mode"}
          </Button>
        </HStack>
      </Flex>

      <HStack spacing={4} mb={2}>
        <Text width="40px" fontWeight="bold">
          Select
        </Text>
        <Text flex={1} fontWeight="bold">
          Step Name
        </Text>
        <Text flex={1} fontWeight="bold">
          Answer Field
        </Text>
        <Text flex={1} fontWeight="bold">
          Intent Type
        </Text>
        <Text flex={1} fontWeight="bold">
          Label
        </Text>
      </HStack>

      {fields.map((field, index) => (
        <HStack key={index} spacing={4} mb={2}>
          <Checkbox
            isChecked={field.isSelected}
            onChange={(e) => updateField(index, { ...field, isSelected: e.target.checked }, section)}
            width="40px"
          />
          <Input
            value={field.step_name}
            onChange={(e) => updateField(index, { ...field, step_name: e.target.value }, section)}
            isReadOnly={!isEditing || (section === "export" && dashboardConfig.exportFields === dashboardConfig.summaryFields)}
            placeholder="Step Name"
            flex={1}
          />
          <Input
            value={field.answer_field}
            onChange={(e) => updateField(index, { ...field, answer_field: e.target.value }, section)}
            isReadOnly={!isEditing || (section === "export" && dashboardConfig.exportFields === dashboardConfig.summaryFields)}
            placeholder="Answer Field"
            flex={1}
          />
          <Input
            value={field.intent_type}
            onChange={(e) => updateField(index, { ...field, intent_type: e.target.value }, section)}
            isReadOnly={!isEditing || (section === "export" && dashboardConfig.exportFields === dashboardConfig.summaryFields)}
            placeholder="Intent Type"
            flex={1}
          />
          <Input
            value={field.label || ""}
            onChange={(e) => updateField(index, { ...field, label: e.target.value }, section)}
            isReadOnly={!isEditing || (section === "export" && dashboardConfig.exportFields === dashboardConfig.summaryFields)}
            placeholder="Label"
            flex={1}
          />
        </HStack>
      ))}
    </Box>
  );

  return (
    <VStack align="stretch" spacing={6}>
      <Box>
        <VStack align="stretch" spacing={4}>
          <FormControl isRequired>
            <FormLabel>Bot Name</FormLabel>
            <Input value={dashboardConfig.botName} onChange={(e) => setDashboardConfig((prev) => ({ ...prev, botName: e.target.value }))} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Bot Type</FormLabel>
            <Input value={dashboardConfig.botType} onChange={(e) => setDashboardConfig((prev) => ({ ...prev, botType: e.target.value }))} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Company</FormLabel>
            <Input value={dashboardConfig.company} onChange={(e) => setDashboardConfig((prev) => ({ ...prev, company: e.target.value }))} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Role</FormLabel>
            <Input value={dashboardConfig.role} onChange={(e) => setDashboardConfig((prev) => ({ ...prev, role: e.target.value }))} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Info</FormLabel>
            <Input value={dashboardConfig.info} onChange={(e) => setDashboardConfig((prev) => ({ ...prev, info: e.target.value }))} />
          </FormControl>
          <HStack spacing={12} className="pt-4 justify-center" width="100%">
            <Box
              border={dashboardConfig.isAssesshubTestEnabled ? `2px solid #805AD5` : "none"}
              className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
              onClick={() => setDashboardConfig((prev) => ({ ...prev, isAssesshubTestEnabled: !prev.isAssesshubTestEnabled }))}
            >
              <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
                📝
              </Box>
              <Box className="text-left p-4 h-[45%] rounded-b-xl" background="white" color="black" border="1px" borderColor="gray.200">
                <Heading size="md">Assesshub Test</Heading>
                <Text className="text-slate-600">Enable Assesshub Test</Text>
              </Box>
            </Box>
            <Box
              border={dashboardConfig.isVideoInterviewEnabled ? `2px solid #805AD5` : "none"}
              className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
              onClick={() => setDashboardConfig((prev) => ({ ...prev, isVideoInterviewEnabled: !prev.isVideoInterviewEnabled }))}
            >
              <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
                👨‍💻
              </Box>
              <Box className="text-left p-4 h-[45%] rounded-b-xl" background="white" color="black" border="1px" borderColor="gray.200">
                <Heading size="md">Video Test</Heading>
                <Text className="text-slate-600">Enable Video Interview</Text>
              </Box>
            </Box>
          </HStack>
        </VStack>
      </Box>

      <Tabs isFitted variant="enclosed">
        <TabList mb="1em">
          <Tab>Summary Fields</Tab>
          <Tab>Export Fields</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <Flex justify="space-between">
                <Button onClick={() => fetchExportValues("summary")} colorScheme="blue">
                  Get Summary Fields
                </Button>
                <InputGroup maxW="300px">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input placeholder="Search fields" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </InputGroup>
              </Flex>
              {filteredFields(dashboardConfig.summaryFields).map((field, index) => renderFieldCard(field, index, "summary"))}
            </VStack>
          </TabPanel>
          <TabPanel>
            <VStack align="stretch" spacing={4}>
              <Flex justify="space-between">
                <Button onClick={() => fetchExportValues("export")} colorScheme="blue">
                  Get Export Fields
                </Button>
                <InputGroup maxW="300px">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input placeholder="Search fields" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </InputGroup>
              </Flex>
              <HStack>
                <Switch isChecked={isSameAsSummary} onChange={handleSameAsSummaryChange} />
                <Text>Same as Summary</Text>
              </HStack>
              {filteredFields(dashboardConfig.exportFields).map((field, index) => renderFieldCard(field, index, "export"))}
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};
export default SetDashboardData;
