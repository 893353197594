import React, { useState, useEffect } from "react";
import { useStoreState } from "../../hooks/store";

import { FaCalendarAlt } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { MdBookmarkAdded } from "react-icons/md";
import dayjs from 'dayjs';

import {
    Box,
    HStack,
    Heading,
    Text,
    Select,
    Divider,
    Stack,
    VStack,
    useToast,
    FormLabel,
    Input,
} from "@chakra-ui/react";
import config from "../../config";
import { FaBusinessTime } from "react-icons/fa6";

const ScheduleInterviews = () => {

    type BookedTimeSlots = Record<string, [string, string]>;    
    const flows = useStoreState((state) => state.flows);
    const [beginingTime, setBeginingTime] = useState('');
    const [endingTime, setendingTime] = useState('');
    const [interviewDuration, setInterviewDuration] = useState(60);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const currentMonth = dayjs();
    const [userdateforInterview, setUserDateForInterview] = useState('')
    const [timeSlots, setTimeSlots] = useState([])
    const [countSlots, setCountSlots] = useState(0)
    const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
    const [bookedTimeSlots, setBookedTimeSlots] = useState<BookedTimeSlots>({});
    const [dayDate, setDayDate] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const toast = useToast();
    const [selectedFlow, setSelectedFlow] = useState('');

    const user = JSON.parse(localStorage.getItem('user'));


    useEffect(() => {
        getOperationalHours();
        setDayDate(generateNextFiveDays());
        getInterviewerSchedule();
        setSelectedFlow(localStorage.getItem('selected_flow_id'));
    }, []);


    useEffect(
        () => {
            setSelectedTimeSlots({});
            generateTimeSlots();
        }, [beginingTime, endingTime, interviewDuration]
    )

    const getOperationalHours = async () => {

        try {
            const response = await fetch(`${config.url}/get_operational_hours/${user.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
            });


            if (!response.ok) {
                return;
            }

            const resp = await response.json();

            // console.log('Response ', resp);


            setBeginingTime(resp.startTime)
            // console.log('Begining Time -->',beginingTime);

            setendingTime(resp.endTime)
            // console.log('Ending Time -->',endingTime);

            setInterviewDuration(resp.duration)



        } catch (error) {

            console.error(error);


        }





    }

    const updateTimeSlots = (data) => {
        const updatedData = {};

        for (const [date, time] of Object.entries(data)) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const dateObj = new Date(date + 'T' + now.toTimeString().split(' ')[0] + '.000+0530');
            updatedData[dateObj.toString()] = time
        }

        return updatedData;
    }

    const getInterviewerSchedule = async () => {

        const requestData = {
            'userId' : user.id,     
            'botId' : selectedFlow       
        }

        try {   

            const response = await fetch(`${config.url}/get_interviewer_schedule/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body : JSON.stringify(requestData)
                 
            });


            if (!response.ok) {
                return;
            }

            const resp = await response.json();


            const data = updateTimeSlots(resp);
            setSelectedTimeSlots(data);

        } catch (error) {
            console.log(error);

        }

    }

    const generateNextFiveDays = () => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const result = [];
        const today = new Date();
        let dayCount = 0;


        while (result.length < 5) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate.setDate(today.getDate() + dayCount);
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                const dayName = daysOfWeek[dayOfWeek];
                result.push({
                    date: String(currentDate.getDate()).padStart(2, '0'), day: dayName,
                    utc: currentDate
                });
            }
            dayCount++;
        }

        // console.log('Result', result);




        return result;

    }

    const interviewerSchedule = async () => {

        const dateObject = new Date(userdateforInterview);
        const formattedDate = new Date(dateObject.getTime() - dateObject.getTimezoneOffset() * 60000).toISOString();


        if (!selectedFlow) {
            toast(
                {
                    "title": "Select Bot Flow",
                    "status": "error"
                }
            )
            return
        }


        const requestData = {
            botId: selectedFlow,
            userId: user.id,
            userName: user.username,
            interviewerDate: formattedDate,
            interviewerTimeSlots: selectedTimeSlots[userdateforInterview]
        }

        try {

            const response = await fetch(`${config.url}/interviewer_schedule/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData),
            });


            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }

            toast({
                title: "Changes saved",
                status: "success",
            });

        } catch (error) {
            toast(
                {
                    "title": "Something Wrong",
                    "status": "error"
                }
            )

        }

    }

    const handleNextPage = () => {

        // console.log('Next Page ? -->' , (currentPage + 1) * 10 < timeSlots.length);

        // console.log((currentPage + 1) * 10);


        if ((currentPage + 1) * 8 < timeSlots.length) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }

    const formatDateToString = (utcPlus: Date) => {

        if (!utcPlus) {
            return
        }

        const utcTimePlus5 = utcPlus.toISOString().replace('Z', '+05:30');
        const date = new Date(utcTimePlus5);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedHours = hours < 10 ? '0' + hours : hours;

        return `${formattedHours}:${formattedMinutes}`;

    }

    const handleDateTime = (selectedTime) => {
        if (!selectedTime) {
            return null
        }

        const currentDate = new Date();

        // Create a date object with the current date and the selected time
        const dateTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            selectedTime.split(':')[0],
            selectedTime.split(':')[1]
        );

        const utcPlus530 = new Date(dateTime.getTime() + (5.5 * 60 * 60 * 1000));

        return utcPlus530;
    }

    const handleBeginingTimeChange = (event) => {
        const utcplus = handleDateTime(event.target.value)
        if (utcplus) {
            const formateDate = formatDateToString(utcplus)
            setBeginingTime(formateDate)
        }

    }

    const handleEndingTimeChange = (event) => {

        const utcplus = handleDateTime(event.target.value)
        if (utcplus) {
            const formateDate = formatDateToString(utcplus)

            setendingTime(formateDate)
        }
    }

    const handleInteviewDuration = (event) => {
        setCurrentPage(0)
        setInterviewDuration(event.target.value);
    };

    const saveOperationalHours = async () => {

        const strdate = new Date(handleDateTime(beginingTime).toISOString().replace('Z', '+05:30'));
        const enddate = new Date(handleDateTime(endingTime).toISOString().replace('Z', '+05:30'));



        const requestData = {
            botId: selectedFlow,
            startTime: strdate,
            endTime: enddate,
            duration: interviewDuration,
        };

        try {
            const response = await fetch(`${config.url}/operational_hours/${user.id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }

            toast({
                title: "Changes saved",
                status: "success",
            });

        } catch (error) {
            toast({
                status: "error",
                title: "Something Wrong"
            })

        }

        // console.log('Response', response.json());


    }

    const generateTimeSlots = () => {
        const millisecondsInMinute = 60 * 1000;
        const durationInMilliseconds = interviewDuration * millisecondsInMinute;

        const startime = handleDateTime(beginingTime);
        const endtime = handleDateTime(endingTime);

        if (!startime || !endtime) {
            return;
        }

        const date1 = new Date(startime.toISOString().replace('Z', '+05:30'));
        const date2 = new Date(endtime.toISOString().replace('Z', '+05:30'));

        const slots = (date2.getTime() - date1.getTime()) / durationInMilliseconds;

        setCountSlots(slots);

        const timeSlotsArray = [];

        // Format hours and minutes to 24-hour format
        const formatTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0'); // Ensure two digits
            const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits
            return `${hours}:${minutes}`; // Return formatted time
        };

        for (let i = 0; i < slots; i++) {
            const date = new Date(date1);
            date.setTime(date1.getTime() + i * durationInMilliseconds); // Update time based on slot duration

            // Get formatted time for the current and next slot
            const currentTime = formatTime(date);

            const nextSlotTime = new Date(date);
            nextSlotTime.setTime(date.getTime() + durationInMilliseconds);

            const nextTime = formatTime(nextSlotTime);

            // Create the time range string
            const timeRange = `${currentTime} - ${nextTime}`;

            timeSlotsArray.push(timeRange);
        }

        setTimeSlots(timeSlotsArray);
    };

    const handleSelectChange = (event) => {
        const { value } = event.target;
        setSelectedFlow(value);
        localStorage.setItem("selected_flow_id", value)
        getInterviewerSchedule()
    };



    return (

        <Stack className="flex px-2 h-full overflow-hidden ">

            <HStack justify="space-between">
                <Box py={2}>
                    <Heading py={1} size="md">
                        Schedule Interview
                    </Heading>
                    <Text>Seamlessly schedule interviews.</Text>
                </Box>


            </HStack>

            <Divider py={2} />

            <HStack spacing={8} w="full" alignItems="flex-start" h="full" py={6}>
                {/* Left Section: VStack with equal vertical space */}
                <VStack
                    className="flex w-2/5"
                    alignItems="flex-start"
                    h="full"

                >
                    <Box
                        w="full"
                        rounded="xl"
                        shadow="xl"
                        mb={8}
                    >

                        <HStack spacing={2} className="flex flex-col" p={4}>
                            <FaBusinessTime className="text-md text-[#5A639C]" />
                            <Text fontSize="md" fontWeight="bold">
                                Hours of Operation
                            </Text>
                        </HStack>

                        <Divider />

                        <HStack w="full" spacing={4} align="center" p={4}>
                            <VStack w="full" align="start">
                                <FormLabel fontSize="sm" color="#9aa1a9">
                                    Beginning Time
                                </FormLabel>
                                <Input
                                    type="time"
                                    value={beginingTime}
                                    focusBorderColor="blue.500"
                                    onChange={handleBeginingTimeChange}
                                />
                            </VStack>

                            <VStack w="full" align="start">
                                <FormLabel fontSize="sm" color="#9aa1a9">
                                    Ending Time
                                </FormLabel>
                                <Input
                                    type="time"
                                    value={endingTime}
                                    focusBorderColor="blue.500"
                                    onChange={handleEndingTimeChange}
                                />
                            </VStack>
                        </HStack>

                        <HStack alignItems="center" w="full" p={4} spacing={4}>
                            <Box className="flex justify-end items-center p-4 text-[#758694]">
                                <MdTimer />
                                <Text className="mx-2" fontWeight="medium">
                                    Duration
                                </Text>
                                <select
                                    className="border rounded-md p-2 hover:cursor-pointer outline-none"
                                    value={interviewDuration}
                                    onChange={handleInteviewDuration}
                                >
                                    <option value="30">30 Min</option>
                                    <option value="60">60 Min</option>
                                </select>
                            </Box>

                            <Box
                                p={2}
                                className="flex items-center hover:cursor-pointer justify-center hover:shadow-md rounded-xl w-1/5 space-x-2 border"
                                onClick={saveOperationalHours}  // Move onClick here
                            >
                                <MdBookmarkAdded className="text-[#7D8ABC]" />
                                <Text fontSize="md" fontWeight="medium">
                                    Save
                                </Text>
                            </Box>

                        </HStack>

                    </Box>

                    <Box w="full" p={6} rounded="xl" shadow="xl">
                        <VStack spacing={6} alignItems="start" w="full">
                            {/* First Section */}
                            <HStack spacing={4} alignItems="center" w="full">
                                <Text className="text-md font-semibold text-gray-600" whiteSpace="nowrap">
                                    List of Position's
                                </Text>
                                <Box w="80">
                                    <Select placeholder="Select Position" w="full">
                                        {Object.keys(flows).length > 0 ? (
                                            Object.entries(flows).map(([key, flow]) => (
                                                <option key={key} value={key}>
                                                    {key}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No flows available</option>
                                        )}
                                    </Select>
                                </Box>
                            </HStack>

                            {/* Second Section */}
                            <HStack spacing={4} alignItems="center" w="full">
                                <Text className="text-md font-semibold text-gray-600" whiteSpace="nowrap">
                                    List of Bot Flow's
                                </Text>
                                <Box w="80">
                                    <Select
                                        placeholder="Select Bot Flow"
                                        w="full"
                                        onChange={handleSelectChange}
                                        value={selectedFlow} // Set the value to selectedFlow
                                    >
                                        {Object.keys(flows).length > 0 ? (
                                            Object.entries(flows).map(([key, flow]) => (
                                                <option key={key} > 
                                                    {key}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No flows available</option>
                                        )}
                                    </Select>
                                </Box>
                            </HStack>
                        </VStack>
                    </Box>

                </VStack>

                <VStack
                    spacing={2}
                    className="rounded-xl w-full max-w-xl p-4 max-h-full"
                    shadow="xl"
                >

                    {/* Heading and description */}
                    <Box className="w-full">
                        <Heading py={1} size="sm">
                            Schedule an Interview to Assess and Discuss Relevant Experience
                        </Heading>
                        <Text className="text-sm mt-4 font-medium text-[#758694]">
                            A focused conversation to evaluate qualifications, discuss relevant experiences, and understand the candidate's fit for the role.
                        </Text>
                    </Box>

                    <Box>
                        <Divider />
                    </Box>

                    {/* Selected date section */}
                    <HStack alignItems="end" w="full" p={4} justifyContent="end">
                        <Box className="flex flex-row border rounded-xl shadow-md p-4 items-center space-x-4">
                            <FaCalendarAlt className="text-[#5A639C]" />
                            <Text fontSize="md" fontWeight="medium">
                                {selectedDate.format('MMMM YYYY')}
                            </Text>
                        </Box>
                    </HStack>

                    {/* Date selection */}
                    <HStack alignItems="center" w="full" p={4} spacing={4} className="flex justify-between">
                        {dayDate.map((item, index) => (
                            <Box
                                key={index}
                                onClick={() => {
                                    if (userdateforInterview === item.utc) {
                                        setUserDateForInterview(null);
                                    } else {
                                        setUserDateForInterview(item.utc); // Select the new date
                                    }
                                }}
                                className={`shadow-md rounded-xl hover:cursor-pointer border w-24 p-2 
                    transition duration-300 ease-in-out flex items-center justify-center
                    ${item.utc === userdateforInterview
                                        ? 'border-[#AD49E1] shadow-[0px_0px_12px_4px_rgba(173,73,225,0.6)]'
                                        : 'hover:shadow-glow border-gray-300'}`}
                            >
                                <Box className="flex flex-col items-center justify-center space-y-2">
                                    <Text fontSize="md" fontWeight="medium" color="#AD49E1">
                                        {item.day}
                                    </Text>
                                    <Text fontSize="2xl" fontWeight="bold" color="#AD49E1">
                                        {item.date}
                                    </Text>
                                    <Box className="flex flex-row items-center justify-center space-x-1">
                                        <FaCircle size={16} color="#73EC8B" />
                                        <Text fontSize="sm" fontWeight="medium" color="#A594F9">
                                            {countSlots}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </HStack>

                    {/* Time slots section */}
                    {userdateforInterview && (
                        <HStack w="full" spacing={4} ml={6} className="flex flex-wrap justify-start">
                            {timeSlots
                                .slice(currentPage * 8, (currentPage + 1) * 8)
                                .map((timeSlot, index) => {
                                    // Check if the current time slot is booked for the selected date
                                    const isBooked = Object.values(bookedTimeSlots).some(([bookedDate, bookedSlot]) => {
                                        const dateObj = new Date(bookedDate + 'T00:00:00'); // Format the booked date
                                        const providedDateObj = new Date(userdateforInterview); // Date for comparison
                                        return dateObj.getTime() === providedDateObj.getTime() && bookedSlot === timeSlot;
                                    });

                                    // Check if the current time slot is selected
                                    const isSelected = selectedTimeSlots[userdateforInterview]?.includes(timeSlot);

                                    return (
                                        <Box
                                            key={index}
                                            onClick={() => {
                                                if (!isBooked) {
                                                    setSelectedTimeSlots(prev => ({
                                                        ...prev,
                                                        [userdateforInterview]: isSelected
                                                            ? prev[userdateforInterview]?.filter(slot => slot !== timeSlot) // Remove from selection
                                                            : [...(prev[userdateforInterview] || []), timeSlot] // Add to selection
                                                    }));
                                                }
                                            }}
                                            className={`flex items-center justify-center hover:cursor-pointer rounded-xl border-2 w-1/5  // Ensures exactly 4 slots per row
                                            ${isBooked ? "bg-gray-300 cursor-not-allowed" :
                                                    isSelected ? "bg-[#86D293]" :
                                                        "border-[#bea8f9] shadow-sm hover:shadow-md"}
                                        `}
                                        >
                                            <Text
                                                fontWeight="bold"
                                                fontSize="xs" // Maintain small size for text
                                                color={isBooked ? "#A0AEC0" : isSelected ? "white" : "#AD49E1"}
                                                className="whitespace-nowrap text-center overflow-hidden p-2" // Add padding inside text
                                            >
                                                {timeSlot}
                                            </Text>
                                        </Box>
                                    );
                                })}
                        </HStack>



                    )}

                    {/* Save and pagination section */}
                    <HStack alignItems="center" w="full" p={4} spacing={4} className="flex justify-between">
                        <Box p={2} className="flex items-center hover:cursor-pointer justify-center hover:shadow-md rounded-xl w-1/5 space-x-2 border" onClick={() => interviewerSchedule()}>
                            <MdBookmarkAdded className="text-[#7D8ABC]" />
                            <Text fontSize="md" fontWeight="medium">
                                Save
                            </Text>
                        </Box>

                        {userdateforInterview && (
                            <Box className="flex flex-row text-[#5A639C]">
                                <Box
                                    p={2}
                                    onClick={handlePreviousPage}
                                    className={`hover:cursor-pointer border hover:shadow-md rounded-md mx-2 
                            ${currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""}`}
                                >
                                    <IoIosArrowBack />
                                </Box>

                                <Text fontWeight="bold">
                                    Page {currentPage + 1} of {Math.ceil(timeSlots.length / 8)} {/* Adjust pagination count */}
                                </Text>

                                <Box
                                    p={2}
                                    onClick={handleNextPage}
                                    className={`hover:cursor-pointer border hover:shadow-md rounded-md mx-2 
                                ${(currentPage + 1) * 8 >= timeSlots.length ? "opacity-50 cursor-not-allowed" : ""}`}
                                >
                                    <IoIosArrowForward />
                                </Box>

                            </Box>
                        )}

                    </HStack>

                </VStack>


            </HStack>

        </Stack>

    )
}

export default ScheduleInterviews