import axios from "axios";
import config from "../../config";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";

interface MessageFeedbackProps {
  flowId: string;

  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useMessageFeedback = ({
  flowId,
  refetchKey,
}: MessageFeedbackProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const {
    user: { email: userMail },
    getAuthToken,
  } = useAuthToken();

  return useMutation({
    mutationKey: ["message", "feedback", flowId],
    mutationFn: async ({
      message,
      feedback,
    }: {
      message: MessageInDb;
      feedback: "vote_up" | "vote_down";
    }) => {
      const token = localStorage.getItem('token')

      const response = await axios.post<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/${message.message_id}/feedback`,
        {
          feedback,
          message: message.message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "user-id": userMail,
          },
          withCredentials: true,
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({ title: "Thanks for feedback", status: "success" });
      }

      return response.data;
    },

    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to send feedback",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
