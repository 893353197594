import React, { useState, useEffect } from "react";
import { VStack, Text, HStack, Tag, useToast } from "@chakra-ui/react";

import { CronEvent, getCronEvents, deleteCronEvent } from "../../../api/cron";
import { Button } from "../../../components/Button";
import { RepeatTiming } from "../../../api/invite";
import { WEEKDAYS } from "../../../constants";
import { DeleteIcon } from "../../../constants/icons";
import { useAuthToken } from "../../../hooks/useAuthToken";

type Props = {
  flowId: string;
};

export const CronEventsList = ({ flowId }: Props) => {
  const message = useToast();
  const { getAuthToken } = useAuthToken();
  const [cronEvents, setCronEvents] = useState<CronEvent[]>([]);

  const renderTiming = (timing: RepeatTiming) => {
    /**
     * Convert RepeatTiming object to human readable format
     * The RepatTiming object is defined in src/api/invite.tsx
     * The object has the following structure:
     * years	      ∞	            One or more years in YYYY format.
     * months	    1 - 12	      One or more months, where January is 1 and December is 12.
     * days	      1 - 31	      One or more month days, from 1 to 31.
     * weekdays	  0 - 6	        One or more weekdays, where Sunday is 0, and Saturday is 6
     * hours	      0 - 23	      One or more hours in 24-hour time, from 0 to 23.
     * minutes	    0 - 59	      One or more minutes, from 0 to 59.
     *
     * The function should return a string in the following format:
     * "Every 1st of the month at 12:00"
     */

    let timingString = `Runs from ${timing.days}-${timing.months}-${timing.years} `;
    if (timing.weekdays) {
      timingString += `on days - ${timing.weekdays.map((weekday) =>
        WEEKDAYS[weekday].slice(0, 3)
      )} `;
    }
    if (timing.hours) {
      timingString += `at ${timing.hours}:`;
    }
    if (timing.minutes) {
      timingString += `${timing.minutes}(IST)`;
    }

    return timingString;
  };

  const removeCronEvent = async (cronId: string) => {
    const token = localStorage.getItem('token')
    const response = await deleteCronEvent({ cronId, token });
    if (response.status === "success")
      message({
        status: "success",
        description: "removed automation successfully",
      });
  };

  useEffect(() => {
    const fetchCronEvents = async () => {
      const token = localStorage.getItem('token')
      const events = await getCronEvents({ flowId, token });
      if (events) setCronEvents(events);
    };

    fetchCronEvents();
  }, []);

  return (
    <VStack w="full">
      {cronEvents.map((cronEvent) => (
        <VStack
          bg="white"
          boxShadow="sm"
          borderWidth="thin"
          borderRadius="md"
          spacing={4}
          p={4}
          transition="box-shadow 100ms ease-out 0s"
          w="100%"
          align="start"
          key={cronEvent._id}
        >
          <HStack w="full" justify="space-between">
            <Text fontSize="md" fontWeight="medium">
              {cronEvent.name}
            </Text>
            <Button
              leftIcon={<DeleteIcon />}
              askConfirmation
              confirmText="Are you sure you want to delete this?"
              aria-label={"remove"}
              size="xs"
              variant="secondary"
              onClick={() => removeCronEvent(cronEvent._id)}
              confirmAction="delete"
              confirmButton="Delete"
            >
              Delete
            </Button>
          </HStack>
          <HStack justify="space-between">
            <Tag>{cronEvent.status}</Tag>
            <Text>{renderTiming(cronEvent.timing)}</Text>
          </HStack>
        </VStack>
      ))}
    </VStack>
  );
};
