import React, { forwardRef, useState } from "react";

import {
  BoxProps,
  Button,
  Stack,
  HStack,
  Text,
  Icon,
  Tooltip,
} from "@chakra-ui/react";

import {
  MdDone,
  MdDoneAll,
  MdError,
  MdOutlineWatchLater,
} from "react-icons/md";

import { parseDate } from "../InfiniteTable";
import { getWhatsappFailedMessage } from "../../utils";
import {
  ImageChatMessage,
  DocumentChatMessage,
  AudioChatMessage,
  VideoChatMessage,
  WhatsappMessageText,
} from "../ChatMessage";
import { TemplateChatMessage } from "../ChatMessage/TemplateChatMessage";

interface BotChatMessageProps extends BoxProps {
  message: BotMessageInDB;

  inverse?: boolean;

  showStatus?: boolean;

}

const BotChatMessage = forwardRef<HTMLDivElement, BotChatMessageProps>(
  (
    {
      message: {
        flow_id,
        source,
        message_id,
        message,
        status,
        timestamp,
        sender,
        data,
      },
      inverse = false,
      showStatus = true,
      ...props
    },
    ref
  ) => {
    const [buttons, setButtons] = useState<{
      status: "loading" | "found";
      buttons: string[];
    }>({
      status: "loading",
      buttons: [],
    });


    const getSender = () => {
      return data?.["manual_message_data"]?.["sender"] ?? sender;
    };

    return (
      <Stack
        ref={ref}
        spacing={1}
        alignSelf={inverse ? "flex-end" : "flex-start"}
        {...props}
      >
        <Stack
          px={3}
          py={2}
          borderRadius="md"
          boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.12);"
          bg={inverse ? "purple.50" : "white"}
        >
          {getSender() !== "bot" && (
            <Text
              alignSelf="start"
              fontSize="sm"
              fontWeight={500}
              color="pink.400"
            >
              {getSender()}
            </Text>
          )}
          {message.type === "text" && (
            <WhatsappMessageText text={message.message} />
          )}
          {message.type === "template" &&
            (message.template_type === "gupshup" || message.template_type === "sinch" || message.template_type === "valueFirst" || message.template_type === "teams" ? (
              <WhatsappMessageText text={message.text} />
            ) : (
              <TemplateChatMessage
                flowId={flow_id}
                sourceId={source}
                templateId={message.template_id}
                params={message.params}
                onTemplateFetch={(template) => {
                  if (buttons.status === "found") return;
                  setButtons({
                    status: "found",
                    buttons: template.buttons ?? [],
                  });
                }}
              />
            ))}
          {(message.type === "button" || message.type === "list") && (
            <WhatsappMessageText text={message.body} />
          )}
          {message.type === "image" && (
            <ImageChatMessage
              message={{
                ...message,
                url: message.link,
                timestamp,
                name: "",
                message: "",
              }}
            />
          )}
          {message.type === "video" && (
            <VideoChatMessage
              message={{
                ...message,
                url: message.link,
                timestamp,
                name: "",
                message: "",
              }}
            />
          )}
          {message.type === "audio" && (
            <AudioChatMessage
              message={{
                ...message,
                url: message.link,
                timestamp,
                name: "",
                message: "",
              }}
            />
          )}
          {message.type === "document" && (
            <DocumentChatMessage
              message={{
                ...message,
                url: message.link,
                timestamp,
                name: "",
                message: "",
              }}
            />
          )}

          <HStack align="center" justify="space-between">
            <Tooltip label="Confidence score">
              <Text fontSize="xs" color="pink.600">
                {data?.["kb_confidence"] &&
                  parseFloat(data?.["kb_confidence"]).toFixed(2)}
              </Text>
            </Tooltip>
            <HStack align="center">
              <Text className="text-xs text-gray-500">
                {parseDate(timestamp, "ddd · dd/mm/yy · hh:MM tt")}
              </Text>
              {showStatus && status === "sending" && <Icon as={MdOutlineWatchLater} />}
              {showStatus && status === "failed" && (
                <Tooltip
                  label={`Meta error: ${getWhatsappFailedMessage(data)}`}
                >
                  <span>
                    <Icon as={MdError} color="red" />
                  </span>
                </Tooltip>
              )}
              {showStatus && status === "read" && <Icon as={MdDoneAll} color="blue" />}
              {showStatus && status === "sent" && <Icon as={MdDone} color="grey.500" />}
              {showStatus && status === "delivered" && (
                <Icon as={MdDoneAll} color="gray.500" />
              )}
            </HStack>
          </HStack>
        </Stack>
        {(message.type === "button" || message.type === "list") && (
          <Stack
            w="full"
            spacing={message.type === "button" ? 2 : 0}
            direction={
              message.type === "button"
                ? message.options.length < 3
                  ? "row"
                  : "column"
                : "column"
            }
          >
            {message.options.map((option, idx) =>
              typeof option === "string" ? (
                <Button size="sm" key={`${idx}_${message_id}_${message.type}`}>
                  {option}
                </Button>
              ) : (
                <Button size="sm" key={`${idx}_${message_id}_${message.type}`}>
                  {option.title}
                </Button>
              )
            )}
          </Stack>
        )}
        {message.type === "template" && (
          <Stack w="full" direction="row">
            {buttons.buttons.map((option, idx) => (
              <Button
                flex={1}
                size="sm"
                key={`${idx}_${message_id}_${message.type}`}
              >
                {option}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);

export default BotChatMessage;
