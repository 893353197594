interface DashboardConfig {
  // backend url
  url: string;
  // engine url
  engine: string;
  // domain url
  domain: string;
  //admin url 
  admin_url: string;

  admin_email: string;

  admin_password: string;

  chat_url : string;

  pass_key: string;
}

type DashboardEnvironment = "Staging" | "Local" | "Production";

const config: {
  Staging: DashboardConfig;
  Production: DashboardConfig;
  Local: DashboardConfig;
} = {
  Local: {
    url: "http://localhost:10000",
    engine: "http://localhost:8000",
    domain: "http://localhost:3000",
    admin_url: "https://admin-api.assesshub.com",
    admin_email: "padmin@demo.com",
    admin_password: "Admin@2024",
    chat_url : "",
    pass_key: "rN2cAPaw2m5XMVUrq9qToB4iyGY6ZA6/9/2c88fwsao="
  },
  Staging: {
    url: "https://botdashboard-api.myassesshub.com",
    engine: "https://botengine-api.myassesshub.com",
    domain: "https://botdashboard.myassesshub.com",
    admin_url: "https://admin-api.assesshub.com",
    admin_email: "padmin@demo.com",
    admin_password: "Admin@2024",
    chat_url : "https://chat.myassesshub.com/",
    pass_key: "te44ThbB0pwft99kyvPw//3G5yXSMEfy0nHAAvFLnqs="
  },
  Production: {
    url: "https://botdashboard-api.assesshub.com",
    engine: "https://botengine-api.assesshub.com",
    domain: "https://botdashboard.assesshub.com",
    admin_url: "https://admin-api.assesshub.com",
    admin_email: "padmin@demo.com",
    admin_password: "Admin@2024",
    chat_url : "https://chat.assesshub.com/",
    pass_key: "e3tol2Hsjxm6AwIsFUJnYJ9q7wBROcuESokLEJC46aI="
  },
};


function getEnvironment(): DashboardEnvironment | any {
  const hostname = window.location.hostname

  if (hostname.includes('.assesshub.com')) {
    return "Production"
  } else if (hostname.includes('.myassesshub.com')) {
    return "Staging"
  } else {
    return "Local"
  }
}


const environment: DashboardEnvironment = getEnvironment();

export default config[environment];
