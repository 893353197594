import {
  ButtonGroup,
  Select,
  Stack,
  IconButton,
  ButtonGroupProps,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DeleteIcon } from "../../constants/icons";
import { DatePicker } from "../Input";
import { TagGroup } from "../TagGroup";
import { FilterField, MiniFilterOperation } from "./models";

interface FilterConditionProps extends Omit<ButtonGroupProps, "onChange"> {
  filters?: FilterField[];
  condition: FilterField;
  setDefault?: boolean;
  onChange?: (
    filter: FilterField,
    operation: MiniFilterOperation,
    value: any
  ) => void;
  onRemove?: () => void;
}

const generateOperations = (condition: FilterField) => {
  if (condition.type === "date") {
    return ["is", "with in"];
  } else return ["is", "is not"];
};

export const FilterConditionComponent = ({
  condition,
  filters,
  onChange,
  onRemove,
  setDefault = true,
  ...props
}: FilterConditionProps) => {
  const [filterGroup, setFilterGroup] = useState<FilterField>(condition);
  const [operation, setOperation] = useState<MiniFilterOperation>("is");
  const [filterValue, setFilterValue] = useState<string[] | number[]>();

  useEffect(() => {
    if (setDefault)
      setFilterValue(
        filterGroup.options
          ? filterGroup.options.length !== 0
            ? [filterGroup.options[0]]
            : []
          : []
      );
  }, [filterGroup, condition]);

  useEffect(() => {
    onChange?.(filterGroup, operation, filterValue);
  }, [filterValue, operation]);

  return (
    <ButtonGroup
      size="sm"
      flexGrow={1}
      variant="outline"
      isAttached
      spacing={0}
      {...props}
    >
      {/* fields */}

      {filters ? (
        <Select
          w={56}
          borderRadius="unset"
          value={filterGroup.name}
          size="sm"
          onChange={(event) => {

            const selectedFilter = filters.filter(
              (filters) => filters.name === event.currentTarget.value
            );

            if (selectedFilter) {
              setFilterGroup(selectedFilter[0]);
            }
          }}
          _hover={{ bg: "hsla(216,59%,33%,0.05)" }}
        >
          {filters.map((filter) => (
            <option value={filter.name}>{filter.name}</option>
          ))}
        </Select>
      ) : (
        <Box minW={56} px={4} borderWidth="thin">
          {condition.name}
        </Box>
      )}

      {/* operation */}
      <Select
        borderRadius="unset"
        w={48}
        size="sm"
        _hover={{ bg: "hsla(216,59%,33%,0.05)" }}
        value={operation}
        onChange={(event) => {
          setOperation(event.currentTarget.value as MiniFilterOperation);
        }}
      >
        {generateOperations(filterGroup).map((filterOperation) => (
          <option value={filterOperation}>{filterOperation}</option>
        ))}
      </Select>

      {/* value */}

      {filterGroup.render_as === "datepicker" ? (
        <DatePicker
          borderRadius="unset"
          title="Pick date"
          date={{
            from: undefined,
            to: undefined,
          }}
          variant="tertiary"
          mode={operation === "is" ? "single" : "range"}
          onDateSelected={(range) => {
            let dateFilters = [range.from.getTime() / 1000];
            if (range.to) dateFilters.push(range.to.getTime() / 1000);
            setFilterValue(dateFilters);
          }}
          borderWidth="thin"
        />
      ) : (
        <Stack borderWidth="thin" px={2} justify="center">
          {filterGroup.render_as === "tags" ? (
            <TagGroup
              multiple
              placeholder="Select value"
              tags={filterValue}
              options={filterGroup.options}
              onChange={(selectedTags) =>
                typeof selectedTags === "string"
                  ? setFilterValue([selectedTags])
                  : setFilterValue(selectedTags)
              }
              allowNewTag={false}
            />
          ) : (
            <TagGroup
              multiple
              placeholder="Select Value"
              tags={filterValue}
              options={filterGroup.options}
              onChange={(selectedTags) =>
                typeof selectedTags === "string"
                  ? setFilterValue([selectedTags])
                  : setFilterValue(selectedTags)
              }
              allowNewTag={false}
            />
          )}
        </Stack>
      )}

      {/* remove */}
      <IconButton
        icon={<DeleteIcon />}
        aria-label={"remove"}
        variant="ghost"
        borderWidth="thin"
        borderRadius="unset"
        onClick={() => onRemove?.()}
      />
    </ButtonGroup>
  );
};
