import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import { PaginationConfig } from "../../models";
import { SupportInterviewer } from "../../models/support";
import config from "../../config";


export const useSupportInterviewerUser = (
    orgId: number,
    pagination?: PaginationConfig
  ) => {
    const fetchWithAuth = useFetchWithAuth();
  
    const fetchSupportInterviewerUsers = async () => {
      const res = await fetchWithAuth<SupportInterviewer[]>({
        url: `${config.url}/list_of_interviewer/${orgId}`,
        //   config: {
        //     params: {
        //       pagination: JSON.stringify(pagination),
        //     },
        //   },
      });
  
      return res;
    };
  
    return useQuery(["support", "interviewers", orgId], fetchSupportInterviewerUsers, {
      keepPreviousData: true,
      refetchInterval: 1000 * 30, // 30 seconds
    });
  };
  