import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export const useFeatureFlags = (flowId: string , orgId?:string ) => {
  const fetchWithAuth = useFetchWithAuth();

  const fetchFeatureFlags = async () => {
    // let orgId: string = orgId
    const userDetails = localStorage.getItem("recruiter");

    if (userDetails) {
      const user = JSON.parse(userDetails);
      if (user?.fields?.company) {
        orgId = user.fields.company;
      }
    }

    const res = await fetchWithAuth<{ flags: string[] }>({
      url: `${config.url}/v2/feature_flags/`,
      config: {
        params: {
          flow_id: flowId,
          org_id: orgId,
        },
      },
    });

    return res;
  };

  return useQuery(["flags", flowId], fetchFeatureFlags, {
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5, // 5 mins
  });
};
