import dateFormat from "dateformat";
import Papa from "papaparse";
import { OnboardingIcon, HiringIcon, SupportIcon } from "../constants/icons";
import { quessLabels, uploadKeys, arvindManagers } from "./constants";
import { projectAdjectives, projectNouns } from "../constants";
import * as XLSX from "xlsx";

export const addDate = (number) => {
  var d = new Date();
  d.setDate(d.getDate() + 5);
  return d;
};

export const getThisWeek = (date) => {
  var now = date ? new Date(date) : new Date();

  // set time to some convenient value
  now.setHours(0, 0, 0, 0);

  // Get the previous Monday
  var weekStart = new Date(now);
  weekStart.setDate(weekStart.getDate() - weekStart.getDay() + 1);

  // Get next Sunday
  var weekEnd = new Date(now);
  weekEnd.setDate(weekEnd.getDate() - weekEnd.getDay() + 7);


  return {
    start: weekStart,
    end: weekEnd,
  };
};

export const getThisMonth = (date) => {
  var now = date ? new Date(date) : new Date();
  var y = now.getFullYear(),
    m = now.getMonth();

  // Get the previous Monday
  var monthStart = new Date();
  monthStart.setFullYear(y, m, 1);

  var monthEnd = new Date();
  monthEnd.setFullYear(y, m + 1, 0);

  return {
    start: monthStart,
    end: monthEnd,
  };
};

export const hasCheckedItems = (checkedItems) => {
  let items = false;

  Object.keys(checkedItems).map((key) => (items = items | checkedItems[key]));

  return items;
};

export const exportCandidatesData = (
  rows = [],
  candidatesData = [],
  key = "id"
) => {
  let keys = rows.map((row) => row?.[key]);

  const exportData = candidatesData.filter(({ table = {} }) =>
    keys.includes(table?.[key])
  );

  return exportData;
};

export const getCSVHeaders = (rows = []) => {
  /**
   * @param {array} rows
   * @returns {array} headers
   * @description
   * This function will return the headers of the csv file
   * @example
   * getCSVHeaders([{ id: "1", name: "John Doe" }, { id: "2", city: "New York" }])
   * // returns ["id", "name", "city"]
   */
  let headers = [];
  rows.map((row) => {
    Object.keys(row).map((key) => {
      if (key !== "__parsed_extra") {
        if (!headers.includes(key)) headers.push(key);
      }
    });
  });
  return headers;
};

export const isUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const getUrlType = (data = "") => {
  let url = `${data}`;
  if (url.includes("linkedin")) return "social";
  else if (url.includes("mp4")) return "video";
  else return "embed";
};

export const formatDate = (string) => {
  let now = new Date(string);
  now.setHours(now.getHours() + 5);
  now.setMinutes(now.getMinutes() + 30);
  return dateFormat(now, "dd mmm, h:MM TT");
};

export const formatDashboardDate = (string = "") => {
  try {
    let now = "";
    if (!string || string === "") now = new Date();
    else now = new Date(string.replace(/-/g, "/"));
    now = now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    return dateFormat(now, "mmm dd");
  } catch (error) {
    let a = string.split(/[^0-9]/);
    let now = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    return dateFormat(now, "mmm dd");
  }
};

export const formatTimestampDate = (string) => {
  if (string === "") return string;

  let now = new Date(string);
  now = now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
  return dateFormat(now, "mmm dd, h:MM");
};

export const getEpoch = (string) => {
  if (string === "") return string;

  let now = new Date(string);
  return now.getTime() / 1000;
};

export const getUniqueCampaignName = () => {
  String.prototype.capitlizeFirst = capitlizeFirst;

  const getRandomArrayElement = (array = [""]) =>
    array[Math.floor(Math.random() * array.length)];
  let randomAdjective = getRandomArrayElement(projectAdjectives);
  randomAdjective = capitlizeFirst(randomAdjective);
  let randomNoun = getRandomArrayElement(projectNouns);
  randomNoun = capitlizeFirst(randomNoun);

  return `${formatDashboardDate()} ${randomAdjective} ${randomNoun}`;
};

export const isInRange = (date, dateRange) => {
  var from = dateRange.startDate;
  var to = dateRange.endDate;
  var check = new Date(date);

  return check > from && check < to;
};

const extractNames = (eachCandidate) => {
  let names = eachCandidate?.Name ?? "";
  return names.split(" ");
};

export const getRows = (candidates, enableKey = true) => {
  let rows = [];
  candidates.map((eachCandidate, idx) => {
    if (enableKey) {
      rows.push({
        key: idx,
        ...eachCandidate.table,
        val: extractNames(eachCandidate.table),
      });
    } else {
      rows.push({
        ...eachCandidate.table,
      });
    }
  });
  return rows;
};

export const quess_enabled_users = [
  "admin@quessco.com",
  "siddish@workhack.io",
  "amit@ah.com",
  "shanti@workhack.io",
  "akshat@edhack.io",
  "mohan@workhack.io",
];

export const campaign_status = {
  "Invite Success": "",
  Processing: "",
  Duplicate: "",
  "Invite Failed": "",
};

export function capitlizeFirst(str = "") {
  // checks for null, undefined and empty string
  if (!str) return;

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const arvind_user_classes = [
  "Assesshub",
  "Arvind HR Reviewers",
  "Leadership",
  "All Employees",
];

export const retail_user_classes = [
  "All Employees",
  "Assesshub",
  "off-roll",
  "On-roll",
];

export const arvind_campaign_types = [
  "Learning and Training",
  "Announcements",
  "Updates about Bot and Policies",
  "Information and Reminders",
];

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const getSupportTable = {
  index: "S. No",
  name: "Name",
  conversation_id: "Phone",
  query: "Query",
  timestamp: "Date",
  status: "Status",
};

export const support_status = ["Not answered", "Resolved"];

export const arvind_analytics = {
  "This Month": {
    "Total Unique Messages delivered": "24517",
    "% of users read the notification": "61%",
  },

  "This Week": {
    "Total Unique Messages delivered": "3476",
    "% of users read the notification": "54%",
  },
};

export const topQueries = [
  "Gratuity ",
  "Salary",
  "Leave Balance",
  "FBP ",
  "Insurance",
];

export const getCampaignTable = [
  "Name",
  "Phone Number",
  "Status",
  "E-mail",
  "Last Modified",
];

//   return table
// };

export const exportCandidatesCSV = (
  conversations,
  removeKeys = [],
  enableParsing = true
) => {
  let exportData = [];

  if (enableParsing) {
    conversations.map((eachCandidate) => {
      let table = eachCandidate.table;
      if (eachCandidate.export)
        exportData.push({ ...table, ...eachCandidate.export });
      else exportData.push(table);
    });
  }

  exportData = enableParsing ? exportData : conversations;

  if (removeKeys.length > 0) {
    exportData.map((eachCandidate) => {
      removeKeys.map((key) => {
        delete eachCandidate[key];
      });
    });
  }

  let columns = getCSVHeaders(exportData);

  const csv = Papa.unparse(exportData, { columns });
  const blob = new Blob([csv]);

  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob, { type: "text/csv" });
  a.download = "download.csv";

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getCampaignCount = (obj) => {
  let count = 0;
  Object.keys(obj)
    .map((category) => {
      Object.keys(obj[category])
        .map((key) => (count += parseInt(obj[category][key]?.count ?? 0)))
    });
  return count;
};

export const completionRateStatus = {
  CSB: ["Recommended", "Video Pending", "Rejected On Video Interview"],
  QUESS: ["Details Submitted", "Completed"],
};

export const fetchOnboardingData = async () => {
  const response = await fetch("/data/axa.csv");
  const reader = response.body.getReader();
  const result = await reader.read(); // raw array
  const decoder = new TextDecoder("utf-8");
  const csv = decoder.decode(result.value); // the csv text
  const results = Papa.parse(csv, { header: true }); // object with { data, errors, meta }
  const rows = results.data; // array of objects
  let data = [];
  rows.forEach((row) => {
    data.push({
      table: row,
      export: {},
      summary: {
        "Basic Information": {
          Gender: "Male",
          "Martial Status": "Married",
          Address: "Bihar",
          "Interview Experience": "It was nice",
          Skills: "Business Management",
        },
        Documents: {
          Photo:
            "https://cdn.discordapp.com/attachments/947812709926326292/966420950285418536/Passport_photo.jpg",
          "Offer Letter":
            "https://cdn.discordapp.com/attachments/947812709926326292/966420950486753300/offer-letter.png",
          "Pay Slip":
            "https://cdn.discordapp.com/attachments/947812709926326292/966420950717464586/Salary_Slip.png",
          "Appointment Letter":
            "https://cdn.discordapp.com/attachments/947812709926326292/966420950486753300/offer-letter.png",
          Pan: "No",
          Aadhar:
            "https://cdn.discordapp.com/attachments/947812709926326292/966420949824065577/Aadhar_Card.jpeg",
          "Aadhar Verified": "Yes",
        },
        PF: {
          PF: "MHBAN0000064",
        },
      },
    });
  });
  return data;
};

export const getTimestampData = (timestampData = {}) => {
  let data = {};

  for (const key in timestampData) {
    if (Object.hasOwnProperty.call(timestampData, key)) {
      const { summary, timestamp } = timestampData[key];
      const dataKey = formatDashboardDate(timestamp);
      if (data[dataKey]) {
        const prev = { ...data[dataKey] };
        Object.keys(summary).map((key) => {
          prev[key] = prev[key] + summary[key];
        });
        data[dataKey] = prev;
      } else {
        data[dataKey] = summary;
      }
    }
  }

  return data;
};

export const getCampaignAnalytics = (campaignData = []) => {
  let data = {};

  campaignData.map((campaign) => {
    const {
      _id: { type },
      Count: count,
    } = campaign;

    if (type) {
      data[type] = count;
    } else {
      data["General"] = count;
    }
  });

  return data;
};

export const getIndexedRows = (rows = []) => {
  let data = [];

  let count = rows.length;

  rows.map((row) => {
    data.push({
      index: count,
      ...row,
    });
    count -= 1;
  });

  return data;
};

export const getBotType = (name) => {
  switch (name) {
    case "recruiter":
      return "Hiring";
    case "onboarding":
      return "Onboarding";
    case "referrals":
      return "Referring";
    case "faq":
      return "Helpdesk";
    default:
      return `${name}`;
  }
};

export const getBotIcon = (type) => {
  switch (type) {
    case "Hiring":
      return <HiringIcon />;

    case "Helpdesk":
      return <SupportIcon />;
    default:
      return <OnboardingIcon />;
  }
};

export const getTagColor = (tag) => {
  let color = "#e9e9e9";

  switch (tag) {
    case "Not Started":
      color = "rgba(206,205,202,0.5)";
      break;
    case "Pending":
      color = "rgba(255, 240, 179, 1)";
      break;
    case "In Progress":
    case "High Engagement":
    case "KYC Complete":
      color = "rgba(245,93,0,0.2)";
      break;
    case "Details Submitted":
    case "Recommended":
    case "PIP Complete":
    case "sent":
    case "Test Pending":
    case "Diet Chart Sent":
      color = "rgba(103,36,222,0.2)";
      break;
    case "Assesshub Test Pending":
    case "EMP Complete":
    case "delivered":
    case "Order Created":
    case "Fitness Quiz Finished":
      color = "rgba(0,120,223,0.2)";
      break;
    case "Video Pending":
    case "Processing":
    case "Feedback Complete":
    case "Fitness Amateur":
      color = "rgba(233,168,0,0.2)";
      break;
    case "Completed":
    case "Test Passed":
    case "Invite Success":
    case "Resolved":
    case "read":
    case "Invited":
    case "Fitness Novice":
    case "Onboarding Complete":
    case "Selected":
      color = "rgba(0,135,107,0.2)";
      break;
    case "Rejected On Assesshub Test":
    case "Test Failed":
    case "Rejected":
      color = "rgba(221,0,129,0.2)";
      break;
    case "Induction Complete":
    case "Rejected On Video Interview":
      color = "rgba(255,0,26,0.2)";
      break;

    case "Dropped Out":
    case "Without Whatsapp":
    case "Not Eligible":
    case "Test Assignment Failed":
      color = "rgba(255,3,102, .5)";
      break;

    default:
      color = "#e9e9e9";
  }

  return color;
};

export const extractPhoneNumber = (row) => {
  let keys = Object.keys(row);

  // keys.map((key) => {
  //   if (new RegExp(keyVariants["phone"].join("|")).test(key)) {
  //     console.log(key);
  //     console.log(row[key]);
  //     return row[key];
  //   }
  // });

  return "";
};

export const excelExport = (
  fileName = "download.xlsx",
  sheets = [
    {
      sheetName: "",
      json: [],
    },
  ]
) => {
  const workbook = XLSX.utils.book_new();

  sheets.map((sheet, index) => {
    const sheetName = sheet.sheetName ?? `Sheet ${index + 1}`;
    const sheetData = sheet.json ?? [];
    var worksheet = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  });

  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const getFlowTags = (flow) => {
  if (flow === "ARVIND_CORP") return arvind_user_classes;
  else if (flow === "ARVIND_RETAIL") return retail_user_classes;
  else if (flow === "ARVIND_REC") return arvindManagers;
  else return quessLabels;
};

export const getCompletionRate = (analytics = {}, botId = "") => {
  if (Object.keys(analytics).length === 0) {
    return 0;
  }

  let count = 0;
  let completionCount = 0;

  Object.keys(analytics).forEach((key) => {
    if (!["Not Started", "No Whatsapp"].includes(key))
      completionCount += analytics[key];
    count += analytics[key];
  });

  completionCount = (completionCount / count) * 100;

  return parseInt(completionCount);
};

export const getCampaignProgress = (summary = {}) => {
  let count = getCampaignCount(summary);
  if (Object.keys(summary).length === 0) return 0;
  let completed =
    summary["Invite Success"] ??
    summary["Successful Invites"] ??
    summary["Successful Unique Invites"] ??
    summary["Invited"] ??
    0;
  return parseInt((completed / count) * 100);
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}