import React, { useState } from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  useToast,
  Image,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Container,
  SimpleGrid,
  HStack,
  FormErrorMessage
} from '@chakra-ui/react';

import config from "../../config";
import { saveAs } from 'file-saver';

export const QRCode = () => {

  const user = JSON.parse(localStorage.getItem('user'));

  const [formData, setFormData] = useState({
    recruiterName: '',
    recruiterCode: '',
    partnerBranchCode: '',
    partnerLocation: '',
    recruiterDesignation: '',
    organizationName : user.company
  });

  console.log('FormData -->',user.company);
  



  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [inputerror , setError] = useState(false)



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleGenerateQR = async () => {
    
    if (!formData.recruiterName && !formData.recruiterCode) {
      toast({
        title: 'Recruiter Name or Recruiter Code is required',
        status: 'error',
        duration : null,
        isClosable: true,
      });
      setError(true)
      return ;
    }

    setLoading(true);
    console.log('Form Data -->', JSON.stringify(formData));
    try {

      const response = await fetch(`${config.url}/generate_qr_code/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        },
        credentials: "include",
        body: JSON.stringify(formData),
      });
      if (!response.ok) throw new Error('Failed to generate QR code');
      const data = await response.json();
      // console.log('Data -->', data);

      setQrCode(data.qrCodeUrl);

      toast({
        title: 'QR Code generated successfully!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

    } catch (error) {
      toast({
        title: 'Failed to generate QR code',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {

    fetch(qrCode)

      .then((res) => res.blob())  // Convert response to Blob
      .then((blob) => {
        saveAs(blob, `QR_${formData.recruiterName}.png`);  // FileSaver will handle the download
      })
      .catch((err) => {
        console.error('Error downloading the QR code:', err);
      });
  };

  // console.log('Input Error -->',inputerror);
  // console.log('Either of them -->', !formData.recruiterName || !formData.recruiterCode);
  
  // console.log((inputerror && (!formData.recruiterName || !formData.recruiterCode)));
  



  return (
    <Container maxW="4xl" py={8}>
      <Card boxShadow="lg" borderRadius="lg" bg="white">
        <CardHeader bg="gray.50" borderTopRadius="lg" py={5} textAlign="center">
          <Heading size="lg" color="gray.700">QR Code Generator</Heading>
        </CardHeader>
        <CardBody px={10} py={6}>
          <Box bg="blue.50" p={4} rounded="md" mb={6}>
            <Text fontSize="sm" color="blue.600" textAlign="center">
              Please fill in all required fields to generate your QR code.
            </Text>
          </Box>

          {/* HStack for Form and QR Code */}
          <HStack spacing={10} justify="center" align="center" transition="transform 0.5s ease">
            {/* Form Fields */}
            <Box
              flex="1"
              maxW={qrCode ? "50%" : "60%"}
              transition="max-width 0.5s ease"
              shadow="lg"
              borderRadius="md"
              p={4}
            >
              <VStack spacing={5} align="stretch">

                <FormControl >
                  <FormLabel>Recruiter Name</FormLabel>
                  <Input
                    name="recruiterName"
                    value={formData.recruiterName}
                    onChange={
                      (e) => {
                        handleInputChange(e);
                        setError(false);
                      }
                    }
                    placeholder="Enter recruiter name"
                    bg="gray.50"
                    borderColor={(inputerror && !formData.recruiterName ) ? 'red.400' : 'gray.300'}
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: "blue.500", bg: "white" }}
                    size="md"
                  />                  
                </FormControl>

                <FormControl >
                  <FormLabel>Recruiter Code</FormLabel>
                  <Input
                    name="recruiterCode"
                    value={formData.recruiterCode}
                    onChange={
                      (e) => {
                        handleInputChange(e);
                        setError(false);
                      }
                    }
                    placeholder="Enter recruiter code"
                    bg="gray.50"
                    borderColor={(inputerror && !formData.recruiterCode ) ? 'red.400' : 'gray.300'}
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: "blue.500", bg: "white" }}
                    size="md"
                  />
                </FormControl>

                <FormControl >
                  <FormLabel>Partner Branch Code</FormLabel>
                  <Input
                    name="partnerBranchCode"
                    value={formData.partnerBranchCode}
                    onChange={handleInputChange}
                    placeholder="Enter partner branch code"
                    bg="gray.50"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: "blue.500", bg: "white" }}
                    size="md"
                  />
                </FormControl>

                <FormControl >
                  <FormLabel>Partner Location</FormLabel>
                  <Input
                    name="partnerLocation"
                    value={formData.partnerLocation}
                    onChange={handleInputChange}
                    placeholder="Enter partner location"
                    bg="gray.50"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: "blue.500", bg: "white" }}
                    size="md"
                  />
                </FormControl>

                <FormControl >
                  <FormLabel>Recruiter Designation</FormLabel>
                  <Input
                    name="recruiterDesignation"
                    value={formData.recruiterDesignation}
                    onChange={handleInputChange}
                    placeholder="Enter recruiter designation"
                    bg="gray.50"
                    borderColor="gray.300"
                    _hover={{ borderColor: "gray.400" }}
                    _focus={{ borderColor: "blue.500", bg: "white" }}
                    size="md"
                  />
                </FormControl>


                <Box textAlign="center" mt={4}>
                  <Button
                    bgColor="#6941c6"
                    color="white"
                    _hover={{ bgColor: "#5a35a6" }}
                    onClick={handleGenerateQR}
                    isLoading={loading}
                    loadingText="Generating..."
                    size="lg"
                    fontWeight="medium"
                  >
                    Generate QR Code
                  </Button>
                </Box>
              </VStack>


            </Box>

            {/* QR Code Display */}
            {qrCode && (
              <Box
                flex="1"
                maxW="40%"
                transition="max-width 0.5s ease"
                display="flex"
                flexDirection="column"
                alignItems="center"
                shadow="lg"
                borderRadius="md"
                p={4}
                justifyContent="center"
              >
                <Text fontSize="lg" color="gray.700" fontWeight="medium" textAlign="center" mb={5}>
                  Generated QR Code
                </Text>
                <Box
                  p={4}
                  rounded="md"
                  bg="gray.50"
                  border="1px"
                  borderColor="gray.200"
                  mb={6}
                >
                  <Image
                    src={qrCode}
                    alt="Generated QR Code"
                    boxSize="200px"
                    mx="auto"
                  />
                </Box>
                <Button
                  colorScheme="green"
                  onClick={handleDownload}
                  size="lg"
                  fontWeight="medium"
                  leftIcon={<DownloadIcon />}
                >
                  Download QR Code
                </Button>
              </Box>
            )}
          </HStack>

        </CardBody>
      </Card>
    </Container>


  );

};
const DownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);
export default QRCode;