import {
    Stack, HStack, VStack, Text, Box, Heading, Divider,
    InputGroup, InputLeftAddon, Input, useToast
} from "@chakra-ui/react";
import React, { useState, useRef, } from "react";
import { Button, } from "../../../../components/Button";



import { useFlowInfo } from "../../../../hooks/useFlowInfo";
import { useParams } from "react-router-dom";

import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
} from '@chakra-ui/react'

import { FaCloudUploadAlt, FaTimes } from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import { BsFillSaveFill } from "react-icons/bs";


import config from "../../../../config";

export const WebChat = () => {
    const { botId, id } = useParams();
    const flowId = botId ?? id;
    const botInfo = useFlowInfo(flowId)    
    const [botName, setBotName] = useState();
    const [logoname, setProfileName] = useState();
    const [wallpapername, setWallpaperName] = useState();
    const [sliderValue, setSliderValue] = useState(50)
    const toast = useToast();
    const [logo, setLogo] = useState(null);
    const [wallpaper, setWallpaper] = useState(null);
    const fileLogoRef = useRef(null);
    const fileWallpaperRef = useRef(null);
    
    const handleFiles = (event, eventType) => {

        const file = event.target.files[0];

        if (file) {
            if (eventType === 'logo') {
                setProfileName(file.name);
                setLogo(file)
            } else {
                setWallpaperName(file.name);
                setWallpaper(file)
            }
        }
    };

    const handleClose = (eventType) => {

        if (eventType === 'logo') {
            setProfileName(null);
            if (fileLogoRef.current) {
                fileLogoRef.current.value = '';
            }
        } else {
            setWallpaperName(null);
            if (fileWallpaperRef.current) {
                fileWallpaperRef.current.value = '';
            }
        }
    };

    const handleSave = async () => {
        const formData = new FormData();
        formData.append('name', botName ? botName : botInfo.name);
        formData.append('logo', logo);
        formData.append('wall', wallpaper);
        formData.append('blur', sliderValue.toLocaleString());
        formData.append('flowId', flowId);
        try {
            const response = await fetch(`${config.url}/bot_profile_update/`, {
                method: 'POST',
                headers : {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                credentials: "include",
                body: formData
            });

            const result = await response.json();
            
            if (result['status']==='success'){
                toast({
                    title: "Changes saved",
                    status: "success",
                });
                
            }else {
                toast({
                    title: "Something Wrong",
                    status: "error",
                });
            }
        } catch (error) {
            toast({
                title: "Something Wrong",
                status: "error",
            });
        }
    };

    const handleBotName = (event) => {
        setBotName(event.target.value);
    };

    const handlePreview = () => {
        const baseurl = config.chat_url
        const preview = true;
        const mobileno = "919876543210"
        const name = flowId.split('v2-')[1].toUpperCase()
        const queryString = `preview=${preview}&mobile_number=${mobileno}&start=/start&bot=v2-${flowId}&name=${name}`;
        const url = `${baseurl}?data=${btoa(queryString)}`;
        window.open(url, '_blank');
    }


    return (
        <Stack className="px-8 py-4 m-0 h-screen w-full">
            <HStack justify="space-between">
                <Box py={2}>
                    <Heading py={1} size="md">
                        Web Chat Bot
                    </Heading>
                    <Text>Customize your web chat bot</Text>
                </Box>
            </HStack>

            <Box>
                <Divider />
            </Box>

            <VStack spacing={4} align="stretch">
                <Box className="w-full" >
                    <Heading py={1} size="sm">
                        Bot Name
                    </Heading>
                    <Text className="text-sm">The name will be used for bot profile name .</Text>
                </Box>

                <InputGroup size="sm" maxW="sm">
                    <InputLeftAddon color='#808e9b'>NAME</InputLeftAddon>
                    <Input type='text'
                        value={botName}
                        onChange={handleBotName}
                        placeholder='eg...Assesshub' />
                </InputGroup>
            </VStack>

            <VStack spacing={4} align="stretch" className="mt-2">
                <Box className="w-full">
                    <Heading py={1} size="sm">
                        Profile Logo
                    </Heading>
                    <Text className="text-sm">The selected image will be used for the Profile Logo </Text>
                </Box>

                <Box className="flex flex-col items-start">
                    <label htmlFor="file-logo" className="cursor-pointer">
                        <Button
                            as="span"
                            className="w-40 bg-blue-500 text-white flex items-center gap-2 rounded hover:bg-blue-600 active:bg-blue-700"
                        >
                            <FaCloudUploadAlt className="text-lg" />
                            <span> Choose Image</span>
                        </Button>
                    </label>

                    <Input
                        id="file-logo"
                        ref={fileLogoRef}
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(event) => handleFiles(event, 'logo')}
                    />

                    {logoname && (
                        <Box className="flex flex-row items-center mt-4 p-2 text-xs border-none rounded-md shadow-md bg-[#d2dae2] max-w-xs relative">
                            <Text className="text-[#778ca3] truncate flex-1">
                                {logoname}
                            </Text>
                            <FaTimes className="ml-4 cursor-pointer text-xs items-end" onClick={() => handleClose('logo')} />
                        </Box>
                    )}
                </Box>
            </VStack>

            <VStack spacing={4} align="stretch" className="mt-2">
                <Box className="w-full">
                    <Heading py={1} size="sm">
                        Background Wallpaper
                    </Heading>
                    <Text className="text-sm">The selected image will be used for the Background Wallpaper </Text>
                </Box>

                <Box className="flex flex-col items-start">
                    <label htmlFor="file-wallpaper" className="cursor-pointer">
                        <Button
                            as="span"
                            className="w-40 bg-blue-500 text-white flex items-center gap-2 rounded hover:bg-blue-600 active:bg-blue-700"
                        >
                            <FaCloudUploadAlt className="text-lg" />
                            <span>Choose Image</span>
                        </Button>
                    </label>

                    <Input
                        id="file-wallpaper"
                        ref={fileWallpaperRef}
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={(event) => handleFiles(event, null)}
                    />

                    {wallpapername && (
                        <Box className="flex flex-row items-center mt-4 p-2 text-xs border-none rounded-md shadow-md bg-[#d2dae2] max-w-xs relative">
                            <Text className="text-[#808e9b] truncate flex-1">
                                {wallpapername}
                            </Text>
                            <FaTimes className="ml-4 cursor-pointer text-xs items-end " onClick={() => handleClose(null)} />
                        </Box>
                    )}
                </Box>


            </VStack>

            <VStack spacing={4} align="stretch" className="mt-2">
                <Box className="w-full">
                    <Heading py={1} size="sm">
                        Wallpaper Opacity
                    </Heading>
                    <Text className="text-sm">Adjust the background opacity to make the background more transparent</Text>
                </Box>

                <Box p={4} pt={6} w='lg'>
                    <Slider aria-label='slider-ex-6' onChange={(val) => setSliderValue(val)}>
                        <SliderMark
                            value={sliderValue}
                            textAlign='center'
                            bg='#6941c6'
                            color='white'
                            mt='-10'
                            ml='-5'
                            w='12'
                        >
                            {sliderValue}%
                        </SliderMark>
                        <SliderTrack >
                            <SliderFilledTrack bg='#6941c6' />
                        </SliderTrack>
                        <SliderThumb />
                    </Slider>
                </Box>

            </VStack>

            <Box className="mt-2">
                <Divider />
            </Box>

            <VStack spacing={4} align="stretch" className="mt-4" w='lg'>
                <Box className="flex justify-between cursor-pointer mx-3" >
                    <Button
                        as="span"
                        onClick={handleSave}
                        className="w-40 bg-blue-500 text-white flex items-center gap-2 rounded hover:bg-blue-600 active:bg-blue-700">

                        <BsFillSaveFill className="text-sm" />
                        <span>Save Changes </span>

                    </Button>

                    <Button
                        as="span"
                        onClick={handlePreview}
                        className="w-40 bg-blue-500 text-white flex items-center gap-2 rounded hover:bg-blue-600 active:bg-blue-700">
                        <IoIosSend className="text-lg" />
                        <span>Quick Preview</span>
                    </Button>

                </Box>
            </VStack>
        </Stack>

    )
}