import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import axios from "axios";

import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";

interface MutationInput {
  flowId: string;

  // query key to refetch after mutation is completed
  refetchKey: string[];
}

export const useDeleteUserNote = ({ flowId, refetchKey }: MutationInput) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { user, getAuthToken } = useAuthToken();

  return useMutation({
    mutationKey: ["delete", "user_note"],
    mutationFn: async (noteId: string) => {
      const token = localStorage.getItem('token')

      const response = await axios.delete<{ status: "success" | "failed" }>(
        `${config.url}/v2/support/${flowId}/user_notes/${noteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "User-Email": user.email,
            "dashboard-user-id": user.email,
            "user-id": user.email,
          },
          withCredentials: true,
        }
      );

      if (response && response.data && response.data.status === "success") {
        toast({ title: "Note removed", status: "success" });
      }

      return response.data;
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, variables, context) => {
      toast({
        description: "failed to remove note, please try again",
        status: "error",
      });
    },

    // Always refetch after error or success:
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
