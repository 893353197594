import React, { useState, useRef, useEffect, } from "react";
import { Button, } from "../../../../components/Button";
import config from "../../../../config";
import { Form, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import { BsPeopleFill } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { MdTimer } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaCircle } from "react-icons/fa";
import { MdBookmarkAdded } from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa6";

import { useSupportUsers } from "../../../../data/support/useSupportUsers";
import {
    Stack, HStack, VStack, Text, Box, Heading, Divider,
    Input, useToast, FormLabel,
} from "@chakra-ui/react";






export const InterviewSchedular = () => {

    type BookedTimeSlots = Record<string, [string, string]>;

    const [selectedDate, setSelectedDate] = useState(dayjs());
    const currentMonth = dayjs();
    const { botId, id } = useParams();
    const flowId = botId ?? id;
    const user = JSON.parse(localStorage.getItem('user'));
    const { data: members } = useSupportUsers(flowId);

    const [beginingTime, setBeginingTime] = useState('');
    const [endingTime, setendingTime] = useState('');
    const [interviewDuration, setInterviewDuration] = useState(60);
    const [dayDate, setDayDate] = useState([]);
    const [timeSlots, setTimeSlots] = useState([])
    const [countSlots, setCountSlots] = useState(0)
    const [userdateforInterview, setUserDateForInterview] = useState('')
    const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
    const [bookedTimeSlots, setBookedTimeSlots] = useState<BookedTimeSlots>({});

    const [currentPage, setCurrentPage] = useState(0);
    const toast = useToast();






    useEffect(() => {
        getOperationalHours();
        setDayDate(getNextFiveDays());
        getInterviewerSchedule();
        getBookedInterviewSlots();
    }, []);


    useEffect(
        () => {
            setSelectedTimeSlots({});
            generateTimeSlots();
        }, [beginingTime, endingTime, interviewDuration]
    )


    // console.log('Booked Time SLots', bookedTimeSlots);


    // const currentTimeSlots = timeSlots.slice(currentPage * 10, (currentPage + 1) * 10);

    const handleInteviewDuration = (event) => {
        setInterviewDuration(event.target.value);
    };



    const handleBeginingTimeChange = (event) => {
        const utcplus = handleDateTime(event.target.value)
        if (utcplus) {
            const formateDate = formatDateToString(utcplus)
            setBeginingTime(formateDate)
        }

    }


    const handleEndingTimeChange = (event) => {

        const utcplus = handleDateTime(event.target.value)
        if (utcplus) {
            const formateDate = formatDateToString(utcplus)

            setendingTime(formateDate)
        }
    }
    
    const formatDateToString = (utcPlus: Date) => {

        if (!utcPlus) {
            return
        }

        const utcTimePlus5 = utcPlus.toISOString().replace('Z', '+05:30');
        const date = new Date(utcTimePlus5);
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        const formattedHours = hours < 10 ? '0' + hours : hours;

        return `${formattedHours}:${formattedMinutes}`;

    }

    const handleDateTime = (selectedTime) => {
        if (!selectedTime) {
            return null
        }

        const currentDate = new Date();

        // Create a date object with the current date and the selected time
        const dateTime = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            selectedTime.split(':')[0],
            selectedTime.split(':')[1]
        );

        const utcPlus530 = new Date(dateTime.getTime() + (5.5 * 60 * 60 * 1000));

        return utcPlus530;
    }

    const handleNextMonth = () => {
        setSelectedDate((prevDate) => prevDate.add(1, 'month'));
    };

    const handlePrevMonth = () => {
        setSelectedDate((prevDate) => prevDate.subtract(1, 'month'));
    };

    const saveOperationalHours = async () => {


        // console.log(handleDateTime(beginingTime));
        // console.log(handleDateTime(endingTime));



        const strdate = new Date(handleDateTime(beginingTime).toISOString().replace('Z', '+05:30'));

        const enddate = new Date(handleDateTime(endingTime).toISOString().replace('Z', '+05:30'));







        // console.log('begi Time',date);

        const requestData = {
            botId: flowId,
            startTime: strdate,
            endTime: enddate,
            duration: interviewDuration,
        };

        try {
            const response = await fetch(`${config.url}/operational_hours/${user.id}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }

            toast({
                title: "Changes saved",
                status: "success",
            });

        } catch (error) {
            toast({
                status: "error",
                title: "Something Wrong"
            })

        }

        // console.log('Response', response.json());


    }

    const getOperationalHours = async () => {

        try {
            const response = await fetch(`${config.url}/get_operational_hours/${user.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
            });


            if (!response.ok) {
                return;
            }

            const resp = await response.json();



            setBeginingTime(resp.startTime)
            // console.log('Begining Time -->',beginingTime);

            setendingTime(resp.endTime)
            // console.log('Ending Time -->',endingTime);

            setInterviewDuration(resp.duration)

        } catch (error) {

            console.error(error);


        }





    }

    const generateTimeSlots = () => {

        const millisecondsInMinute = 60 * 1000;
        const durationInMilliseconds = interviewDuration * millisecondsInMinute;

        const startime = handleDateTime(beginingTime)
        const endtime = handleDateTime(endingTime)

        if (!startime || !endtime) {
            return
        }

        const date1 = new Date(startime.toISOString().replace('Z', '+05:30'))
        const date2 = new Date(endtime.toISOString().replace('Z', '+05:30'))

        const slots = ((date2.getTime() - date1.getTime()) / durationInMilliseconds)

        setCountSlots(slots)

        const timeSlotsArray = [];

        const formatHour = (hour) => {
            const formattedHour = hour % 12 || 12;
            return formattedHour < 10 ? `0${formattedHour}` : formattedHour;
        };


        for (let i = 0; i < slots; i++) {
            const date = new Date(date1);
            date.setTime(date1.getTime() + i * durationInMilliseconds); // Update time based on slot duration

            const currentHour = date.getHours();
            const currentMinutes = date.getMinutes();

            // Calculate the next time slot based on duration
            const nextSlotTime = new Date(date);
            nextSlotTime.setTime(date.getTime() + durationInMilliseconds);

            const nextHour = nextSlotTime.getHours();
            const nextMinutes = nextSlotTime.getMinutes();

            const currentAmpm = currentHour >= 12 ? 'PM' : 'AM';
            const nextAmpm = nextHour >= 12 ? 'PM' : 'AM';

            const formattedCurrentHour = formatHour(currentHour);
            const formattedNextHour = formatHour(nextHour);

            // Format minutes
            const formattedCurrentMinutes = currentMinutes < 10 ? `0${currentMinutes}` : currentMinutes;
            const formattedNextMinutes = nextMinutes < 10 ? `0${nextMinutes}` : nextMinutes;

            // Create the time range string
            const timeRange = `${formattedCurrentHour}:${formattedCurrentMinutes} ${currentAmpm} - ${formattedNextHour}:${formattedNextMinutes} ${nextAmpm}`;

            timeSlotsArray.push(timeRange);
        }

        setTimeSlots(timeSlotsArray)

    }

    const getNextFiveDays = () => {
        const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const result = [];
        const today = new Date();
        let dayCount = 0;


        while (result.length < 5) {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            currentDate.setDate(today.getDate() + dayCount);
            const dayOfWeek = currentDate.getDay();

            if (dayOfWeek !== 0 && dayOfWeek !== 6) {
                const dayName = daysOfWeek[dayOfWeek];
                result.push({
                    date: String(currentDate.getDate()).padStart(2, '0'), day: dayName,
                    utc: currentDate
                });
            }
            dayCount++;
        }





        return result;

    }

    const interviewerSchedule = async () => {

        // console.log("User -->", userforInterview);
        const dateObject = new Date(userdateforInterview);

        const formattedDate = new Date(dateObject.getTime() - dateObject.getTimezoneOffset() * 60000).toISOString();



        const requestData = {
            botId: flowId,
            userId: user.id,
            userName: user.username,
            interviewerDate: formattedDate,
            interviewerTimeSlots: selectedTimeSlots[userdateforInterview]
        }

        try {

            const response = await fetch(`${config.url}/interviewer_schedule/`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData),
            });


            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }

            toast({
                title: "Changes saved",
                status: "success",
            });

        } catch (error) {
            toast(
                {
                    "title": "Something Wrong",
                    "status": "error"
                }
            )

        }

    }

    const handleTimeSlotClick = (timeSlot) => {
        const currentSelectedTimeSlots = selectedTimeSlots[userdateforInterview] || [];

        // if (isBooked(userdateforInterview, timeSlot)) {
        //     console.log('This slot is booked and cannot be selected.');
        //     return;
        // }

        if (currentSelectedTimeSlots.includes(timeSlot)) {
            const updatedTimeSlots = currentSelectedTimeSlots.filter(slot => slot !== timeSlot);
            setSelectedTimeSlots({
                ...selectedTimeSlots,
                [userdateforInterview]: updatedTimeSlots
            });
        } else {
            setSelectedTimeSlots({
                ...selectedTimeSlots,
                [userdateforInterview]: [...currentSelectedTimeSlots, timeSlot]
            });
        }
    };

    const updateTimeSlots = (data) => {
        const updatedData = {};

        for (const [date, time] of Object.entries(data)) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const dateObj = new Date(date + 'T' + now.toTimeString().split(' ')[0] + '.000+0530');
            updatedData[dateObj.toString()] = time
        }

        return updatedData;
    }

    const getInterviewerSchedule = async () => {
        try {

            const response = await fetch(`${config.url}/get_interviewer_schedule/${user.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
            });


            if (!response.ok) {
                return;
            }

            const resp = await response.json();
            const data = updateTimeSlots(resp);
            setSelectedTimeSlots(data);

        } catch (error) {
            console.log(error);

        }

    }

    const getBookedInterviewSlots = async () => {

        try {

            const requestData = {
                flowId: flowId,
                userId: user.id,

            }
            const response = await fetch(`${config.url}/get_booked_interview_slot`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                toast(
                    {
                        "title": "Something Wrong",
                        "status": "error"
                    }
                )
                return
            }


            const resp = await response.json();
            setBookedTimeSlots(resp);


        } catch (error) {

            toast(
                {
                    "title": "Something Wrong",
                    "status": "error"
                }
            )

        }
    }

    // const isBooked = (date: string, timeSlot: string): boolean => {

    //     // console.log('Booked Time Slot', bookedTimeSlots);
    //     // console.log('Date', date);
    //     // console.log('TimeSlot ', timeSlot);



    //     const value = Object.values(bookedTimeSlots).some(([bookedDate, bookedSlot]) => {
    //         const now = new Date();
    //         now.setHours(0, 0, 0, 0);

    //         const dateObj = new Date(bookedDate + 'T00:00:00');
    //         const providedDateObj = new Date(date);

    //         // Log the types and values for debugging
    //         console.log('Check One', dateObj, providedDateObj, dateObj.getTime() === providedDateObj.getTime());

    //         // Assuming bookedSlot and timeSlot are both strings
    //         console.log('Check Two', bookedSlot, timeSlot, bookedSlot === timeSlot);

    //         // Return the final comparison
    //         return dateObj.getTime() === providedDateObj.getTime() && bookedSlot === timeSlot;
    //     });





    //     return value

    // };


    const handleNextPage = () => {
        if ((currentPage + 1) * 10 < timeSlots.length) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    }


    return (
        <Stack className="px-8 py-4 m-0 h-screen w-full">
            <HStack justify="space-between">
                <Box py={2}>
                    <Heading py={1} size="md">
                        Interview Schedular
                    </Heading>
                    <Text>Seamlessly schedule interviews.</Text>
                </Box>
            </HStack>

            <Box>
                <Divider />
            </Box>

            <HStack flexDirection="row" className="space-x-4">
                <VStack flexDirection="column" w="full" h="full" alignItems="center" className="space-y-4">
                    <HStack spacing={2} w="full" justifyContent="center">
                        <Box className="w-full shadow-md rounded-xl border">
                            <HStack spacing={2} className="flex flex-col" p={4}>
                                <BsPeopleFill className="text-md text-[#5A639C]" />
                                <Text fontSize="md" fontWeight="bold">List of Participants</Text>
                            </HStack>

                            <Box>
                                <Divider />
                            </Box>


                            <Box className="flex flex-col space-y-2 w-full p-2">
                                <Box className="flex flex-row justify-around space-x-2 bg-[#F8F8F8]">
                                    <Box className="flex w-full p-2 ">
                                        <Text className="font-bold">Name</Text>
                                    </Box>
                                    <Box className="flex  w-full p-2">
                                        <Text className="font-bold">Phone</Text>
                                    </Box>
                                </Box>

                                {/* Scrollable User List */}
                                <Box className="max-h-64 overflow-y-auto">
                                    {members && members.map((user, index) => (
                                        <HStack
                                            key={index}
                                            className="p-2 shadow-sm rounded-md hover:bg-gray-100 transition duration-200 mb-2 justify-center hover:cursor-pointer"
                                            w="full"
                                        >
                                            <HStack className="w-1/2 max-w-lg justify-start">
                                                <Text className="font-normal text-sm ">{user.name}</Text>
                                            </HStack>

                                            <HStack className="w-1/2 max-w-lg justify-start">
                                                <Text className="font-normal text-sm ">{user.phone}</Text>
                                            </HStack>

                                        </HStack>
                                    ))}
                                </Box>



                            </Box>
                        </Box>
                    </HStack>

                    <HStack flex="1" w="full" spacing={2} alignItems="flex-start">
                        <Box className="w-full shadow-md rounded-xl border">
                            <HStack spacing={2} className="flex flex-col" p={4}>
                                <FaBusinessTime className="text-md text-[#5A639C]" />
                                <Text fontSize="md" fontWeight="bold">Hours of Operation</Text>
                            </HStack>
                            <Box>
                                <Divider />
                            </Box>

                            <HStack w="full" spacing={4} align="center" p={4} >
                                <VStack w="full" align="start">
                                    <FormLabel fontSize="sm" color="#9aa1a9" >Beginning Time</FormLabel>
                                    <Input type="time" value={beginingTime} focusBorderColor="blue.500" onChange={handleBeginingTimeChange} />
                                </VStack>

                                <VStack w="full" align="start">
                                    <FormLabel fontSize="sm" color="#9aa1a9">Ending Time</FormLabel>
                                    <Input type="time" value={endingTime} focusBorderColor="blue.500" onChange={handleEndingTimeChange} />
                                </VStack>
                            </HStack>

                            <HStack alignItems="center" w="full" p={4} spacing={4}>
                                <Box className="flex justify-end items-center p-4 text-[#758694]">
                                    <MdTimer />
                                    <Text className="mx-2" fontWeight="medium" >Duration</Text>
                                    <select className="border rounded-md p-2 hover:cursor-pointer outline-none"
                                        value={interviewDuration} onChange={handleInteviewDuration}>
                                        <option value="30">30 Min</option>
                                        <option value="60">60 Min</option>
                                    </select>
                                </Box>

                                <Box p={2} className="flex items-center hover:cursor-pointer justify-center shadow-md rounded-xl w-1/5 space-x-2 border">
                                    <MdBookmarkAdded className="text-[#7D8ABC]" />
                                    <Text fontSize="md" fontWeight="medium" onClick={saveOperationalHours}  >
                                        Save
                                    </Text>
                                </Box>


                            </HStack>


                        </Box>
                    </HStack>

                </VStack>

                <VStack spacing={2} align="stretch" className="rounded-xl w-3/5 border p-4 shadow-md">
                    <Box className="w-full" >
                        <Heading py={1} size="sm">
                            Schedule an Interview to Assess and Discuss Relevant Experience
                        </Heading>
                        <Text className="text-sm  mt-4 font-medium text-[#758694]">A focused conversation to evaluate qualifications, discuss relevant
                            experiences, and understand the candidate's fit for the role.</Text>
                    </Box>

                    <Box >
                        <Divider />
                    </Box>


                    <HStack alignItems="center" w="full" p={4} >
                        <Box className="flex flex-row border rounded-xl shadow-md p-4 items-center space-x-4">
                            <FaCalendarAlt className="text-[#5A639C]" />
                            <Text fontSize="md" fontWeight="medium">
                                {selectedDate.format('MMMM YYYY')}
                            </Text>
                        </Box>


                        <Box className="flex-grow" />

                        <Box className="flex flex-row text-[#5A639C]">
                            <Box
                                p={2}
                                className={`hover:cursor-pointer border rounded-md mx-2 ${selectedDate.isSame(currentMonth, 'month') ? 'cursor-not-allowed opacity-50' : 'hover:shadow-md'}`}
                                onClick={() => {
                                    if (!selectedDate.isSame(currentMonth, 'month')) {
                                        handlePrevMonth();
                                    }
                                }}
                            >
                                <IoIosArrowBack />
                            </Box>

                            <Box
                                p={2}
                                className="hover:cursor-pointer border hover:shadow-md rounded-md mx-2"
                                onClick={() => { handleNextMonth(); }}
                            >
                                <IoIosArrowForward />
                            </Box>
                        </Box>
                    </HStack>


                    <HStack alignItems="center" w="full" p={4} spacing={4} className="flex justify-between">
                        {dayDate.map((item, index) => (
                            <Box
                                key={index}
                                onClick={
                                    () => {
                                        if (userdateforInterview === item.utc) {
                                            setUserDateForInterview(null);
                                        } else {
                                            setUserDateForInterview(item.utc); // Select the new date
                                        }

                                    }
                                }
                                className={`shadow-md rounded-xl hover:cursor-pointer border w-24 p-2 
                                    transition duration-300 ease-in-out flex items-center justify-center
                                    ${item.utc === userdateforInterview
                                        ? 'border-[#AD49E1] shadow-[0px_0px_12px_4px_rgba(173,73,225,0.6)]'
                                        : 'hover:shadow-glow border-gray-300'}`}
                            >
                                <Box className="flex flex-col items-center justify-center space-y-2">
                                    <Text fontSize="md" fontWeight="medium" color="#AD49E1">
                                        {item.day}
                                    </Text>
                                    <Text fontSize="2xl" fontWeight="bold" color="#AD49E1">
                                        {item.date}
                                    </Text>

                                    <Box className="flex flex-row items-center justify-center space-x-1">
                                        <FaCircle size={16} color="#73EC8B" />
                                        <Text fontSize="sm" fontWeight="medium" color="#A594F9">

                                        </Text>
                                        <Text fontSize="sm" fontWeight="medium" color="#A594F9">
                                            {countSlots}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </HStack>

                    {userdateforInterview && (
                        <HStack w="full" spacing={2} ml={2} className="flex flex-wrap justify-start">
                            {timeSlots.map((timeSlot, index) => {
                                // Check if the current time slot is booked for the selected date
                                const isBooked = Object.values(bookedTimeSlots).some(([bookedDate, bookedSlot]) => {
                                    const dateObj = new Date(bookedDate + 'T00:00:00'); // Format the booked date
                                    const providedDateObj = new Date(userdateforInterview); // Date for comparison
                                    return dateObj.getTime() === providedDateObj.getTime() && bookedSlot === timeSlot;
                                });

                                // Check if the current time slot is present in setSelectedTimeSlots for the specific date
                                const isSelected = selectedTimeSlots[userdateforInterview]?.includes(timeSlot);

                                return (
                                    <Box
                                        key={index}
                                        onClick={() => {
                                            // Only allow clicking if the time slot is not booked
                                            if (!isBooked) {
                                                // Handle selection for the specific date
                                                setSelectedTimeSlots(prev => ({
                                                    ...prev,
                                                    [userdateforInterview]: isSelected
                                                        ? prev[userdateforInterview]?.filter(slot => slot !== timeSlot) // Remove from selection
                                                        : [...(prev[userdateforInterview] || []), timeSlot] // Add to selection
                                                }));
                                            }
                                        }}
                                        className={`flex items-center hover:cursor-pointer justify-center rounded-xl border-2 w-1/4 py-3 m-1
                                    ${isBooked ? "bg-gray-300 cursor-not-allowed" :
                                                                    isSelected ? "bg-[#86D293]" :
                                                                        "border-[#bea8f9] shadow-sm hover:shadow-md"}
                                `}
                                    >
                                        <Text fontSize="xs" fontWeight="extrabold" color={isBooked ? "#A0AEC0" : isSelected ? "white" : "#AD49E1"}>
                                            {timeSlot}
                                        </Text>
                                    </Box>
                                );
                            })}




                        </HStack>
                    )}

                    <HStack alignItems="center" w="full" p={4} spacing={4} className="flex justify-between">

                        <Box p={2} className="flex items-center hover:cursor-pointer justify-center shadow-md rounded-xl 
                        w-1/5 space-x-2 border" onClick={
                                () => interviewerSchedule()
                            }>
                            <MdBookmarkAdded className="text-[#7D8ABC]" />
                            <Text fontSize="md" fontWeight="medium"    >
                                Save
                            </Text>
                        </Box>

                        <Box className="flex flex-row text-[#5A639C]">
                            <Box
                                p={2}
                                onClick={handlePreviousPage}
                                className={`hover:cursor-pointer border hover:shadow-md rounded-md mx-2 
                                    ${currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""}`}

                            >
                                <IoIosArrowBack />
                            </Box>

                            <Text fontWeight="bold">
                                Page {currentPage + 1} of {Math.ceil(timeSlots.length / 10)}
                            </Text>

                            <Box
                                p={2}
                                onClick={handleNextPage}
                                className={`hover:cursor-pointer border hover:shadow-md rounded-md mx-2 
                                    ${(currentPage + 1) * 10 >= timeSlots.length ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                                <IoIosArrowForward />
                            </Box>
                        </Box>
                    </HStack>

                </VStack>
            </HStack>

        </Stack >
    )

}