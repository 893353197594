import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useAuthToken } from "../../hooks/useAuthToken";
import config from "../../config";

interface UserTranscriptResult {
  transcript: MessageInDb[];
  last_document_index?: number;
}

export const useTranscript = ({
  flowId,
  userId,
  limit = 20,
}: {
  flowId: string;
  userId: string;
  limit?: number;
}) => {
  const { getAuthToken } = useAuthToken();

  const fetchChats = async ({ pageParam = 0 }) => {
    const token = localStorage.getItem('token')

    const response = await axios.post<UserTranscriptResult>(
      `${config.url}/v2/users/transcript/${flowId}/${userId}`,
      {
        skip: pageParam,
        size: limit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  };

  return useInfiniteQuery(["transcript", flowId, userId], fetchChats, {
    // getPreviousPageParam: (firstPage) => firstPage ?? undefined,
    getNextPageParam: (lastPage) => lastPage.last_document_index ?? undefined,
    keepPreviousData: true,
    refetchInterval: 1000, //
    // refetchIntervalInBackground: false,
    // refetchOnWindowFocus: false,
  });
};
