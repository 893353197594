import React, { forwardRef, Fragment, useEffect } from "react";
import { Stack, StackProps, CircularProgress, HStack } from "@chakra-ui/react";
import { useInView } from "react-intersection-observer";

import { SupportMessage } from "./Message";
import { useTranscript } from "../../../data/support/useTranscript";
import { useDashboardEvents } from "../../../data/events/useDashboardEvents";
import { DashboardEvent } from "../../../api/events";
import { DashboardEventBubble } from "../../../components/DashboardEventBubble";

interface TranscriptProps extends StackProps {
  flowId: string;
  userId: string;
  sessionId: string;
}

interface MessageEvent {
  event: "MESSAGE";
  message: MessageInDb;
  timestamp: number;
}

export const Transcript = forwardRef<HTMLDivElement, TranscriptProps>(
  ({ flowId, userId, sessionId, ...props }, ref) => {
    const { ref: listRef, inView: isInView } = useInView({
      threshold: 0,
    });

    useEffect(() => {
      if (isInView) {
        fetchNextPage();
      }
    }, [isInView]);

    const { data, isLoading, error, fetchNextPage, hasNextPage } =
      useTranscript({
        flowId,
        userId,
      });

    const { data: events } = useDashboardEvents(sessionId);

    const appendEventsAndChat = (
      transcript: MessageInDb[]
    ): (MessageEvent | DashboardEvent)[] => {
      // appends events that happened during the chat to the transcript
      const messageObjs: MessageEvent[] = transcript.map((event) => ({
        event: "MESSAGE",
        message: event,
        timestamp: event.timestamp,
      }));

      if (!events) return messageObjs;

      if (messageObjs.length === 0) return [];

      let eventsHappenedDuringChat = events.filter(
        (event) =>
          event.timestamp < transcript[0].timestamp && // event happened after the chat started
          event.timestamp > transcript[transcript.length - 1].timestamp // event happened before the chat ended
      );

      if (eventsHappenedDuringChat && eventsHappenedDuringChat.length === 0)
        return messageObjs;

      let eventObjs = [...eventsHappenedDuringChat, ...messageObjs];
      eventObjs.sort((a, b) => b.timestamp - a.timestamp);
      return eventObjs;
    };

    if (isLoading)
      return (
        <Stack ref={ref} {...props}>
          <div>Loading...</div>;
        </Stack>
      );

    // if (error)
    //   return (
    //     <Stack ref={ref} {...props}>
    //       <div>{JSON.stringify(error)}</div>;
    //     </Stack>
    //   );

    if (!data)
      return (
        <Stack ref={ref} {...props}>
          <div>No data</div>;
        </Stack>
      );

    return (
      <Stack ref={ref} {...props}>
        {data &&
          data.pages &&
          data.pages.map((page, i) => (
            <Fragment>
              {appendEventsAndChat(page.transcript).map((message, idx) =>
                message.event === "MESSAGE" ? (
                  <SupportMessage
                    key={`${message.message.message_id}_${idx}`}
                    inverse
                    message={message.message}
                  />
                ) : (
                  <Stack py={1}>
                    <HStack
                      alignSelf="center"
                      px={2}
                      bg="gray.100"
                      borderWidth="thin"
                      borderRadius="md"
                    >
                      <DashboardEventBubble event={message} color="gray.600" />
                    </HStack>
                  </Stack>
                )
              )}
            </Fragment>
          ))}
        {hasNextPage && (
          <CircularProgress isIndeterminate alignSelf="center" ref={listRef} />
        )}
      </Stack>
    );
  }
);
