import React, { useEffect, useState } from "react";

import { ButtonGroup, IconButton, Spacer, Stack } from "@chakra-ui/react";

import {
  AIAsk,
  AITurn,
  ask_and_acknowledge,
  IntentUnion,
  Media,
} from "../models/intent";

import { ConversationTurn } from "./ConversationTurn";
import { MdDragIndicator } from "react-icons/md";
import { DeleteIcon, DownvoteIcon, UpvoteIcon } from "../constants/icons";
import { createAIComponent } from "../api/flow";
import { useAuthToken } from "../hooks/useAuthToken";
import {
  ImageChatMessage,
  VideoChatMessage,
  AudioChatMessage,
} from "./ChatMessage";

type IntentViewProps = {
  intent: IntentUnion;
  persona: string;
  tone: string;
  intentEditing?: boolean;
  onChange?: (intent: IntentUnion) => void;
  onRemove?: (intent: IntentUnion) => void;
  onDownVote?: (intent: IntentUnion) => void;
  onUpvote?: (intent: IntentUnion) => void;
  onButtonTap?: (button: string) => void;
  setIntentEditing?: () => void;
};

const generateRandomContext = () => {
  const context = [
    "acknowledge and don't ask any question",
    "uplift and motivate their response",
    "cheerfully acknowledge to what user has replied",
    "show empathy and gratitude towards their response",
  ];
  return context[Math.floor(Math.random() * context.length)];
};

const parseMedia = (intent: Media): UserMediaMessage => {
  if (intent.media_type === "image") {
    return {
      type: "image",
      name: intent.media_type,
      url: intent.media,
      message: intent.body,
      timestamp: 0,
    };
  }

  if (intent.media_type === "video") {
    return {
      type: "video",
      name: intent.media_type,
      url: intent.media,
      message: intent.body,
      timestamp: 0,
    };
  }

  return {
    type: "audio",
    name: intent.media_type,
    url: intent.media,
    message: intent.body,
    timestamp: 0,
  };
};

export const IntentView = ({
  intent: givenIntent,
  ...props
}: IntentViewProps) => {
  const { 
    user: { email: userMail, username: userName },
    getAuthToken } = useAuthToken();

  const [intent, setIntent] = useState<IntentUnion>(givenIntent);
  const [currentlyEditing, setCurrentlyEditing] = useState(-2);
  const [loading, setLoading] = useState<number | boolean>(false);

  useEffect(() => {
    setIntent(givenIntent);
  }, [givenIntent]);

  const saveIntent = async (body: string, response: string = "") => {
    const updatedIntent = intent;
    updatedIntent.body = body;
    if (response) updatedIntent.userResponse = response;
    props.onChange?.(updatedIntent);
  };

  const updateReply = async (
    reply: string,
    index: number,
    intent: IntentUnion
  ) => {
    const generateVariation = async (intent: AIAsk, turn: AITurn) => {
      const variationInput = ask_and_acknowledge(
        turn.question,
        intent.persona,
        intent.tone,
        intent.context
      );
      variationInput.turns = [
        {
          question: turn.question,
          reply: turn.reply,
          hallucination: "",
          acknowledgement: "",
        },
      ];

      const token = localStorage.getItem('token')

      const turns = await createAIComponent({
        userMail,
        token,
        intent: variationInput,
        flowId: "",
      });
      return turns;
    };

    if (intent.type === "ai_ask") {
      const updatedIntent = intent;
      updatedIntent.turns[index].reply = reply;
      setLoading(index);
      const turns = await generateVariation(
        updatedIntent,
        intent.turns[index]
      ).catch((_) => {
        props.onChange?.(updatedIntent);
      });
      setLoading(false);
      if (turns) {
        updatedIntent.turns[index] = turns.pop();
        props.onChange?.(updatedIntent);
      }
    }
  };

  const renderOptions = () => {
    if (intent.type === "ask_in_options") {
      if (!intent.userResponse) return "";

      const match = intent.options.filter((button) =>
        button.title.toLowerCase() === intent.userResponse.toLowerCase()
          ? button.response.body
          : ""
      );

      if (match.length > 0) return match[0].response.body;
    }

    return intent.userResponse;
  };

  if (intent.type === "delay")
    return (
      <ConversationTurn
        hasDelay
        question={intent.body}
        allowQuestionEditing={true}
        isQuestionEditing={props.intentEditing && currentlyEditing === -1}
        onQuestionSave={(message) => {
          saveIntent(message);
          setCurrentlyEditing(-2);
        }}
        onQuestionEdit={(isEditing) => {
          // this is to ensure the other block locks before this edits
          props.setIntentEditing();
          setCurrentlyEditing(-1);
        }}
        questionActions={
          <ButtonGroup isAttached>
            <IconButton
              cursor="grab"
              size="xs"
              aria-label="drag"
              icon={<MdDragIndicator />}
            />
            <IconButton
              variant="ghost"
              size="xs"
              aria-label={""}
              onClick={() => props.onRemove?.(intent)}
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
      />
    );

  if (intent.type === "say")
    return (
      <ConversationTurn
        question={intent.body}
        allowQuestionEditing={true}
        isQuestionEditing={props.intentEditing && currentlyEditing === -1}
        onQuestionSave={(message) => {
          saveIntent(message);
          setCurrentlyEditing(-2);
        }}
        onQuestionEdit={(isEditing) => {
          // this is to ensure the other block locks before this edits
          props.setIntentEditing();
          setCurrentlyEditing(-1);
        }}
        questionActions={
          <ButtonGroup isAttached>
            <IconButton
              cursor="grab"
              size="xs"
              aria-label="drag"
              icon={<MdDragIndicator />}
            />
            <IconButton
              variant="ghost"
              size="xs"
              aria-label={""}
              onClick={() => props.onRemove?.(intent)}
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
      />
    );

  if (intent.type === "branch" || intent.type === "ask_in_options")
    return (
      <ConversationTurn
        question={intent.body}
        questionButtons={
          intent.type === "branch"
            ? intent.branches?.map((button) => button) ?? []
            : intent.options?.map((button) => button.title)
        }
        onQuestionButtonTap={(button) => {
          saveIntent(intent.body, button);
          props.onButtonTap?.(button);
        }}
        allowQuestionEditing={true}
        isQuestionEditing={props.intentEditing && currentlyEditing === -1}
        onQuestionSave={(message) => {
          saveIntent(message);
          setCurrentlyEditing(-2);
        }}
        onQuestionEdit={(isEditing) => {
          // this is to ensure the other block locks before this edits
          props.setIntentEditing();
          setCurrentlyEditing(-1);
        }}
        //
        reply={intent.userResponse}
        allowReplyEditing={false}
        //
        questionActions={
          <ButtonGroup isAttached>
            <IconButton
              cursor="grab"
              size="xs"
              aria-label="drag"
              icon={<MdDragIndicator />}
            />
            <IconButton
              variant="ghost"
              size="xs"
              aria-label={""}
              onClick={() => props.onRemove?.(intent)}
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
        acknowledgement={
          intent.type === "ask_in_options" ? renderOptions() : ""
        }
      />
    );

  if (intent.type === "ask")
    return (
      <ConversationTurn
        question={intent.body}
        allowQuestionEditing={true}
        isQuestionEditing={props.intentEditing && currentlyEditing === 0}
        onQuestionSave={(message) => {
          saveIntent(message);
          setCurrentlyEditing(-2);
        }}
        onQuestionEdit={(isEditing) => {
          // this is to ensure the other block locks before this edits
          props.setIntentEditing();
          setCurrentlyEditing(0);
        }}
        //
        reply={intent.userResponse ?? "hmm"}
        // isLoading={loading && flag === "res"}
        allowReplyEditing
        isEditing={props.intentEditing && currentlyEditing === -1}
        onReplyEdit={(isEditing) => {
          // this is to ensure the other block locks before this edits
          props.setIntentEditing();
          setCurrentlyEditing(-1);
        }}
        onReplySave={(message) => {
          saveIntent(intent.body, message);
          setCurrentlyEditing(-2);
        }}
        //
        questionActions={
          <ButtonGroup isAttached>
            <IconButton
              cursor="grab"
              size="xs"
              aria-label="drag"
              icon={<MdDragIndicator />}
            />
            <IconButton
              variant="ghost"
              size="xs"
              aria-label={""}
              onClick={() => props.onRemove?.(intent)}
            >
              <DeleteIcon />
            </IconButton>
          </ButtonGroup>
        }
      />
    );

  if (intent.type === "media")
    return (
      <Stack>
        <Stack mx={"28"} w="50%">
          {intent.media_type === "image" ? (
            <ImageChatMessage message={parseMedia(intent)} />
          ) : intent.media_type === "video" ? (
            <VideoChatMessage message={parseMedia(intent)} />
          ) : (
            <AudioChatMessage message={parseMedia(intent)} />
          )}
        </Stack>
        <ConversationTurn
          allowQuestionEditing={true}
          isQuestionEditing={props.intentEditing && currentlyEditing === -1}
          onQuestionSave={(message) => {
            saveIntent(message);
            setCurrentlyEditing(-2);
          }}
          onQuestionEdit={(isEditing) => {
            // this is to ensure the other block locks before this edits
            props.setIntentEditing();
            setCurrentlyEditing(-1);
          }}
          questionActions={
            <ButtonGroup isAttached>
              <IconButton
                cursor="grab"
                size="xs"
                aria-label="drag"
                icon={<MdDragIndicator />}
              />
              <IconButton
                variant="ghost"
                size="xs"
                aria-label={""}
                onClick={() => props.onRemove?.(intent)}
              >
                <DeleteIcon />
              </IconButton>
            </ButtonGroup>
          }
          question={intent.body}
        />
      </Stack>
    );

  if (intent.type === "ai_ask")
    return (
      <Stack>
        {intent.turns.map((aiTurn, index) => (
          <ConversationTurn
            question={aiTurn.question}
            allowQuestionEditing={index === 0}
            isQuestionEditing={
              props.intentEditing && currentlyEditing === -1 && index === 0
            }
            onQuestionSave={(message) => {
              if (index !== 0) return;
              // saveIntent(intent.body, message);
              if (!message) return setCurrentlyEditing(-2);

              const defaultMessage = `${aiTurn.question}`;
              const editedMessage = `${message}`;

              if (defaultMessage !== editedMessage) {
                const updatedIntent = intent;
                updatedIntent.turns[0].question = editedMessage;
                updatedIntent.body = editedMessage;
                props.onChange?.(updatedIntent);
              }

              setCurrentlyEditing(-2);
            }}
            onQuestionEdit={(isEditing) => {
              // this is to ensure the other block locks before this edits
              props.setIntentEditing();
              setCurrentlyEditing(-1);
            }}
            reply={aiTurn.reply}
            acknowledgement={
              aiTurn.acknowledgement === "" ? "got it." : aiTurn.acknowledgement
            }
            isAcknowledgmentLoading={loading === index}
            isEditing={props.intentEditing && currentlyEditing === index}
            onReplyEdit={(isEditing) => {
              // this is to ensure the other block locks before this edits
              props.setIntentEditing();
              setCurrentlyEditing(index);
            }}
            onReplySave={(message) => {

              // saveIntent(intent.body, message);
              if (!message) return setCurrentlyEditing(-2);

              const defaultMessage = `${aiTurn.reply}`;
              const editedMessage = `${message}`;

              if (defaultMessage !== editedMessage)
                updateReply(message, index, intent);

              setCurrentlyEditing(-2);
            }}
            questionActions={
              <ButtonGroup isAttached>
                <IconButton
                  hidden={index !== 0}
                  cursor="grab"
                  size="xs"
                  aria-label="drag"
                  icon={<MdDragIndicator />}
                />

                <Spacer hidden={index === 0} px={3} />

                <IconButton
                  variant="ghost"
                  size="xs"
                  aria-label={""}
                  onClick={() => {
                    if (index === 0) return props.onRemove?.(intent);
                    intent.turns = intent.turns.filter(
                      (value, idx) => idx !== index
                    );
                    props.onChange?.(intent);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ButtonGroup>
            }
            acknowledgmentActions={
              <ButtonGroup isAttached>
                <IconButton
                  variant="ghost"
                  size="xs"
                  icon={<UpvoteIcon />}
                  aria-label={"retry"}
                  onClick={() => props.onUpvote?.(intent)}
                />
                <IconButton
                  variant="ghost"
                  size="xs"
                  icon={<DownvoteIcon />}
                  aria-label={"retry"}
                  onClick={() => {
                    intent.context = generateRandomContext();
                    updateReply(aiTurn.reply, index, intent);
                    props.onDownVote?.(intent);
                  }}
                />
              </ButtonGroup>
            }
          />
        ))}
      </Stack>
    );

  return (
    <ConversationTurn
      question={intent.body}
      reply={intent.userResponse}
      acknowledgement={intent.userResponse}
    />
  );
};
