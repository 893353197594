import React, { useEffect, useState } from "react";
import { Container, useToast } from "@chakra-ui/react";

import { MicroFlow } from "../../models/intent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMicroFlows, saveMicroFlow } from "../../api/flow";
import { FlowBuilder } from "../FlowBuilder";
import { useStoreActions } from "../../hooks/store";
import { useAuthToken } from "../../hooks/useAuthToken";

export const ConversationBuilder = () => {
  const { getAuthToken } = useAuthToken();

  const { botId, id } = useParams();
  const routeNavigator = useNavigate();
  const location = useLocation();
  const message = useToast();

  const flowId = botId ?? id;

  const storeCampaignData = useStoreActions(
    (actions) => actions.cacheCampaignData
  );

  const [productInfo, setProductInfo] = useState<string>("");
  const [howItWorks, setHowItWorks] = useState<string>("");
  const [persona, setPersona] = useState<string>("A friendly human");
  const [tone, setTone] = useState<string>("Friendly and Empathetic");
  const [flag, setFlag] = useState<"setup" | "done">("done");
  const [loading, setLoading] = useState<boolean>(false);
  const [microFlow, setMicroFlow] = useState<MicroFlow>({
    name: "untitled",
    intents: [],
  });

  const blastNotifications = async (
    flow: MicroFlow,
    publish: boolean = false
  ) => {
    flow.data = {
      ...flow.data,
      goal: productInfo,
      customer_profile: howItWorks,
      persona,
      tone,
    };

    const data = await saveMicroFlow({
      flowId,
      flow,
      token: await getAuthToken(),
    });

    if (publish) {
      routeNavigator(`/dashboard/${flowId}/users/new/`);
    } else {
      message({ title: "your conversation is saved!", status: "success" });
    }
  };

  useEffect(() => {
    const fetchFlowFromLocation = () => {
      const navState = location.state ?? {};

      if (navState) {
        let flow: MicroFlow | undefined = navState["flow"];

        if (flow) {
          if (flow.data) {
            setHowItWorks(flow.data?.["customer_profile"] ?? "");
            setProductInfo(flow.data?.["goal"] ?? "");

            const persona = flow.data?.["persona"] ?? "A close friend";
            setPersona(persona.length === 0 ? "A close friend" : persona);

            const tone = flow.data?.["tone"] ?? "Friendly and Empathetic";
            setTone(tone.length === 0 ? "Friendly and Empathetic" : tone);
          }
          setMicroFlow(flow);
        } else {
          fetchMicroFlows();

          // new micro flow, add campaignData
          storeCampaignData({ key: "micro_flow", value: "micro_flow_back" });
        }
        setFlag("done");
      } else {
      }
    };

    const fetchMicroFlows = async () => {
      try {
        setLoading(true);
        const data = await getMicroFlows({
          flowId,
          token: await getAuthToken(),
        });
        setLoading(false);

        if (data) {
          let count = 0;

          Object.keys(data).map((flowName) => {
            if (flowName.includes(microFlow.name)) count += 1;
          });

          if (count > 0) {
            setMicroFlow((flow) => {
              return { ...flow, name: `untitled-${count + 1}` };
            });
          }
        }
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFlowFromLocation();
  }, []);

  useEffect(() => {
    if (productInfo.length === 0 && howItWorks.length === 0) {
      return setFlag("done");
    }

    setFlag("setup");
  }, [productInfo, howItWorks]);

  return (
    <Container px={0} maxW="full">
      <FlowBuilder
        flow={microFlow}
        persona={persona}
        tone={tone}
        onSave={(flow, publish) => blastNotifications(flow, publish)}
      />
    </Container>
  );
};
