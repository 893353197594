import React, { useState, useEffect } from "react";
import { Input, InputGroup, InputLeftElement, SimpleGrid, Stack, VStack, Button, Box, Icon, Text, Image } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import AHLOGO from "../img/AH_Logo.png";
import { useStoreState, useStoreActions } from "../hooks/store";
import { getBotsAssigned, getBotsAssignedToUser } from "../api/root";
import { SearchIcon } from "../constants/icons";
import { Appbar } from "../components/Appbar";
import { UserMenu } from "../components/UserMenu";
import { OrgFlow } from "../models";
import { colorSystem } from "../theme";
import { HiHome, HiChartBar, HiServer } from "react-icons/hi";
import { RiTimerFlashFill } from "react-icons/ri";
import { FlowRecordTile } from "../components/FlowRecord";
import { AuthContext } from "../store/authContext";
import { set } from "idb-keyval";
import FullPageLoader from "../components/Loaders/FullPageLoader";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { INTERVIEWER_RESTRICTED } from "../constants/feature_flags";
import BotAnalytics from "../components/BotAnalytics";
import GoLive from "../components/GoLive";
import Scheduler from "../components/Interview_Scheduler";

const { innerShadow } = colorSystem;
const BOT_SEARCH_INPUT_KEY = "bot_search_input";

const SidebarButton = ({ label, icon, isActive, onClick }) => (
  <Button
    width="full"
    justifyContent="flex-start"
    variant={isActive ? "solid" : "ghost"}
    colorScheme={isActive ? "blue" : "gray"}
    leftIcon={icon}
    onClick={onClick}
  >
    <Text>{label}</Text>
  </Button>
);

export const Home = () => {
  const { user, isAuthenticated } = React.useContext(AuthContext);
  const isAdmin = user.email === "admin@testlibrary.com" || user.email === "padmin@testlibrary.com";

  // console.log('user org id -->', user);
  // console.log('isADMIn --- >', isAdmin);

  const redirect = useNavigate();
  const location = useLocation();
  const { data: featureFlags } = useFeatureFlags("", user.role);

  const isFeatureEnabled = (feature: string) => {
    return (featureFlags?.flags ?? []).includes(feature);
  };

  const isBotAnalytics = location.pathname === "/botanalytics";
  const isGoLive = location.pathname === "/go_live";
  const isInterviewScheduler = location.pathname === "/interviewScheduler";
  const cachedFlows = useStoreState((state) => state.flows);
  const cachedInput = useStoreState((state) => state.inputs[BOT_SEARCH_INPUT_KEY]);

  const cacheUpdatedFlows = useStoreActions((actions) => actions.cacheFlows);
  const cacheInputs = useStoreActions((actions) => actions.cacheInputs);
  const clearCachedFlows = useStoreActions((actions) => actions.clearFlows);

  const [search, setsearch] = useState("");
  const [bots, setbots] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const searchResult = bots.filter((bot) => {
    return bot.bot_name.toLowerCase().includes(search.toLowerCase());
  });

  const getBotsFromAPI = async (token: string) => {
    if (isAuthenticated) {
      try {
        let data = await getBotsAssignedToUser({
          id: Number(user.id),
          token,
        });

        if (data) {
          setbots(data);
          cacheUpdatedFlows(data);
        }
      } catch (err) {
        console.log(err);
        clearCachedFlows();
      } finally {
        // Always set isLoading to false after completing the operation
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    /**
     * checks if there are cached bots, if not fetch from api
     * if there are cached bots, restore them and then fetch from api to update the cache
     */
    const fetchBots = async () => {
      const token = localStorage.getItem("token");

      // if (Object.keys(cachedFlows).length != 0) {
      //   console.log("restoring cached bots from local storage");
      //   Object.keys(cachedFlows).map((flowId) =>
      //     setbots((state) => [...state, cachedFlows[flowId]])
      //   );

      //   // refresh bots from api
      //   return getBotsFromAPI(token);
      // }

      if (token) {
        await getBotsFromAPI(token);
      }
    };

    const restoreStoredInput = () => {
      // restores the cached input
      if (cachedInput) setsearch(cachedInput);
    };

    // restoreStoredInput();
    fetchBots();
  }, [isAuthenticated, user.id]);

  return (
    <SimpleGrid templateColumns="16rem auto">
      <Stack
        h="100vh"
        position="sticky"
        top={0}
        bottom={0}
        as="aside"
        justify="space-between"
        bg="gray.200"
        boxShadow={innerShadow}
        borderRightWidth="thin"
      >
        <VStack spacing={4} align="stretch">
          <Box p={4}>
            <Image src={AHLOGO} />
          </Box>
          {(isAdmin || isFeatureEnabled(INTERVIEWER_RESTRICTED)) && (
            <SidebarButton
              label="Home"
              icon={<Icon as={HiHome} />}
              isActive={location.pathname === "/"}
              onClick={() => redirect("/")}
            />
          )}

          {
            (isAdmin || isFeatureEnabled(INTERVIEWER_RESTRICTED)) && (
              <SidebarButton
                label="Interview Scheduler"
                icon={<Icon as={RiTimerFlashFill} />}
                isActive={location.pathname === "/interviewScheduler"}
                onClick={() => redirect("/interviewScheduler")}
              />
            )
          }

          {isAdmin && (
            <SidebarButton
              label="Bot Analytics"
              icon={<Icon as={HiChartBar} />}
              isActive={location.pathname === "/botanalytics"}
              onClick={() => redirect("/botanalytics")}
            />
          )}

          {isAdmin && (
            <SidebarButton
              label={"Go-Live"}
              icon={<Icon as={HiServer} />}
              isActive={location.pathname === "/go_live"}
              onClick={() => redirect("/go_live")}
            />
          )}

        </VStack>
        <UserMenu />
      </Stack>
      <Stack spacing={0} maxH="100vh">
        {isLoading ? (
          <FullPageLoader />
        ) : (
          <>
            {!isBotAnalytics && !isGoLive && !isInterviewScheduler && (
              <Appbar variant="sticky" px={8} py={4} m={0} showHeader={false} background="rgba(255,255,255,.8)" backdropFilter="blur(24px)">
                {!isBotAnalytics && !isGoLive && (
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
                    <Input
                      value={search}
                      placeholder="Search here.."
                      onChange={(event) => {
                        setsearch(event.currentTarget.value);
                        cacheInputs({
                          key: BOT_SEARCH_INPUT_KEY,
                          input: event.currentTarget.value,
                        });
                      }}
                    />
                  </InputGroup>
                )}
              </Appbar>
            )}
            <Stack flex={1} overflowY="scroll" bg="white">
              {isBotAnalytics ? (
                <BotAnalytics />
              ) : isGoLive ? (
                <Box m={0}>
                  <GoLive />
                </Box>
              ) : isInterviewScheduler ? (
                <Box m={0}>
                  <Scheduler />
                </Box>
              ) : (
                <SimpleGrid p={6} templateColumns="repeat(3, 1fr)" rowGap="8" columnGap={8}>
                  {searchResult.length > 0 ? (
                    searchResult.map((botInfo) => (
                      <FlowRecordTile
                        key={botInfo.bot_id}
                        name={botInfo.bot_name}
                        type={botInfo.bot_type}
                        onClick={() => redirect(`/dashboard/${botInfo.bot_id}/`)}
                      />
                    ))
                  ) : (
                    <Text>No bots found</Text>
                  )}
                </SimpleGrid>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </SimpleGrid>
  );
};
