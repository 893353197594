import React, { useState } from "react";
import { Stack, Text, Input, useInterval } from "@chakra-ui/react";

import chatImage from "../../../img/chat_bg.png";
import { getUserTranscript } from "../../../api/users";
import { Appbar } from "../../../components/Appbar";
import { Button } from "../../../components/Button";
import { KB_PLAYGROUND_FLOW } from "../../../constants";
import { searchFacts } from "../../../api/flow";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { SupportMessage } from "../Support/Message";

interface ChatProps {
  flowId: string;
}

export const KnowledgebaseChat = ({ flowId }: ChatProps) => {
  const { getAuthToken } = useAuthToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [transcript, setTranscript] = useState<MessageInDb[]>([]);

  // API to get messages
  const getChatInfo = async () => {
    if (flowId === "") return "";
    setLoading(true);

    const token = localStorage.getItem('token')

    const response = await getUserTranscript({
      token,
      flowId: KB_PLAYGROUND_FLOW,
      conversationOId: `${flowId}_kb_user`,
      pagination: {
        size: 20000,
        skip: 0,
      },
    });

    setLoading(false);

    if (response) setTranscript(response.transcript);
  };

  // API to send user reply
  const findFact = async (query: string) => {
    const handleError = () => {
      setQuery("");
    };

    if (query.length === 0) return handleError();

    try {
      setLoading(true);
      const token = localStorage.getItem('token')
      const results = await searchFacts({ flowId, query, token });
      setLoading(false);
      setQuery("");

      if (results) {
        const result = results.length > 0 ? results[0] : null;
      }

      return handleError();
    } catch (error) {
      handleError();
    }
  };

  const _handleKeyDown = (key: string) => {
    if (key === "Enter") {
      findFact(query);
    }
  };

  useInterval(async () => {
    console.log("fetching fresh transcript");
    return await getChatInfo();
  }, 1000);

  return (
    <Stack
      pos="relative"
      direction="column-reverse"
      h="100vh"
      style={{ background: `url(${chatImage}) #FAFAFA` }}
      borderLeftWidth="thin"
    >
      <Stack px={4} py={2}>
        <Input
          value={query}
          onChange={(event) => setQuery(event.currentTarget.value)}
          onKeyDown={(event) => _handleKeyDown(event.key)}
          placeholder={"Type your message here ..."}
        />
        <Text alignSelf="flex-end" fontSize="xs" color="gray.600">
          ↩ To send
        </Text>
      </Stack>
      <Stack
        direction="column-reverse"
        w="full"
        borderRadius="lg"
        flex={1}
        overflowY="scroll"
        px={4}
      >
        {transcript.map((message, idx) => (
          <SupportMessage key={idx} message={message} showStatus= {false}/>
        ))}
      </Stack>
      <Appbar
        title="Playground"
        borderBottomWidth="thin"
        boxShadow="sm"
        trailing={
          <Button visibility="hidden" size="sm">
            Assign
          </Button>
        }
      />
    </Stack>
  );
};
