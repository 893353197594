import axios from 'axios'
import config from '../config'
import CryptoJS from 'crypto-js';

const apiRoot = 'v2/auth'
const adminapiRoot = 'api/auth'

interface UserLoginRequest {
    email: string,
    password: string,
    redirect: boolean
}

function encryptPassword(plaintextPassword, base64Key) {
    const encryptionKey = CryptoJS.enc.Base64.parse(base64Key);
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(plaintextPassword, encryptionKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    const encryptedData = iv.concat(encrypted.ciphertext);
    const encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedData);

    return encryptedBase64;
}

export const loginUser = async (
    request: UserLoginRequest
) => {

    // Base64-encode the ciphertext
    const encryptedPassword = encryptPassword(request.password, config.pass_key)
    const response = await axios.post(`${config.url}/${apiRoot}/dashboard/login`, {
        email: request.email,
        password: encryptedPassword,
        redirect: request.redirect
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true, // Ensure cookies are included in the request
    })
    return response.data
}

export const adminloginUser = async (
    request: UserLoginRequest
) => {
    const response = await axios.post(`${config.admin_url}/${adminapiRoot}/login`, {
        username: request.email,
        password: request.password,
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    localStorage.setItem("admin_token", response.data.token)
    return response.data
}





