import React, { useState } from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  Image,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  AddIcon,
  SearchIcon,
  CloseIcon,
  ExportIcon,
} from "../../constants/icons"

import { useSupportInterviewerUser } from "../../data/support/useInterviewerUsers";
import { Button } from "../../components/Button";
import { NewSupportUser } from "./supportUser";
import emptyImageSrc from "../../img/empty.svg";



const InviteMembers = () => {
  const { botId, id } = useParams();
  const flowId = botId ?? id;

  const { isLoading, data: members } = useSupportInterviewerUser(87);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");


  // console.log('Members', members);


  const filteredMembers = members
    ? members.filter((member) => {
      // Adjusted logic for array or object handling
      const filterList = Array.isArray(member)
        ? [member[1], member[0]]
        : [member.email, member.name]; // For object structure

      return filterList.some((question) =>
        question.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
    : [];



  // console.log('filteredMembers -->', filteredMembers);


  return (
    <Stack className="flex-1 px-6 h-full overflow-hidden">
      {/* Header */}
      <HStack justify="space-between">
        <Box py={2}>
          <Heading py={1} size="md">
            Invite Members
          </Heading>
          <Text>Manage your members</Text>
        </Box>
        <Button leftIcon={<AddIcon />} onClick={onOpen}>
          Import Users
        </Button>
      </HStack>
      <Divider py={2} />

      {/* Search bar */}
      <HStack pt={4} justify="space-between">
        <InputGroup size="sm" maxW="sm">
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            placeholder="Search for users..."
          />
          <InputRightElement>
            {searchQuery.length !== 0 && (
              <CloseIcon onClick={() => setSearchQuery("")} />
            )}
          </InputRightElement>
        </InputGroup>

        <Button hidden leftIcon={<ExportIcon />} variant="tertiary">
          Export
        </Button>
      </HStack>

      {/* Content Area */}
      <Box flex="1" overflow="hidden">
        <TableContainer
          boxShadow="sm"
          borderWidth="thin"
          borderRadius="md"
          maxH="calc(100vh - 220px)" // Adjust this value based on the header's height
          overflowY="auto"
        >
          {/* Empty state for no members */}
          <Stack
            py={8}
            spacing={0}
            h="full"
            justify="center"
            align="center"
            hidden={filteredMembers.length !== 0}
          >
            <Image src={emptyImageSrc} />
            <Text className="text-base text-[#1D2939]">No members</Text>
          </Stack>


          <Table hidden={filteredMembers.length === 0} variant="simple" className="overflow-y-auto max-h-[400px]"> {/* Adjust max-h as needed */}
            <Thead>
              <Tr className="bg-gray-200">
                <Th className="sticky top-0 bg-gray-200 z-10 " >Name</Th> 
                <Th className="sticky top-0 bg-gray-200 z-10">Email</Th>
                <Th className="sticky top-0 bg-gray-200 z-10">Phone No</Th>
              </Tr>
            </Thead>
            <Tbody>
              {filteredMembers.map((member) => (
                <Tr key={member[1]}> {/* Added key for better performance */}
                  <Td>{member[0] ?? member[1]}</Td>
                  <Td>{member[1]}</Td>
                  <Td>{member[2]}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

        </TableContainer>
      </Box>
      <NewSupportUser flowId={flowId} isOpen={isOpen} onClose={onClose} />
    </Stack>


  );
};


export default InviteMembers
