import axios from "axios";
import config from "../config";

import { FilterGroupQuery } from "../components/Filter/models";
import { IFlowRequest, PaginationConfig } from "../models";
import { SupportUser,InterviewerSchedulerSupportUser } from "../models/support";
import { SortGroup } from "./users";

const apiPrefix = "v2/support";

interface SupportUsersRequest extends IFlowRequest {
  users: SupportUser[];
}

interface InterviewerUsersRequest {
  token : string
  users: InterviewerSchedulerSupportUser[];
}

export interface SupportQueryRequest extends IFlowRequest {
  filters: FilterGroupQuery;
  pagination?: PaginationConfig;
  sort?: SortGroup[];
}

interface SupportUpdateRequest extends IFlowRequest {
  conversationId: string;
  source: string;
  close?: boolean;
  userMail: string;
}

export interface AssignAgentRequest extends IFlowRequest {
  sessionId: string;
  agent?: SupportUser;
  team?: string;
  userMail: string;
}

interface SendMessageRequest extends IFlowRequest {
  conversationOId: string;
  message: any;
  userMail: string;
  step?: string;
}

export const updateQuery = async ({
  close = false,
  ...request
}: SupportUpdateRequest): Promise<{ status: string }> => {
  const response = await axios.post<{ status: string }>(
    `${config.url}/${apiPrefix}/${request.flowId}/update`,
    {},
    {
      params: {
        source_id: request.source,
        conversation_id: request.conversationId,
        close,
      },
      headers: {
        "dashboard-user-id": request.userMail,
        Authorization: `Bearer ${request.token}`,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const addNewSupportUsers = async ({
  flowId,
  token,
  users,
}: SupportUsersRequest) => {


  const response = await axios.post(
    `${config.url}/${apiPrefix}/${flowId}/users/new`,
    users,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;
};

export const addNewInterviewerUser = async ({token,users} : InterviewerUsersRequest) => {

  

  const response = await axios.post(
    `${config.url}/create_interviewer_bulk_user`,
    users,
    {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    }
  );

  return response.data;

}


export const assignAgent = async ({
  flowId,
  sessionId,
  agent,
  team,
  token,
  userMail,
}: AssignAgentRequest): Promise<{ status: string; agent?: SupportUser }> => {
  const response = await axios.post<{ status: string }>(
    `${config.url}/${apiPrefix}/${flowId}/assign`,
    {
      agent,
      team,
      session_id: sessionId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "dashboard-user-id": userMail,
      },
      withCredentials: true,
    }
  );

  return response.data;
};

export const sendMessage = async (request: SendMessageRequest) => {
  const response = await axios.post<{ status: "success" | "failed" }>(
    `${config.url}/${apiPrefix}/${request.flowId}/message`,
    {
      conversation_oid: request.conversationOId,
      messages: [request.message],
      step: request.step,
    },
    {
      headers: {
        Authorization: `Bearer ${request.token}`,
        "dashboard-user-id": request.userMail,
      },
      withCredentials: true,
    }
  );

  return response.data;
};
