// useNewInterviewerSupportUsers.ts
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";

import { useAuthToken } from "../../hooks/useAuthToken";
import { InterviewerSchedulerSupportUser } from "../../models/support";
import { addNewInterviewerUser } from "../../api/support";

export interface NewInterviewerSupportUsers {
  flowId: string;
  refetchKey: string[];
}

export const useNewInterviewerSupportUsers = ({ flowId, refetchKey }: NewInterviewerSupportUsers) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { getAuthToken } = useAuthToken(); // Ensure you have this hook for getting auth token

  return useMutation({
    mutationKey: ["new", "interviewer", "support", "users", flowId],
    mutationFn: async (users: InterviewerSchedulerSupportUser[]) => { // Use the InterviewerSchedulerSupportUser type
      const token = localStorage.getItem('token'); // Get the token from local storage

      const result = await addNewInterviewerUser({
        token,
        users
      });

      if (result) {
        toast({ title: "New interviewer users added", status: "success" , 
          duration: 3000, isClosable : true });
      }

      return result;
    },
    onError: (err, variables, context) => {
      toast({
        description: "Failed to add new interviewer support users", 
        status: "error",
        duration: 5000,
        isClosable : true
      });
    },
    onSettled: () => {
      if (refetchKey.length !== 0)
        queryClient.invalidateQueries({
          queryKey: refetchKey,
        });
    },
  });
};
